import LocalizedStrings from 'react-localization';
import store from '../redux/store';

const getMessage = (intstate) => {
  let obj = new Object();
  try {
    let state = intstate || store.getState();
    let messageInstance = null, labelInstance = null;
    let lang = 'en-US';
    if (state.app.language != null && state.app.language != 'undefined') {
      lang = state.app.language;
    }
    messageInstance = require('/src/scripts/multilingual/languages/message/message_' + lang)
    labelInstance = require('/src/scripts/multilingual/languages/labels/label_' + lang)
    obj[lang] = { message: messageInstance.message, label: labelInstance.label }
  } catch (error) {
    console.error("Error in 'GetMessagesMultilingual.js -> getMessage()':" + error);
  }
  return obj;
}

export const getLocalizedStrings = (state) => {
  let localizedStrings = new LocalizedStrings({ ...getMessage(state) });
  return localizedStrings;
}






