import { constants } from '../constants'

export const change_app_language = (payload) => {
    return {
        type: constants.CHANGE_LANUGAGE,
        payload
    }
}

export const toggleLoader = () => {
    return {
        type: constants.TOGGLE_LOADER
    }
}

export const hideLoader = () => {
    return {
        type: constants.HIDE_LOADER
    }
}

export const setFilesFromCrewList = (payload) => {
    return {
        type: constants.SAVE_FILES_TO_CREW_LIST,
        payload
    }
}

export const updateRoutes = (payload) => {
    return {
        type: constants.APP.UPDATE_ROUTES,
        payload
    }
}

export const change_first_render = () => {
    return {
        type: constants.APP.FIRST_RENDER_UPDATE
    }
}

export const store_notifications = (payload) => {
    return {
        type: constants.APP.STORE_NOTIFICATIONS,
        payload
    }
}

export const store_companyinfo = (payload) => {
    return {
        type: constants.APP.STORE_COMPANYINFO,
        payload
    }
}

export const change_active_tab = (payload) => {
    return {
        type: constants.APP.CHANGE_ACTIVE_TAB,
        payload
    }
}

export const change_active_sidebar = (payload) => {
    return {
        type: constants.APP.SIDEBAR_ACTIVE,
        payload
    }
}

export const show_alert = (payload) => {
    return {
        type: constants.APP.OPEN_ALERT,
        payload
    }
}

export const hide_alert = () => {
    return {
        type: constants.APP.CLOSE_ALERT
    }
}
export const show_edit_firstname=(payload)=>{
    return{
        type:constants.APP.SHOW_EDIT_DATA,
        payload
    }
}