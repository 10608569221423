import { constants } from "../constants";
import combinate from "combinate";
import { generateRandomString, sortAndMergeArrays } from "../../utils/common";

const initialState = {
  mounted: false,
  randomKey: "",
  allData: new Object(),
  isEditMode: false,
  editData: {},
  selectedSuppliers: new Array(),
  basicCustomFields: {
    loaded: false,
  },
  warehouse: new Array(),
  defaultWarehouses: new Array(),
  linked_products: new Array(),
  suppliersWithFactoriesFiltered: new Array(),
  basicPrices: new Array(),
  dailyPrices: new Array(),
  selectedUnits: new Array(),
  descriptionEditor: "",
  longDescriptionEditor: "",

  added_characteristics: [],
  variationPrices: [],
  charactersticData: [],
  characteristic_changed: 0,
};

Array.prototype.insert = function (index, item) {
  this.splice(index, 1);
  this.splice(index, 0, item);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.PRODUCT.PRODUCT_STACK_DATA:
      const tempToken = generateRandomString(8);
      localStorage.setItem("product-temp-token", tempToken);

      const defaultFinalResult = sortAndMergeArrays(action.payload.wareHouses, [])
      
      return {
        ...state,
        mounted: true,
        randomKey: tempToken,
        allData: action.payload,
        charactersticData: [...action.payload.charDataList],
        defaultWarehouses: defaultFinalResult
      };
    case constants.PRODUCT.SET_SELECTED_SUPPLIERS:
      return {
        ...state,
        selectedSuppliers: [
          ...state.selectedSuppliers.filter((f) => f.id !== action.payload.id),
          action.payload,
        ],
      };
    case constants.PRODUCT.RESET_PRODUCTS_STATE:
      return {
        mounted: false,
        randomKey: "",
        allData: new Object(),
        isEditMode: false,
        editData: {},
        selectedSuppliers: new Array(),
        basicCustomFields: {
          loaded: false,
        },
        warehouse: new Array(),
        defaultWarehouses: [],
        linked_products: new Array(),
        suppliersWithFactoriesFiltered: new Array(),
        basicPrices: new Array(),
        dailyPrices: new Array(),
        selectedUnits: new Array(),
        descriptionEditor: "",
        longDescriptionEditor: "",
        added_characteristics: [],
        variationPrices: [],
        charactersticData: [],
        characteristic_changed: 0,
      };
    case constants.PRODUCT.REMOVE_SUPPLIER_FROM_LIST:
      return {
        ...state,
        selectedSuppliers: state.selectedSuppliers.filter(
          (f) => f.id !== action.payload
        ),
        variationPrices: state.variationPrices.filter(
          (f) => f.supplier.supplier_id !== action.payload
        ),
        suppliersWithFactoriesFiltered: state.suppliersWithFactoriesFiltered.filter(
          (f) => f.supplier_id !== action.payload
        ),
        basicPrices: state.basicPrices.filter(
          (f) => f.supplier.supplier_id !== action.payload
        ),
        dailyPrices: state.dailyPrices.filter(
          (f) => f.supplier.supplier_id !== action.payload
        )
      };
    case constants.PRODUCT.REPLACE_SUPPLIER:
      state.selectedSuppliers.insert(
        state.selectedSuppliers.findIndex((f) => f.id === action.payload.id),
        action.payload.supplier
      );
      return {
        ...state,
      };
    case constants.PRODUCT.PRODUCT_CUSTOM_FIELDS_BASIC:
      return {
        ...state,
        basicCustomFields: action.payload,
      };
    case constants.PRODUCT.MANAGE_WAREHOUSES:
      
      let warehouses = [...state.warehouse];
      
      if (action.payload.operation === "add") {
        
        const checkIndex = warehouses.findIndex(
            (f) => f.id === action.payload.data.id
          );
        
        if (checkIndex === -1) {
          warehouses.push(action.payload.data);
        } else {
          warehouses.insert(
            warehouses.findIndex(
              (f) => f.id === action.payload.data.id
            ),
            action.payload.data
          );
        }

      } else if (action.payload.operation === "update") {
        warehouses.insert(
          warehouses.findIndex(
            (f) => f.id === action.payload.data.id
          ),
          action.payload.data
        );
      } else if (action.payload.operation === "delete") {
        warehouses = warehouses.filter(
          (f) => f.id !== action.payload.data.id
        );
      }

      warehouses = warehouses.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.name === value.name)
      );
      
      const finalResult = sortAndMergeArrays(warehouses, state.isEditMode ? []: state.defaultWarehouses)
      return {
        ...state,
        warehouse: finalResult,
      };
    case constants.PRODUCT.MANAGE_LINKED_PRODUCTS:
      let products = [...state.linked_products];
      if (action.payload.operation === "add") {
        products.push(action.payload.data);
      } else if (action.payload.operation === "update") {
        products.insert(
          products.findIndex((f) => f.id === action.payload.data.id),
          action.payload.data
        );
      } else if (action.payload.operation === "delete") {
        products = products.filter((f) => f.id !== action.payload.data.id);
      }
      products = products.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.value === value.value)
      );
      return {
        ...state,
        linked_products: [...products],
      };
    case constants.PRODUCT.SUPPLIERS_WITH_FACTORIES_FILTERED:
      let suppliers = structuredClone(state.suppliersWithFactoriesFiltered);
      if (action.payload.operation === "add") {
        suppliers.push(action.payload.supplier);
      } else if (action.payload.operation === "update") {
        suppliers.insert(
          suppliers.findIndex(
            (f) => f.supplier_id === action.payload.supplier.supplier_id
          ),
          action.payload.supplier
        );
      } else if (action.payload.operation === "delete") {
        suppliers = suppliers.filter(
          (f) => f.supplier_id !== action.payload.supplierId
        );
      }
      suppliers = suppliers.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.supplier_id === value.supplier_id)
      );
      return {
        ...state,
        suppliersWithFactoriesFiltered: structuredClone(suppliers),
      };
    case constants.PRODUCT.ADD_ALL_BASIC_PRICES:
      return {
        ...state,
        basicPrices: action.payload,
      };
    case constants.PRODUCT.ADD_BASIC_PRICES:
      let prices = [];
      let pricesBackup = [...state.basicPrices];
      for (let i = 0; i < action.payload.units.length; i++) {
        for (let j = 0; j < action.payload.suppliers.length; j++) {
          let temp = pricesBackup.filter(
            (p) =>
              p.unit === action.payload.units[i].label &&
              p.supplier.supplier_id ===
                action.payload.suppliers[j].supplier_id
          );
          let tempIndex = pricesBackup.findIndex(
            (p) =>
              p.unit === action.payload.units[i].label &&
              p.supplier.supplier_id ===
                action.payload.suppliers[j].supplier_id
          );
          let inPricesArray = prices.filter(
            (p) =>
              p.unit === action.payload.units[i].label &&
              p.supplier.supplier_id ===
                action.payload.suppliers[j].supplier_id
          );
          if (temp.length && inPricesArray.length) {
            prices[tempIndex]["id"] = temp[0].id;
            prices[tempIndex]["purchase"] = temp[0].purchase;
            prices[tempIndex]["sell"] = temp[0].sell;
            prices[tempIndex]["units"] = temp[0].units;
            prices[tempIndex]["unit"] = action.payload.units[i].label;
            prices[tempIndex]["unit_id"] = action.payload.units[i].value;
            prices[tempIndex]["supplier"] = action.payload.suppliers[j];
            prices[tempIndex]["deleted"] = temp[0].deleted;
          } else if (temp.length && !inPricesArray.length) {
            prices.push({
              id: temp[0].id,
              purchase: temp[0].purchase,
              sell: temp[0].sell,
              units: temp[0].units,
              unit: action.payload.units[i].label,
              unit_id: action.payload.units[i].value,
              supplier: action.payload.suppliers[j],
              deleted: temp[0].deleted,
            });
          } else {
            prices.push({
              id: null,
              purchase: 0,
              sell: 0,
              units: 0,
              unit: action.payload.units[i].label,
              unit_id: action.payload.units[i].value,
              supplier: action.payload.suppliers[j],
              deleted: false,
            });
          }
        }
      }
      return {
        ...state,
        basicPrices: [...prices],
      };
    case constants.PRODUCT.REMOVE_BASIC_PRICE:
      return {
        ...state,
        basicPrices: state.basicPrices.map((m, idx) => {
          if (idx === action.payload) {
            return { ...m, deleted: true };
          } else {
            return { ...m };
          }
        }),
      };
    case constants.PRODUCT.UPDATE_BASIC_PRICE:
      let newBasicProducts = state.basicPrices.map((m, idx) => {
        if (idx === action.payload.index) {
          return { ...m, ...action.payload.data };
        } else {
          return { ...m };
        }
      });
      let foundBasicPrice = state.basicPrices[action.payload.index];
      let foundVariants = state.variationPrices.map((vp, idx) => {
        if (
          vp.unit_id === foundBasicPrice.unit_id &&
          vp.supplier.supplier_id === foundBasicPrice.supplier.supplier_id &&
          (idx+1) === Number(vp?.product_code?.split("-")[1])
        ) {
          return { ...vp, ...action.payload.data };
        } else {
          return { ...vp };
        }
      });

      return {
        ...state,
        basicPrices: newBasicProducts,
        variationPrices: foundVariants,
      };
    case constants.PRODUCT.ADD_DAILY_PRICE:
      let dailyPricesTemp = [...state.dailyPrices];
      if (action.payload.isEditMode) {
        dailyPricesTemp[action.payload.index-1] = action.payload.data;
      } else {
        dailyPricesTemp.push(action.payload.data);
        dailyPricesTemp = dailyPricesTemp.filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => !t.hasOwnProperty("id") || t.id === value.id)
        );
      }

      return {
        ...state,
        dailyPrices: [...dailyPricesTemp],
      };
    case constants.PRODUCT.ADD_NEW_DAILY_PRICE:
      let dailyPricesTemp1 = [...state.dailyPrices];
      if (action.payload.isEditMode) {
        dailyPricesTemp1.insert(action.payload.index, action.payload.data);
      } else {
        dailyPricesTemp1.push(action.payload.data);
      }
      return {
        ...state,
        dailyPrices: [...dailyPricesTemp1],
      };
    case constants.PRODUCT.DELETE_DAILY_PRICE:
      let dailyPricesBackup = [...state.dailyPrices];
      dailyPricesBackup.splice(action.payload - 1, 1);
      return {
        ...state,
        dailyPrices: [...dailyPricesBackup],
      };
    case constants.PRODUCT.SELECTED_UNITS:
      return {
        ...state,
        selectedUnits: [...action.payload],
      };
    case constants.PRODUCT.ENABLE_EDIT_MODE:
      return {
        ...state,
        isEditMode: true,
        editData: action.payload,
      };
    case constants.PRODUCT.STORE_EDITOR_OBJECTS:
      return {
        ...state,
        descriptionEditor: action.payload.descriptionEditor,
        longDescriptionEditor: action.payload.longDescriptionEditor,
      };
    case constants.PRODUCT.UPDATE_VARIATION_PRICES:
      return {
        ...state,
        variationPrices: [],
      };
    /**PRODUCT CHARACTERISTICS STARTS */
    case constants.PRODUCT.MANAGE_EXISTING_CHARACTERISTICS:
      return {
        ...state,
        added_characteristics: [...action.payload],
        characteristic_changed: state.characteristic_changed + 1,
      };
    case constants.PRODUCT.REFRESH_CHARACTERSTICS:
      let refreshedVariationPrices = [];
      if (
        Array.isArray(action.payload.variant) &&
        action.payload.variant.length
      ) {
        action.payload.variant.forEach((v, idx) => {
          let supplierDetails = {};
          if (v.supplier_data?.id) {
            supplierDetails.name = v.supplier_data?.name;
            supplierDetails.supplier_id = v.supplier_data.id;
          } else {
            let supplierFound = state.allData.supplierlist.find(
              (k) => k.id === Number(v?.supplier)
            );
            if (supplierFound) {
              supplierDetails.name = supplierFound.name;
              supplierDetails.supplier_id = supplierFound.id;
            }
          }
          if (supplierDetails?.supplier_id) {
            refreshedVariationPrices.push({
              id: v.id,
              primaryId: v.product_id,
              product_code:
                v.product_code || `${action.payload.code}-${idx + 1}`,
              classId: Number(v.class),
              sizeId: Number(v.size),
              flexibilityId: Number(v.flexibility),
              // hardeningClassId: !isNaN(v?.hardening_class)
              //   ? Number(v.hardening_class)
              //   : null,
              supplier: supplierDetails,
              unit: v.unit_id ?  state?.allData?.unitList?.find(
                (k) => k.id === Number(v.unit_id)
              )?.unit : null,
              unit_id: Number(v.unit_id),
              units: v?.amount ? v.amount : 0,
              purchase: v.purchase_price || 0,
              sell: v.selling_price || 0,
              deleted: Boolean(v.deleted_at),
            });
          }
        });
      }
      return {
        ...state,
        variationPrices: [...refreshedVariationPrices],
      };
    case constants.PRODUCT.REFRESH_BASIC_PRICES:
      let refreshedBasicPrices = [];
      if (Array.isArray(action.payload) && action.payload.length) {
        action.payload.forEach((v) => {
          let supplierDetails = {};
          let supplierFound = state.allData.supplierlist.find(
            (k) => k.id === Number(v?.supplier_id)
          );
          
          let unitData = state.allData.unitList.find(
            (u) => u.id === Number(v.unit_id)
          );
          if (supplierFound) {
            supplierDetails.name = supplierFound.name;
            supplierDetails.supplier_id = supplierFound.id;
            refreshedBasicPrices.push({
              supplier: supplierDetails,
              deleted: Boolean(v.deleted_at),
              id: v.id,
              purchase: v?.purchase_price || 0,
              sell: v?.selling_price || 0,
              unit: unitData?.unit || "",
              unit_id: v.unit_id,
              units: v.units || 0,
            });
          }
        });
      }
      return {
        ...state,
        basicPrices: [...refreshedBasicPrices],
      };
    case constants.PRODUCT.REFRESH_DAILY_PRICES:
      let refreshedDailyPrices = [];
      if (Array.isArray(action.payload) && action.payload.length) {
        action.payload.forEach((v) => {
          refreshedDailyPrices.push({
            end_time: v.to_time,
            id: v.id,
            purchase: v?.purchase_price || 0,
            sell: v?.selling_price || 0,
            start_time: v.from_time,
            supplier: v.supplier_details,
            unit: { value: v.unit_id, label: v.unit_details.unit },
            units: v.units || 0,
            weekdays: JSON.parse(v.days),
          });
        });
      }
      return {
        ...state,
        dailyPrices: [...refreshedDailyPrices],
      };
    case constants.PRODUCT.ADD_CHARACTISTICS_VALUES_WITH_TYPE:
      let variationPrices = [];
      let variationPricesBackup = [...state.variationPrices];
      for (let i = 0; i < action.payload.units.length; i++) {
        for (let j = 0; j < action.payload.suppliers.length; j++) {
          let charsForVariation = state.added_characteristics.filter(
            (f) =>
              f.value !== 5 &&
              f.hasOwnProperty("selected") &&
              f.selected.length > 0
          );
          if (charsForVariation.length > 0) {
            let combinations = [];
            combinations = combinate(
              state.added_characteristics
                .filter((f) => f.value !== 5)
                .map((ch) => {
                  return ch.selected;
                })
            );
            combinations = combinations.filter(
              (f) => Object.keys(f).length > 1
            );
            combinations.length = combinations.length / 2;
            // let hasHardeningClass = state.added_characteristics.some(
            //   (s) => s.value === 4 && s.selected.length > 0
            // );
            combinations.forEach((c) => {
              let classId =
                c[
                  Object.keys(c).filter((k) =>
                    k === "insert"
                      ? false
                      : c[k].parentValue ===
                        state.allData.charList.filter(
                          (l) => l.name === "Class"
                        )[0].id
                  )
                ].value;
              let sizeId =
                c[
                  Object.keys(c).filter((k) =>
                    k === "insert"
                      ? false
                      : c[k].parentValue ===
                        state.allData.charList.filter(
                          (l) => l.name === "Size"
                        )[0].id
                  )
                ].value;
              let flexibilityId =
                c[
                  Object.keys(c).filter((k) =>
                    k === "insert"
                      ? false
                      : c[k].parentValue ===
                        state.allData.charList.filter(
                          (l) => l.name === "Flexibility"
                        )[0].id
                  )
                ].value;
              // let hardeningClassId = hasHardeningClass
              //   ? c[
              //       Object.keys(c).filter((k) =>
              //         k === "insert"
              //           ? false
              //           : c[k].parentValue ===
              //             state.allData.charList.filter(
              //               (l) => l.name === "Hardening Class"
              //             )[0].id
              //       )
              //     ].value
              //   : "";

              let hasAdded = variationPricesBackup.filter(
                (f) =>
                  f.classId === Number(classId) &&
                  f.sizeId === Number(sizeId) &&
                  f.flexibilityId === Number(flexibilityId) &&
                  f.unit_id === action.payload.units[i].value &&
                  f.supplier.supplier_id ===
                    action.payload.suppliers[j].supplier_id
                //   &&
                // (hasHardeningClass
                //   ? f.hardeningClassId === Number(hardeningClassId)
                //   : true)
              );
              let hasAddedIndex = variationPricesBackup.findIndex(
                (f) =>
                  f.classId === Number(classId) &&
                  f.sizeId === Number(sizeId) &&
                  f.flexibilityId === Number(flexibilityId) &&
                  f.unit_id === action.payload.units[i].value &&
                  f.supplier.supplier_id ===
                    action.payload.suppliers[j].supplier_id
                //   &&
                // (hasHardeningClass
                //   ? f.hardeningClassId === Number(hardeningClassId)
                //   : true)
              );
              let inVariationPrices = variationPrices.filter(
                (f) =>
                  f.classId === Number(classId) &&
                  f.sizeId === Number(sizeId) &&
                  f.flexibilityId === Number(flexibilityId) &&
                  f.unit_id === action.payload.units[i].value &&
                  f.supplier.supplier_id ===
                    action.payload.suppliers[j].supplier_id
                //   &&
                // (hasHardeningClass
                //   ? f.hardeningClassId === Number(hardeningClassId)
                //   : true)
              );

              if (hasAdded.length && inVariationPrices.length) {
                variationPrices[hasAddedIndex]["product_code"] =
                  hasAdded[0].product_code;
                variationPrices[hasAddedIndex]["classId"] = hasAdded[0].classId;
                variationPrices[hasAddedIndex]["sizeId"] = hasAdded[0].sizeId;
                variationPrices[hasAddedIndex]["flexibilityId"] =
                  hasAdded[0].flexibilityId;
                // variationPrices[hasAddedIndex]["hardeningClassId"] =
                //   hasAdded[0].hardeningClassId;
                variationPrices[hasAddedIndex]["units"] = hasAdded[0].units;
                variationPrices[hasAddedIndex]["purchase"] =
                  hasAdded[0].purchase;
                variationPrices[hasAddedIndex]["sell"] = hasAdded[0].sell;
                variationPrices[hasAddedIndex]["supplier"] =
                  action.payload.suppliers[j];
                variationPrices[hasAddedIndex]["unit"] =
                  action.payload.units[i].label;
                variationPrices[hasAddedIndex]["unit_id"] =
                  action.payload.units[i].value;
                variationPrices[hasAddedIndex]["deleted"] = hasAdded[0].deleted;
              } else if (hasAdded.length && !inVariationPrices.length) {
                variationPrices.push({
                  product_code: hasAdded[0].product_code,
                  classId,
                  sizeId,
                  flexibilityId,
                  // hardeningClassId,
                  supplier: action.payload.suppliers[j],
                  unit: action.payload.units[i].label,
                  unit_id: action.payload.units[i].value,
                  units: hasAdded[0].units,
                  purchase: hasAdded[0].purchase,
                  sell: hasAdded[0].sell,
                  deleted: hasAdded[0].deleted,
                });
              } else {
                let basicPrice = state.basicPrices.find((bp) => 
                bp.supplier.supplier_id === action.payload.suppliers[j].supplier_id
                && action.payload.units[i].value === bp.unit_id
                )
                variationPrices.push({
                  product_code: `${action.payload.code}-${i + 1}`,
                  classId,
                  sizeId,
                  flexibilityId,
                  // hardeningClassId,
                  supplier: action.payload.suppliers[j],
                  unit: action.payload.units[i].label,
                  unit_id: action.payload.units[i].value,
                  units: basicPrice ? basicPrice.units : 0,
                  purchase: basicPrice ? basicPrice.purchase : 0,
                  sell: basicPrice ? basicPrice.sell : 0,
                  deleted: false,
                });
              }
            });
          }
        }
      }
      variationPrices = variationPrices.map((vp, idx) => ({ ...vp, product_code: `${action.payload.code}-${idx + 1}` }))
      return {
        ...state,
        variationPrices: [...variationPrices],
      };
    case constants.PRODUCT.REMOVE_VARIATION_PRICE:
      return {
        ...state,
        variationPrices: state.variationPrices.map((m, idx) => {
          if (idx === action.payload) {
            return { ...m, deleted: true };
          } else {
            return { ...m };
          }
        }),
      };
    case constants.PRODUCT.UPDATE_VARIATION_PRICE:
      return {
        ...state,
        variationPrices: state.variationPrices.map((m, idx) => {
          if (idx === action.payload.index) {
            return { ...m, ...action.payload.data };
          } else {
            return { ...m };
          }
        }),
      };
    case constants.PRODUCT.ADD_ALL_CHARACTERSTICS:
      return {
        ...state,
        charactersticData: [...action.payload],
      };
    case constants.PRODUCT.ADD_ALL_VARIANT_PRICES: {
      return {
        ...state,
        variationPrices: [...action.payload],
      };
    }
    /**PRODUCT CHARACTERISTICS ENDS */
    default:
      return {
        ...state,
      };
  }
};
