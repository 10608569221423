import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './scripts/redux/store'
import { Provider } from 'react-redux'
import { user_check_login, logout } from './scripts/redux/actions/authActions';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import '@yaireo/tagify/dist/tagify.css'
import { change_app_language } from './scripts/redux/actions/appActions';

const theme = createTheme({
  palette: {
    primary: {
      main: "#323b6b"
    },
    secondary: {
      main: "#6d7fea"
    }
  }
});

// debugger
const token = localStorage.getItem('x-access-token')
const user = localStorage.getItem('user-details')
const access = localStorage.getItem('user-access-details')
if (localStorage.getItem('erp-language')) {
  store.dispatch(change_app_language({
    language: localStorage.getItem('erp-language')
  }))
}
if (token) {
  const isLoggedIn = store.dispatch(user_check_login({ token, user: JSON.parse(user), access }))
  if (!isLoggedIn) {
    store.dispatch(logout())
  }
} else {
  store.dispatch(logout())
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
