import { constants } from "../constants"

export const user_check_login = (payload) => {
    return {
        type: constants.USER_CHECK_LOGIN,
        payload
    }
}

export const logout = () => {
    return {
        type: constants.USER_LOGOUT
    }
}