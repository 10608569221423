import { constants } from "../constants"

const initialState = {
    isUserLoggedIn: false,
    role: '',
    userId: Number(),
    data: new Object(),
    access: new Object()
}

const userAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.USER_CHECK_LOGIN:
            // let access = []
            // Object.values(JSON.parse(action.payload.access)).map(m => {
            //     access = [...access, ...m]
            // })
            let data = action.payload.user
            // data['user_role']['access']  = structuredClone(access)
            return {
                ...state,
                isUserLoggedIn: true,
                role: data.user_role.role_name,
                userId: action.payload.user.id,
                data: JSON.stringify(action.payload.user),
                access: action.payload.access
            }

        case constants.USER_LOGOUT:
            localStorage.removeItem('x-access-token')
            localStorage.removeItem('user-details')
            localStorage.removeItem('user-access-details')
            return {
                ...state,
                isUserLoggedIn: false,
                role: '',
                data: new Object(),
                access: new Object()
            }

        default:
            return {
                ...state
            }
    }
}

export default userAuthReducer