export const label = {
  LOGIN: {
    ENTER_EMAIL: "Enter Email Address",
    ENTER_PASSWORD: "Enter Password",
    LOGIN_HERE: "Login Here",
    RESET_PASSWORD: "Reset Password",
    FORGOT_PASSWORD: "Forgot Password?",
    LOGIN: "Login",
    REMEMBER_ME: "Remember me?",
    ENTER_OTP: "Enter OTP",
    ENTER_CONFIRMATION_PASSWORD: "Confirm your password",
  },
  COMMON: {
    BASIC: 'Basic',
    ACTIONS: "Actions",
    ADD: "Add",
    EDIT: "Edit",
    NEW: "New",
    DELETE: "Delete",
    CLEAR: "Clear",
    ADD_ITEM: "Add Item",
    SAVE: "Save",
    CANCEL: "Cancel",
    DOWNLOAD: "Download",
    PREVIEW: "Preview",
    PREVIEW_CONTENT: "Order confirmation preview",
    PRINT: "Print",
    COPY: "Copy",
    SEND: "Send",
    YES: "Yes",
    NO: "No",
    VIEW: "View",
    OVERVIEW: "Overview",
    FILE: "Files",
    FILES: "Files",
    NOTES: "Notes",
    CUSTOM_FIELDS: "Add a field",
    MANAGE_FIELDS: "Manage Fields",
    NEW_SUPPLIER: "New Supplier",
    ADD_A_COLUMN: "Manage Columns",
    IMPORT: "Import",
    EXPORT: "Export",
    DOWNLOAD_PRODUCT_SAMPLE: "Download Product Sample",
    NEW_CUSTOMER: "New Customer",
    NEW_OFFER: "New Offer",
    NEW_ORDER: "New Order",
    NEW_INVOICE: "New Invoice",
    REMOVE: "Remove",
    NO_RECORD_FOUND: "No records found",
    CLOSE: "Close",
    SUBMIT: "Submit",
    LOADING: "Loading...",
    PRESS_ENTER_TO_EDIT: "Press enter to edit",
    SEARCH: "Search",
    TYPE: "Type",
    AREA: "Area",
    SEND_TO_NETVISOR: "Send to Netvisor",
    MANAGE: "Manage",
    PREVIOUS_PAGE: "Previous Page",
    NEXT_PAGE: "Next Page",
    GROUP: "group",
    SELECT: "Select",
    EDITOR: "Editor",
    CREATE: "Create",
    UPDATE: "Update",
    NEXT: "Next",
    SENDING: "Sending...",
    FORM: "Form",
    NEWORDER: "New Order",
    GENERATE_INVOICE: "Generate Invoice",
    PREVIEW_ORDER_PROTOCOL: "Print the order protocol",
  },
  EMAIL:{
    SEND_EMAIL: "Send Email",
    TITLE: "Title",
    RECIPIENTS: "Recipients",
    CC: "CC",
    BCC: "BCC",
    FROM: "From",
    BODY: "Body"
  },
  LISTING_HEADER: {
    FIRSTNAME: "Personnel",
    WORK_PHONE: "Work Phone",
    JOB_DESCRIPTION: "Job Description",
    ROLE_ID: "The Role of the User",
    PRODUCT_CODE: "Product Code",
    PRODUCT_NAME: "Product",
    SUPPLIER: "Supplier",
    CATEGORY: "Category",
    PRODUCT_TYPE: "Product type",
    PRICE_RANGE: "Prices",
    AGE: "Age",
    REGISTER_NUMBER: "Registration Number",
    CUSTOMER_REGISTER_NUMBER: "D&B Registration Number",
    CATEGORY_ID: "Category",
    MODEL: "Model",
    TYPES: "Type",
    YEAR: "Model Year",
    COLOR: "Color",
    LENGTH: "Length",
    WIDTH: "Width",
    HEIGHT: "Height",
    WEIGHT: "Weight",
    CAPACITY: "Capacity",
    SUPPLIER_ID: "Supplier",
    DRIVER_ID: "Driver",
    PHONE: "Phone",
    NAME: "Name",
    ADDRESS: "Address",
    ZIP_CODE: "ZIP",
    POSTAL_DISTRICT: "Postal District",
    COMPANY_NAME: "Company Name",
    COMPANY_ID: "Company ID",
    TYPE_ID: "Type",
    PHONE_NUMBER: "Phone Number",
    PERSONAL_ID: "Personal ID",
    EMAIL: "Email",
    COUNTRY: "Country",
    OFFER_NUMBER: "Offer Number",
    SENT: "Sent",
    OFFER_ACCEPTED: "Offer Accepted",
    SELLER_DETAILS: "Seller Name",
    ORDER_NUMBER: "Order number",
    CUSTOMER: "Customer",
    BOOKED: "Booked",
    DELIVERED: "Delivered",
    STATUS: "Status",
    ADDITIONAL_INFORMATION: "Additional information",
    CREATED_AT: "Created on",
    PANEL: "Section",
    FIELD_NAME: "Extra field",
    FIELD_TYPE: "Type",
    GLOBAL_FIELD: "Global",
    ORDER_ID: "Order in list",
    ACCESS: "Visible",
    IN_SEARCH: "Searchable",
    ID: "Invoice ID",
    FILENAME: "Title",
    DESCRIPTION: "Description",
    CATEGORIES_ID: "File Category",
    TYPE: "Type",
    SEGMENTS: "Segments",
    ZIP_CODE_FULL: "ZIP Code",
  },
  APP: {
    WELCOME: "Welcome",
  },
  ORDER_STATUS: {
    DRAFT: "Draft",
    ON_DELIVERY: "On Delivery",
    DELIVERED: "Delivered"
  },
  USER_TABLE: {
    PERSONNEL: "Personnel",
    ROLE: "The Role of the User",
    ROLE_OPTIONS_PLACEHOLDER: "Select Role",
    ROLE_TEXT: "Role",
    JOB_DESCRIPTION: "Job description",
    PERSON: "Person",
    REPORTII: "Reportage",
    FILES: "Files",
    GENERAL: "General",
    NOTES: "Notes",
    INFORMATION: "Information",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL: "Email",
    WORK_PHONE: "Work Phone",
    WORK_PHONE_PLACEHOLDER: "Work Phone Number",
    CREATED_ON: "Created on",
    OVERVIEW: "Overview",
    SEARCH: "Search",
    ADD_NEW_PERSON: "Add new person",
    STATUS: "Status",
    PASSWORD: "Password",
    NEW_PASSWORD_PLACEHOLDER: "New Password",
    TITLE: "Title",
    HIDDEN_FIELD: "Hidden field",
    APPEARS_IN_SEARCH: "Appears in search",
    SHOW_NAME_INSIDE_FIELD: "Show the name inside the field",
    GLOBAL_FIELD: "Global field",
    REQUIRED: "Required",
    EDIT_MODE_TITLE: "Edit custom field",
    NOT_EDIT_MODE: "Add new field",
    USER: "User",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    DELETED: "Deleted",
    PVM_ALKU: "Pvm. alku",
    PVM_LOPPU: "Pvm. loppu",
    TYPE: "Type",
    USER_ROLE: "User Role",
    MANAGE_JOB_DESCRIPTION: "Manage Job Description",
    USER_INFORMATION: "User Information",
    Private: "Private",
    Company: "Company",
  },
  FILE: {
    ADD_FILE: "Add File",
    IMPORT_FILE: "Import File",
    NAME: "Name",
    PERSONNEL: "Personnel",
    DOCUMENT_TYPE: "Document Type",
    START_AT: "Start at",
    READY_AT: "Ready at",
    DESCRIPTION: "Description",
    FILE_CATEGORY: "File Category",
    FILE_INFORMATION: "File Information",
    FILE_FORMAT: "File Format",
    FILE_SIZE: "File Size",
    TITLE: "Title",
    CANCEL: "Close",
    DELETE: "Delete",
    MANAGE_FILE_CATEGORIES: "Manage File Categories",
    ADD: "Add",
    ENTER_TO_EDIT: "Press enter to edit",
    CLOSE: "Close",
    SAVE: "Save",
    DROP_FILE: "Drop files to upload",
    CHOOSE_FILE: "Choose Files",
    UPLOADING: "Uploading...",
    EDIT_FILE_NAME: "Edit Filename",
    FILE_NAME: "File name",
    SUBMIT: "Submit",
    PREVIEW: "Preview",
    EDIT: "Edit",
    REMOVE: "Remove",
    PVM_ALKU: "Pvm. alku",
    PVM_LOPPU: "Pvm. loppu",
  },
  NOTES: {
    READ_BY: "Read by",
    ADD_NOTES: "Add Notes",
    AUTHOR: "Author",
    AUTHOR_2: "Author",
    MARKINGS:"Markings",
    RECIPIENTS: "Recipients",
    REMINDER_DATE: "Reminder Date",
    MEMORENDUM: "Memorandum",
    NOTE_CATEGORY: "Notes Category",
    TYPE: "Type",
    USERS: "Users",
    EDIT: "Edit",
    DELETE: "Delete",
    RECIPIENT: "Recipient",
    CATEGORY: "Category",
    CREATED: "Created",
    ATTACHMENT: "Attachment",
    TIMING: "Timing",
    READ_RECIPIENTS: "Read Recipients",
    REMOVED_REPLY: "This reply has been removed by",
    REPLY: "Reply",
    MANAGE_NOTE_CATEGORY: "Manage Note Categories",
    NOTE_CATEGORY_PLACEHOLDER: "Note Category",
    ADD: "Add",
    CLOSE: "Close",
    REMOVE: "Remove",
    SCHEDULE: "Schedule",
    CANCEL: "Cancel",
    NOTE_RECEIVED_BY: "A new note received by",
    EDITED_REPLY_NOTE: "edited their reply to note",
    REPLIED_IN: `has replied to a note you're in.`,
    ATTACH_FILE: "Attach a file",
    NO_FILE_CHOOSEN: "No file chosen",
    EDIT_REPLY: "Edit Reply",
    ADD_CATEGORY: "Add Category",
    SUBJECT: "Subject",
    DISABLE: "Disable",
    ENABLE: "Enable",
    READ_NOTICES: "Read Notices",
    DISALLOW: "Disallow",
    ALLOW: "Allow",
    REPLIES: "Replies",
    PVM_ALKU: "Pvm. alku",
    PVM_LOPPU: "Pvm. loppu",
    SEARCH: "Search",
    MORE: "More",
    ATTACH_NAME: "Attachment name",
    MODIFIED: "Modified",
  },
  OFFER: {
    ADD_OFFER: "Add Offers",
    ADD_NEW_GROUP: "Add New Group",
    ACCEPT_OFFER: "Accept Offer",
    REJECT_OFFER: "Reject Offer",
    PRINT_OFFER: "Print Offer",
    PHONE_SHORT: "Ph.",
    DESCRIPTION: "Description",
    AMOUNT: "Amount",
    UNIT: "Unit",
    PRICE: "Price",
    TAX_PERCENTAGE: "Tax %",
    TAX_PERCENTAGE_ZERO: "Sub sum",
    DISCOUNT_PERCENTAGE: "Discount %",
    TAX: "Tax",
    IN_TOTAL: "In total",
    PROPERTY: "Property",
    SENDER: "Sender",
    RECIPIENT: "Recipient",
    SENT: "Sent",
    OFFER_NUMBER: "Offer number",
    TITLE: "Offer",
    CONFIRMATION_TITLE: "Order Details",
    MANAGE_PROPERTY_TYPES: "Manage Property Types",
    CREATE_NEW_ROW: "Create new row",
    PRODUCT_LIST: "Product List",
    GROUP_NAME: "Group Name",
    NEW_OFFER_GROUP: "New Offer Group",
    RETAIL_PRICE: "Retail Price",
    ADD_A_ROW: "Add a row",
    NO_PRODUCTS_ADDED: "No products added",
    NO_ITEMS_ADDED: "No items added",
    DUPLICATE_PRODUCT_IN_SAME_OFFER: "Duplicate product in same offer",
    TYPE_TO_SEARCH: "Type to search...",
    MANAGE_OFFER_STATUS: "Manage Offer Status",
    NO_OFFER_GROUPS_FOUND: "No offer groups found!",
    ATTACHMENTS: "Attachments",
    TAX_SEPARATED: "Tax Separated",
    TAX_INCLUDED: "Tax Included",
    TAX_EXCLUDED: "Tax Excluded",
    OFFER_STATUS: "Offer Status",
    OFFER_CREATED: "Offer Created",
    OFFER_SENT: "Offer Sent",
    OFFER_INFORMATION: "Offer Information",
    CONTACT_PERSON: "Contact Person",
    ADD_NEW: "Lisää uusi",
    PREVIEW_WITHOUT_TAX: "Without Tax Price",
    PREVIEW_TAX: "Tax",
    PREVIEW_TOTAL: "With Tax Price",
  },
  CONTACT_PRICE: {
    PRICE: "Add Prices",
  },
  STOCK: {
    CODE: "Code",
  },
  SIDEMENU: {
    FRONT_PAGE: {
      FRONT_PAGE: "Front Page",
    },
    Personnel: {
      Personnel: "Personnel",
      CRE: "Crew List",
    },
    Stock: {
      Stock: "Stock",
      PRD: "Products",
      VCL: "Vehicles",
      SUP: "Suppliers",
    },
    Customer: {
      Customer: "Customer",
      CUS: "Customers",
    },
    Offer: {
      Offer: "Offer",
      OFS: "Offers",
    },
    Order: {
      Order: "Order",
      ORD: "Orders",
    },
    Delivery: {
      Delivery: "Delivery",
      DLV: "Same day report",
    },
    Invoicing: {
      Invoicing: "Invoicing",
      INV: "Invoicing"
    },
  },
  NOTFOUND: {
    NOTFOUND_404: "Oops! Page not found",
    NOTFOUND: "The page you are looking is not here",
    HOME: "Home",
  },
  PRODUCT: {
    NEW_PRODUCT: "New Product",
    BASIC_INFO: "Basic Information",
    DELETE_PRODUCT: "Delete Product",
    newOrEditForm: {
      productName: 'Product Name',
      productCode: 'Product Code',
      taxStatus: 'Tax Status',
      taxRate: 'Tax Rate',
      STOCK_PRODUCT: 'Stock Product',
      brand: 'Brand',
      category: 'Category',
      units: 'Units',
      age: 'Age',
      quickDescription: 'Quick Description',
      longDescription: 'Long Description',
      productImage: 'Product Image',
      taxable: 'Taxable',
      nonTaxable: 'Non-Taxable',
      reversedTax: 'Reversed Tax',
      selectTaxRate: 'Select Tax Rate',
      manageBrands: 'Manage Brands',
      manageUnits: 'Manage Units',
      manageTaxRate: 'Manage Tax Rates',
      manageAge: 'Manage Age',
      productAge: 'Product Age',
      productCategory: 'Product Catagory',
      selectUnit: 'Select Unit',
      startTime: 'Start time',
      endTime: 'End time'
    },
    suppliers: {
      addSupplier: 'Add a supplier',
      addNew: 'Add new',
      selectSupplier: 'Select Supplier',
      ADD_CONTACT_PERSON: "Please add contact person to select"
    },
    manageStocks: {
      manageStockTitle: "Manage Stocks",
      totalInStock: "Total In Stock",
      wareHouse: "Warehouse",
      amount: "Amount",
      stocks: "Stocks",
      alarmLimit: "Alarm Limit",
      stockLessThanAlarmLimit: "Stock need to update",
    },
    manage: {
      supplier: "Supplier",
      category: "Category",
    },
    NEW_STOCK_TITLE: "New Stock",
    EDIT_STOCK_TITLE: "Edit Stock",
    NEW_DAILY_PRICE: "New Daily Price",
    EDIT_DAILY_PRICE: "Edit Daily Price",
    BASIC_PRICE: {
      TITLE: "Basic Prices",
      AMOUNT: "Amount",
      SUPPLIER: "Supplier",
      UNIT: "Unit",
      SELL_PRICE: "Sell Price",
      PURCHASE_PRICE: "Purchase Price",
      EDIT_PRICE_FOR: "Edit Pricings for"
    },
    VARIENT_PRICE: {
      TITLE: "Variant Prices",
      TIME_TITLE: "Time related prices",
      DAYS: "Days",
      FROM_TIME: "From time",
      TO_TIME: "To time",
      AMOUNT: "Amount",
      SUPPLIER: "Supplier",
      UNIT: "Unit",
      SELL_PRICE: "Sell Price",
      PURCHASE_PRICE: "Purchase Price",
    },
    CONCRETE_CHARACTERISTICS: {
      TITLE: "Product Characteristics",
      DIALOG_TITLE: "Manage Product characteristics",
      NAME: "Name",
      SET_OF_VALUES: "Set of Values",
    },
    LINK_PRODUCT: {
      TITLE: "Linked Products",
      ADDITIONAL_PRODUCT: "Additional Product (Cross-Sell)",
      SEARCH_PRODUCT: "Search Product",
      ADD_NEW: "Add new",
      SUBMIT: "Submit",
      PRODUCT_CODE: "Product Code",
      PRODUCT_NAME: "Product Name"
    },
  },
  SUPPLIER_INFO: {
    SUPPLIER_INFORMATION_HEADING: "Supplier Information",
    FACTORY_HEADING: "Factory",
    CONTACT_PERSONS_HEADING: "Contact Persons",
    BASIC_INFO: "Basic Information",
    PRODUCT: "Product",
    ADD_FIELD: "Add a field",
    MANAGE_COLUMN: "Manage Columns",
    NAME: "Supplier Name",
    COMPANY_ID: "Company ID",
    PHONE_NUM: "Phone number",
    EMAIL: "Email",
    EMAIL_PLACEHOLDER: "E-mail",
    ADDRESS: "Address",
    ZIP_CODE: "ZIP Code",
    POST_DISTRICT: "Post District",
    COUNTRY: "Country",
    STATUS: "Status",
    STATUS_ACTIVE: "Active",
    STATUS_INACTIVE: "Inactive",
    MANAGE_STATUS: {
      MANAGE_STATUS_HEADLINE: "Manage Status",
      ENTER_TO_EDIT: "Press enter to edit",
      SUPPLIER_PLACEHOLDER: "Supplier Status",
      ADD: "Add",
      DELETE: "Delete",
      CLOSE: "Close",
    },
    YET_TO_COMPLETE: "yet to complete",
    SEARCH: "Search",
    PVM_ALKU: "Pvm. alku",
    PVM_LOPPU: "Pvm. loppu",
  },
  FACTORY: {
    FACTORY_HEADLINE: "Factory Information",
    NAME: "Name",
    ADDRESS: "Address",
    COUNTRY: "Country",
    ZIP_CODE: "ZIP Code",
    POSTAL_DISTRICT: "Postal District",
    CONTACT_PERSON: "Contact Person",
    TELEPHONE_NUM: "Telephone Number",
    EMAIL: "Email",
    DELETE: "Delete",
    SAVE: "Save",
    EDIT: "Edit",
    ADD_FIELD: "Add a Field",
    FACTORY_AVAILABILITY: "Factory Availability",
  },
  CONTACT_PERSON: {
    EDIT: "Edit",
    DELETE: "Delete",
    NO_CONTACTS_MSG: "No contact persons added",
    CONTACT_HEADLINE: "Contact Person",
    ADD_FIELD: "Add a Field",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    TITLE: "Title",
    PHONE: "Phone",
    EMAIL: "Email",
    ADDITIONAL_INFO: "Additional Information",
    SAVE: "Save",
    STATUS: "Status",
    TYPE: "Type",
    USER_ROLE: "User Role",
  },
  CUSTOMER: {
    MANGE_CUSTOMER_ROLE: "Manage Customer Role",
    MANGE_CUSTOMER_STATUS: "Manage Customer Status",
    ADD_CONTRACT_PRICE: "Add Contract Price",
    EDIT_CONTRACT_PRICE: "Edit Contract Price",
    CONTRACT_PRICE_PRODUCT_LABEL: "Product",
    CONTRACT_PRICE_PRODUCT_PLACEHOLDER: "Select Product",
    CONTRACT_PRICE_DISCOUNT_LABEL: "Discount(%)",
    CONTRACT_PRICE_DISCOUNT_PLACEHOLDER: "Discount Percentage",
    CONTRACT_PRICES: "Contract Prices",
    CONTACT_PERSONS: "Contact Persons",
    OFFERS: "Offers",
    ORDERS: "Orders",
    INVOICES: "Invoices",
    BASIC_INFO: "Basic Information",
    FILES: "File Information",
    CONTACT_PERSONS_NEW: "Add new",
    CONTRACT_PRICES_HEADER: {
      product_code: "Product Code",
      product: "Product",
      discount: "Discount (%)",
      actions: "Actions",
    },
    CREDIT_INFORMATION: {
      TITLE: "Credit information",
      DATA_FETCHED: "Data fetched",
      CREDIT_RATING: "Credit rating",
      CREDIT_RATING_HISTORY: "Credit rating history",
      RATING: "Rating",
      RATING_DATE: "Rating Date",
      PAYMENT_METHOD_HISTORY: "Payment method history",
      PAYMENT_ABILITY_RATING: "Payment Ability Rating",
      DATE: "Date",
    },
    BILLING_INFO: {
      TITLE: "Billing Information",
      SAME_AS_BASIC_ADDRESS: "Same As Basic Address",
      ADDRESS: "Address",
      ZIP_CODE: "ZIP Code",
      POSTAL_DISTRICT: "Postal District",
      COUNTRY: "Country",
      E_INVOICING_ADDRESS: "E-Invoicing Address",
      OPERATOR_ID: "Operator ID",
      ORDERER: "Orderer",
      EMAIL: "Email",
      PHONE_NUMBER: "Phone Number",
      SELLER: "Seller",
    }
  },
  VEHICLES: {
    BASIC: {
      CANCEL: "Cancel",
      SUBMIT: "Submit",
      NAME: "Name",
      CATEGORY: "Category",
      SUPPLIER: "Supplier",
      REGISTRY_NUM: "Registry Number",
      MODEL: "Model",
      TYPE: "Type",
      MODEL_YEAR: "Model year",
      COLOR: "Color",
      LENGTH: "Length",
      WIDTH: "Width",
      HEIGHT: "Height",
      WEIGHT: "Weight",
      STATUS: "Status",
      PICTURES: "Pictures",
      AREA: "Area"
    },
    DRIVER: {
      ADD_DRIVER: "Add driver",
      SELECT_DRIVER: "Select Driver",
      ADD_NEW: "Add new",
      NAME: "Name",
      LAST_NAME: "Last name",
      PHONE_NUM: "Phone number",
      PRIMARY_DRIVER: "Primary driver",
      ADD_NEW_USER_HEADLINE: "Add new driver",
      FIRST_NAME: "First name",
      EMAIL: "Email",
      PASSWORD: "Password",
      WORK_PHONE: "Work Phone",
      WORK_PHONE_PLACEHOLDER: "Work Phone Number",
      ROLE: "Role",
      ROLE_OPTIONS_PLACEHOLDER: "Select Role",
      SWITCH_SECONDARY: "Secondary",
      SWITCH_PRIMARY: "Primary",
    },
    CAPACITY: {
      CAPACITY: "Capacity",
      UNIT: "Unit",
      AMOUNT: "Amount",
      BOOM_LENGTH: "Boom Length",
      M: "m",
      SHOW_IN_TABLE: "Show in table",
      LITRE: "Litre",
      L: "l",
      TONS: "Tons",
      T: "t",
      CUBIC_METER: "Cubic meter",
      M3: "m3",
    },
    INSTRUCTIONS: {
      CANCEL: "Cancel",
      SUBMIT: "Submit",
      NAME: "Name",
      TYPE: "Type",
      CREATED: "Created",
      VIEW_FILE: "View File"
    },
    MANAGE_CATEGORY: {
      HEADLINE: "Manage Categories",
      CATEGORY_PLACEHOLDER: "Vehicle Category",
      ADD: "Add",
      ENTER_TO_EDIT: "Press enter to edit",
      DELETE: "Delete",
      CLOSE: "Close",
      OFFER_STATUS: "Offer Status",
    },
    MANAGE_TYPE: {
      HEADLINE: "Manage Types",
      FILE_TYPE_PLACEHOLDER: "Vehicle Type",
      ADD: "Add",
      ENTER_TO_EDIT: "Press enter to edit",
      DELETE: "Delete",
      CLOSE: "Close",
    },
    MANAGE_AREA: {
      HEADLINE: "Manage Area",
      AREA_PLACEHOLDER: "Vehicle Area",
      ADD: "Add",
      ENTER_TO_EDIT: "Press enter to edit",
      DELETE: "Delete",
      CLOSE: "Close",
    },
    SEARCH: "Search",
    NEW_STOCK_TITLE: "New Stock",
    ADD_FIELD: "Add a field",
    PVM_ALKU: "Pvm. alku",
    PVM_LOPPU: "Pvm. loppu",
  },
  SMTP_SETTINGS: {
    HEADLINE: "SMTP Settings",
    FORM_NAME: "From Name",
    DRIVER: "Driver",
    MAIL_SERVER_HOST_NAME: "Mail Server Host Name",
    PORT: "Port",
    ENCRYPTION_METHOD: "Encryption Method",
    USER_NAME: "Username",
    PASSWORD: "Password",
  },
  COMPANYINFO_SETTINGS: {
    HEADLINE: "Company Settings",
    NAME:"Company Name",
    TITLE:"Company Title",
    ADDRESS:"Company Address",
    ZIP:"Company ZIP",
    POSTAL_DISTRICT:"Company Postal District",
    WWW:"Company WWW",
    LOGO:"Company Logo",
    EMAIL:"Company Email",
    PHONE_NUMBER:"Company Phone number",
    COMPANY_ID:"Company ID",
    TAX_ID:"Company Tax ID",
    OVT_ID:"Company OVT ID",
    BANK:"Company Bank",
    BANK_ACCOUNT:"Company Bank Account",
    BIC:"Company BIC",
    COMPANY_THEME:"Company Theme",
  },
  VEHICLE_TABLE: {
    REGISTRATION_NUM: "Registration Number",
    NAME: "Name",
    CATEGORY: "Category",
    MODEL: "Model",
    TYPE: "Type",
    MODEL_YEAR: "Model Year",
    COLOR: "Color",
    LENGTH: "Length",
    WIDTH: "Width",
    HEIGHT: "Height",
    WEIGHT: "Weight",
    CAPACITY: "Capacity",
    STATUS: "Status",
    SUPPLIER: "Supplier",
    DRIVER: "Driver",
    FILES_AND_INSTRUCTIONS: "Files and Instructions"
  },
  SUPPLIER_TABLE: {
    NAME: "Name",
    COMPANY_ID: "Company ID",
    PHONE: "Phone",
    EMAIL: "Email",
    STATUS: "Status",
    ADDRESS: "Address",
    ZIP: "ZIP",
    POSTAL_DISTRICT: "Postal District",
    CREATED_AT: "Created At",
  },
  CONTACT_PERSONS_IN_SUPPLIER: {
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    TITLE: "Title",
    PHONE_NUM: "Phone Number",
    EMAIL: "Email",
    ADDITIONAL_INFO: "Additional Information",
  },
  PRODUCT_TABLE: {
    PRODUCT_CODE: "Product Code",
    PRODUCT: "Product",
    COLOR: "Color",
    SUPPLIER: "Supplier",
    CATEGORY: "Category",
    PRODUCT_TYPE: "Product type",
    PRICE_RANGE: "Prices",
  },
  PRODUCT_VARIANT_TABLE: {
    PRODUCT_CODE: "Product Code",
    CLASS: "Class",
    SIZE: "Size",
    FLEXIBILITY: "Flexibility",
    HARDENING_CLASS: "Hardening Class",
    AMOUNT: "Amount",
    SUPPLIER: "Supplier",
    UNIT: "Unit",
    SELL_PRICE: "Sell Price",
    PURCHASE_PRICE: "Purchase Price",
  },
  CUSTOM_FIELDS_TABLE: {
    SECTION: "Section",
    EXTRA_FIELD: "Extra field",
    TYPE: "Type",
    GLOBAL: "Global",
    ORDER_IN_LIST: "Order in list",
    VISIBLE: "Visible",
    SEARCHABLE: "Searchable",
    EDIT: "Edit",
    DELETE: "Delete",
    SELECT_PLACEHOLDER: "Select...",
  },
  CRON_TAB: {
    CRON_JOB_P:
      "Cron jobs allow you to automate certain commands or scripts on your site. You can set a command or script to run at a specific time every day, week, etc. For example, you could set a cron job to delete temporary files every week to free up disk space.",
    WARNING: "Warning",
    WARNING_MSG:
      "You need to have a good knowledge of Linux commands before you can use cron jobs effectively. Check your script with your hosting administrator before adding a cron job.",
    CRON_EMAIL: "Cron Email",
    SEND_EMAIL_MSG:
      "Send an email with the output every time the cron job runs.",
    GENERAL_EXAMPLE: "General example",
    DOMAIN_EXAMPLE: "Domain-specific example",
    EXAMPLE_MSG:
      'In the above example, replace “ea-php99” with the PHP version assigned to the domain you wish to use. Look in the <a role="button" className="text-primary">MultiPHP Manager</a> for the actual PHP version assigned to a domain.',
    ADD_NEW_CRON_JOB: "Add New Cron Job",
    PHP_COMMAND_EXAMPLES: "PHP command examples:",
    EDIT: "Edit",
    DISABLE: "Disable",
    ENABLE: "Enable",
    DELETE: "Delete",
    ADD_CRON: {
      TITLE: "Title:",
      COMMON_SETTINGS: "Common Settings",
      COMMON_SETTING_OPTIONS: "--Common Settings--",
      MINUTE: "Minute:",
      HOURS: "Hours:",
      DAY: "Day:",
      MONTH: "Month:",
      WEEKDAY: "Weekday:",
      COMMAND: "Command:",
    },
    CURRENT_CRON_JOBS: {
      HEADLINE: "Current Cron Jobs",
      TITLE: "Title",
      MINUTE: "Minute",
      HOUR: "Hour",
      DAY: "Day",
      MONTH: "Month",
      WEEKDAY: "Weekday",
      COMMAND: "Command",
      ACTIONS: "Actions",
      EDIT_CRON: "Edit Cron",
    },
  },
  FORM: {
    BACK: "Back",
    CANCEL: "Cancel",
    PLEASE_WAIT: "Please wait...",
    SUBMIT: "Submit",
  },
  ASIDE: {
    OWN_ACCOUNT: "Own Account",
    SIGNOUT: "Logout",
    COMPANY_SETTINGS: "Company Settings",
    SMTP_SETTINGS: "SMTP Settings",
    CUSTOM_FIELDS: "Custom Fields",
    CRON_JOBS: "Cronjobs",
    HELP_TAB: "Help Tab",
    TOGGLE: "Toggle",
    HELP: "Help",
    SETTINGS: "Settings",
    MENU: "Menu",
  },
  DATA_TABLE: {
    MANAGE_COLUMNS: "Manage Columns",
    PREVIOUS: "Previous",
    NEXT: "Next",
    LAST_PAGE: "Last Page",
    FIRST_PAGE: "First Page",
    GO_TO_PAGE: "Go to page",
    ALL: "All",
    UPLOADING: "Uploading...",
    ACTIONS: "Actions",
    NOTHING_TO_SHOW: "No Record Found!",
    RESULTS: "Results",
  },
  BREAD_CRUMBS: {
    CUSTOM_FIELDS: "Custom Fields",
    CRON_JOBS: "Cron Jobs",
    HOME: "Home",
    DASHBOARD: "Dashboard",
  },
  HEADER: {
    LOGOUT: "Logout",
  },
  MENU_BAR: {
    BOLD: "Bold",
    ITALIC: "Italic",
    STRIKE: "Strike",
    PARAGRAPH: "Paragraph",
    H1: "H1",
    H2: "H2",
    H3: "H3",
    H4: "H4",
    H5: "H5",
    H6: "H6",
    BULLETED_LIST: "Bulleted List",
    NUMBERED_LIST: "Numbered List",
    BLOCK_QUOTE: "Block Quote",
    HORIZONTAL_RULE: "Horizontal Rule",
    HARD_BREAK: "Hard Break",
    UNDO: "Undo",
    REDO: "Redo",
    LEFT: "Left",
    RIGHT: "Right",
    CENTER: "Center",
    JUSTIFY: "Justify",
    INDENT: "Indent",
  },
  NOTIFICATIONS: {
    TITLE: "Notifications",
    DATA_TITLE: "Notification",
    NO_NOTIFICATION: "No notifications to show",
    NEW_NOTE: "New Note Received",
    DELETED_NOTE: "Note Deleted",
    UPDATE_IN_NOTES: "Update in Notes",
  },
  INVOICE: {
    INVOICE_INFO: "Invoice Information",
    INVOICE_TITLE: "Invoice identification and payment information",
    HEADERS: {
      invoiceNumber: "Invoice ID",
      submittedToNetvisor: "Submitted to Netvisor",
      invoice_status: "Invoice Status",
      invoiceAdditionalDetail: "Additional information",
      created_at: "Created on",
      order_number: "Order Number",
      firstname: "Customer"
    },
    LABEL: {
      INVOICE_STATUS: "Invoice Status",
      INVOICE_TYPE: "Invoice Type",
      INVOICE_CREATED: "Invoice Created",
      INVOICE_ID: "Invoice ID",
      SUBMITTED_TO_NETVISOR: "Submitted to Netvisor",
      ORDER_NUMBER: "Order Number",
      CUSTOMER: "Property",
      SELLER: "Seller",
      ADDITIONAL_INO: "Additional information",
      PRODUCTS: "Products",
      PRODUCT: "Product",
      AMOUNT: "Amount",
      UNIT: "Unit",
      PRICE_TAX_FREE: "Unit Price",
      VAT: "VAT",
      DISCOUNT: "Discount",
      TAX_FREE_PRICE: "Sub total",
      TAX: "Tax",
      PRICE_WITH_TAX: "Sub including tax",
      FETCH_PRODUCT: "Fetch Products",
      ADD_INVOICE_STATUS: "Add Invoice Status",
      MANAGE_INVOICE_STATUS: "Manage Invoice Status",
      MANAGE_INVOICE_TYPE: "Manage Invoice Type",
      ADD_INVOICE_TYPE: "Add Invoice Type",
      ADD_CATEGORY: "Add Category",
      ADD_BRAND: "Add Brand name",
      WAREHOUSE: "Ware house",
      IN_TOTAL: "In Total",
      ADD_BILLING_LINE: "Add Billing Line"
    },
     DELIVERY: {
       DELIVERIES: "Deliveries",
       ORDER: "Order",
       DELIVERY_BATCH_EQUIPMENT_DRIVER: "Delivery batch, equipment and driver",
     }
  },
  ORDER: {
    ORDER_INFO: "Order Information",
    MANAGE_STATUS: "Manage Order Status",
    ADD_STATUS: "Add Order Status",
    DELIVERY_PROTOCOL: "Delivery Protocol",
    DELIVERY_PROTOCOL_HYBRID: "Betoni Pumppus Protocol",
    FETCHING_DETAILS: "Fetching details",
    LOADING_YOUR_ORDER: "Loading your order",
    ADD_SIGNATURE: "Add Signature",
    ADD_PRODUCT: "Add product",
    CUSTOMER_BILLING_ADDRESS: "Customer/Billing address",
    RECIPIENT_SHIPPING_ADDRESS: "Recipient/Shipping address",
    QUANTITY: "Quantity",
    NUMBER_OF_DELIVERED_LOADS_TOTAL_M3: "Number of delivered loads/Total m3",
    ARRIVE: "Saapunut",
    GONE: "Lähtenyt",
    KM: "Km",
    REG_NO: "Rek.no",
    NOTES_ON_DELIVERY: "Notes on delivery",
    RECIPIENTS_ACKNOWLEDGMENT_AND_PHONE_NUMBER :"Recipient's acknowledgment and phone number",
    ORDER_CREATED: "Order Created",
    LABEL: {
      ORDER_NUMBER: "Order Number",
      CUSTOMER_NAME: "Customer Name",
      OFFER: "Offer",
      ORDER_STATUS: "Order Status",
      SELLER: "Seller",
      ADDITIONAL_INFO: "Additional Information",
      SCHEDULE: "Schedule",
      START_DATE: "Start Date",
      END_DATE: "End Date",
      START_TIME: "Start Time",
      END_TIME: "Start Time",
      WORKER: "Worker",
      ROUND: 'Round',
      SELECT_CUSTOMER_PROPERTY: "Please select customer property",
      START_DELIVERY_DATE: "Start Delivery",
      REACH_FACTORY_DATE: "Reach Factory",
      REACH_FACTORY_DISTANCE: "Reach Factory Distance",
      START_LOADING_VEHICLE_DATE: "Start Loading Vehicle",
      STOP_LOADING_VEHICLE_DATE: "Stop Loading Vehicle",
      DRIVE_DESTINATION_DATE: "Drive Destination",
      REACH_DESTINATION_DATE: "Reach Destination",
      REACH_DESTINATION_DISTANCE: "Reach Destination Distance",
      CONCRETE_PUMPTRUCK_PHOTO_ADDED: "Pumptruck Photo Added",
      CONCRETE_PUMPTRUCK_START_PUMPING_DATE: "Start Pumping",
      CONCRETE_PUMPTRUCK_STOP_PUMPING_DATE: "Stop Pumping",
      START_UNLOADING_VEHICLE_DATE: "Start Unloading Vehicle",
      STOP_UNLOADING_VEHICLE_DATE: "Stop Unloading Vehicle",
      STOP_UNLOADING_VEHICLE_JACKSHOTS: "Stop Unloading Vehicle Jackshots",
      STOP_UNLOADING_VEHICLE_DISTANCE: "Stop Unloading Vehicle Distance",
      FINISH_DELIVERY_DATE: "Finish Delivery",
      STOP_DELIVERY_DISTANCE: "Stop Delivery Distance",
      PUMPTRUCK_START_MOUNTING_DATE: "Start Mounting",
      PUMPTRUCK_PHOTO_ADDED_DATE: "Pumptruck Photo Added",
      PUMPTRUCK_FORM_ADDED_DATE: "Pumptruck Form Added",
      PUMPTRUCK_START_PUMPING_DATE: "Start Pumpping",
      PUMPTRUCK_STOP_PUMPING_DATE: "Stop Pumpping",
      PUMPTRUCK_STOP_PUMPING_JACKSHOTS: "Stop Pumpping Jackshots",
      FLOORMAN_START_WORKING_DATE: "Start Working",
      FLOORMAN_STOP_WORKING_DATE: "Stop Working",
      FLOORMAN_START_SEPERATE_HOURLY_WORK_DATE: "Start Seperate Hourly Work",
      FLOORMAN_STOP_SEPERATE_HOURLY_WORK_DATE: "Stop Seperate Hourly Work",
      FLOORMAN_START_WAITING_TIME_DATE: "Start Waiting Time",
      FLOORMAN_STOP_WAITING_TIME_DATE: "Stop Waiting Time",
      COPY: "Copy Order",
    },
    CUSTOMER: {
      CUSTOMER_INFO: "Customer Information",
      BASIC_INFO: "Basic Information",
      BILLING_INFO: "Invoicing Information",
    },
    PROPERTY: {
      NEW_TYPE: "New Property Type",
      MANAGE_TYPE: "Manage Property Types",
      ADD_TYPE: "Add Property Type",
      PRESS_ENTER: "Press Enter",
      PROPERTY_INFO: "Properties",
      ADD_NEW_PROPERTY: "Add New Customer Property",
      PROPERTY_TYPE: "Property Type",
      FULL_ADDRESS: "Full Address",
      ADDRESS: "Address",
      ZIP_CODE: "Zip Code",
      POSTAL_DISTRICT: "Postal District",
      POSTAL_OFFICE: "Postal Office",
      PROPERTIES: "Properties",
      UPDATE_PROPERTY_TYPE: "Update Property Type",
      NEW_PROPERTY_TYPE: "New Property Type",
    },
    DELIVERY: {
      DELIVERY_INFO: "Deliveries",
      ADD_NEW_DELIVERY: "Add new delivery",
      ADD_NEW_VEHICLE: "Add new vehicle",
      ADD_NEW_ONE: "Add new one",
      ADD_NEW_CREW: "Add new crew",
      SELECT_CREW: "Select Crew",
      ADD_SHIPPING: "Add shipping",
      ADD_DELIVERY_SCHEDULE: "Add Delivery Schedule",
      DELIVERIES: "Deliveries",
      PRODUCT: "Product",
      AMOUNT: "Amount",
      UNIT: "Unit",
      PUMP_PRODUCT: "Product for pumping",
      CONCRETE_PRODUCT: "Product for concrete",
      DELIVERY_AREA: "Delivery Area",
      FACTORY: "Factory",
      DELIVERED_AT: "Delivered At",
      STATUS: "Status",
      DELIVERY_TIME: "Delivery Time",
      STARTED: "Started",
      FINISHED: "Finished",
      DELIVERY_BATCHES: "Delivery Batches",
      PERIODS: "Periods",
      PERIODS_TIME: "Jackshot Time",
      EQUIPMENT_DRIVERS: "Equipment and Drivers",
      AT: "At",
      VEHICLE: "Vehicle",
      EQUIPMENT_TYPE: "Equipment Type",
      DRIVER: "Driver",
      ROUND: "Round",
      RETURN_FREIGHT: "Return Freight",
      WEIGHT: "Weight",
      NO_DELIVERY_FOUND: 'No deliveries found',
      CONCRETE_QUALITY: "Concrete quality",
      PUMPING_QUALITY: "Pumping quality",
      ADD: "You add",
      ADDITIVES :"Additives",
      DISMANTLING_METHOD: "Dismantling method",
      WASHING_METHOD: "Washing Method",
      LINE_ALLOWANCE: "Line allowance",
      FLEXIBLE: "Flexible",
      ADD_GUTTER: "Add a Gutter",
      ROLLER_COASTER: "Roller Coasters",
      WATER: "Water",
      DELIVERY_SCHEDULES: "Delivery schedules",
      AMOUNT_OF_RETURN_FREIGHT: "Amount of return freight",
      DUMP_TRUCK: "Estimated Amount",
      START: "Start",
      ARRIVED_AT_FACTORY: "Arrived at the factory",
      TRAVEL: "Travel",
      LOADING: "Loading",
      MINUTES_LOADED: "Minutes Loaded",
      TRANSPORT_TO_DESTINATION: "Transport to Destination",
      UNLOADING_TIME: "Unloading Time",
      EPISODES_OVER: "Episodes Over",
      START_MOUNTING: "Start Mounting",
      START_PUMPING: "Start Pumping",
      STOP_PUMPING: "Stop Pumping",
      STOP_DELIVERY: "Stop Delivery",
      PUMP_TRUCK_PHOTO_ADDED: "Pump truck photo added",
      PUMP_TRUCK_FORM_ADDED: "Pump truck form added",
      CONCRETE_PUMP_TRUCK_PHOTO_ADDED: "Concrete pump truck photo added",
      CONCRETE_PUMP_TRUCK_FORM_ADDED: "Concrete pump truck form added",
      CONCRETE_PUMP_TRUCK_START_PUMPING:"Concrete pump truck start pumping",
      CONCRETE_PUMP_TRUCK_STOP_PUMPING: "Concrete pump truck stop pumping",
      CAST_INTERESET_MARK: "Cast Intereset Mark",
      INTEREST_GIVEN_FROM_WHERE: "Interest given from where",
      WHO_GAVE_THE_INTEREST: "who gave the interest",
      SURFACE_QUALITY_REQUIREMENT: "Surface quality requirement",
      AFTERCARE: "Aftercare",
      AFTERCARE_FROM_CUSTOMER: "Aftercare from customer",
      WEATHER: "Weather",
      RISKS_THAT_WEAKEN_THE_FINAL_RESULT: "Risk that weaken the final result",
      RISK_STATED: "Risk Stated",
      RISKS_STATED_TO_WHOM: "Risk stated to whom",
      REPAIRS_DONE: "Repairs done",
      REPAIRS_DONE_WHO_FIXED_IT: "Repairs done who fixed it",
      PROCEEDINGS_AND_COVERINGS: "Proceedings and Coverings",
      SQUARE_DISTRIBUTE_TO_PERSON: "Squares distributed to employees",
      HOURS_DISTRIBUTE_TO_PERSON: "Hours distributed to employees",
      INDIVIDUAL_HOURS: "Individual",
      PERIOD_HOURS: "period",
      CHOOSE: "Select",
      YES: "Yes",
      NO: "No",
      DELIVERED: "Delivered",
      CHECKPOINTS: "Checkpoints",
      UNDERGONE_STRUCTURAL: "The pump truck has undergone structural inspections (incl. boom cylinders and hyd. hoses)",
      TWELVE_MONTH_INSPECTIONS: "12 month inspections",
      RE_INSPECTION: "Re-inspection",
      SHORTCOMINGS_AND_ERRORS_HAVE_BEEN_CORRECTED: "Shortcomings and errors have been corrected",
      THE_PUMP_TRUCK_COMES_WITH_THE_NECESSARY_DOCUMENTS: "The pump truck comes with the necessary operating and maintenance instructions and other necessary documents",
      THE_CONDITION_OF_THE_SUPPLY_PIPING: "The condition of the supply piping",
      VISUAL_CONTACT_WITH_THE_CASTING_TARGET: "Visual contact with the casting target",
      SAFE_PERFORMANCE_OF_PUMPING_WORK: "The following items must be in the condition required for the safe performance of pumping work:",
      ASSISTIVE_DEVICES: "Assistive devices",
      PLACE_OF_USE: "Place of use",
      GROUND_BASE: "The ground base of the working platform",
      PLATFORM_STABILITY: "Platform stability",
      POWER_LINES_AND_WIRES: "Power lines and wires. Note! Protective distances.",
      TRENCHES: "The trenches",
      TRAFFICKED_AREAS: "Trafficked areas / Trafficked areas",
      WEATHER_CONDITIONS: "Weather conditions",
      CHECKS_LINE_PUMPING: "Checks related to line pumping",
      SAFETY_AND_PROTECTION_DISTANCES_FOR_LINE_PUMPING: "Safety and protective distances for line pumping",
      CONSIDERATION_OF_OTHER_MACHINES: "Consideration of other machines",
      WORKING_HOURS: "Working Hours",
      ADD_FLOORMAN_PROTOCOL: "Add Floorman Protocol",
      ADD_FLOORMAN_COVER: "Add Floorman Cover",
      FLOORMAN_PROTOCOL: "Floorman Protocol",
      ADD_PUMPTRUCK_PROTOCOL: "Add Pumptruck Protocol",
      ADD_PUMPTRUCK_COVER: "Add Pumptruck Cover",
      ADD_PUMPTRUCK_MOUNTING_COVER: "Add Pumptruck Mounting Cover",
      ADD_CONCRETE_COVER: "Add Concrete Cover",
      ADD_CON_PUMP_FACTORY_COVER: "Add Factory Cover",
      ADD_CON_PUMP_COVER: "Add Concrete Pumptruck Cover",
      ADD_CON_PUMP_MOUNTING_COVER: "Add Concrete Pumptruck Mounting Cover",
      ADD_CON_PUMP_CONCRETE_COVER: "Add Concrete Cover",
      ADD_CON_PUMP_RETURN_FREIGHT_COVER: "Add Return Freight Cover",
      NOTES_ON_DELIVERY: "Huomautuksia toimituksesta",
    },
    ATTACHMENT: {
      ATTACHMENT_INFO: "Attachments",
    },
    OFFER_DETAILS: {
      OFFER_BASIC_INFO: "Basic Information of Offer",
      OFFER_CONTENT: "Offer Content",
      OFFER_CONTENT_PREVIEW: "Order Confirmation Preview",
      TAX_STATUS: "Tax Status",
    },
    VEHICLE: {
      EDIT_VEHICLE: "Edit a Vehicle",
      ADD_VEHICLE: "Add a Vehicle"
    },
    CREW: {
      ADD_CREW: "Add a crew",
      EDIT_CREW: "Edit a crew"
    }
  },
};
