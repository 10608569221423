import moment from "moment";
import { useLocation } from "react-router-dom";
import { PRODUCT_TYPES } from "../redux/constants";

export const getUA = () => {
  let device = "Unknown";
  const ua = {
    "Generic Linux": /Linux/i,
    Android: /Android/i,
    BlackBerry: /BlackBerry/i,
    Bluebird: /EF500/i,
    "Chrome OS": /CrOS/i,
    Datalogic: /DL-AXIS/i,
    Honeywell: /CT50/i,
    iPad: /iPad/i,
    iPhone: /iPhone/i,
    iPod: /iPod/i,
    macOS: /Macintosh/i,
    Windows: /IEMobile|Windows/i,
    Zebra: /TC70|TC55/i,
  };
  Object.keys(ua).map((v) => navigator.userAgent.match(ua[v]) && (device = v));
  return device;
};

export const Capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

/**
 *
 * @param {Object} filters send filters for the module
 * @param {Object} paginationData send pagination data, current page, total page, total data
 * @param {Object} sortingData send sorting data
 * @param {Boolean} includePagination pass false if needed to remove pagination
 * @returns returns String
 */

export const mapPaginationAndParams = (
  filters,
  paginationData,
  sortingData = new Object(),
  includePagination = true
) => {
  let params = includePagination
    ? `page=${paginationData.page}&perPage=${paginationData.perPage}`
    : "";
  Object.keys(filters).map((m) => {
    if (filters[m]) {
      if (Boolean(typeof filters[m] == "object" && filters[m].length > 0)) {
        filters[m].map((f) => {
          params += `&${m}[]=${f.toString()}`;
        });
      } else if (typeof filters[m] != "object") {
        params += `&${m}=${filters[m]}`;
      }
    }
  });
  if (Object.keys(sortingData).length) {
    params += `&sortBy=${sortingData.sortBy}&orderBy=${sortingData.orderBy}`;
  }
  return params;
};

const searchTextParsing = (textValue) => {
  return encodeURIComponent(textValue);
};

export const updateFilters = (
  value,
  name,
  setPaginationData,
  setFilters,
  paginationData,
  filters,
  isChecked = false
) => {
  const temp = structuredClone(filters);
  setPaginationData({ ...paginationData, page: 1 });
  if (typeof filters[name] === "object") {
    if (isChecked) {
      temp[name].push(value);
    } else {
      temp[name] = temp[name].filter((f) => f != value);
    }
  } else {
    if (
      name === "search" &&
      value !== "" &&
      typeof JSON.parse(value) === "object"
    ) {
      const tempValue = [];

      const parsedValue = JSON.parse(value);
      if (Array.isArray(parsedValue)) {
        parsedValue.forEach((obj) => {
          typeof obj === "object" &&
            !Array.isArray(obj) &&
            Object.keys(obj).forEach((key) =>
              tempValue.push({ value: searchTextParsing(obj[key]) })
            );

          if (typeof obj != "object") {
            const parsedObj = JSON.parse(obj);
            typeof parsedObj === "object" &&
              !Array.isArray(parsedObj) &&
              Object.keys(parsedObj).forEach((key) =>
                tempValue.push({ value: searchTextParsing(parsedObj[key]) })
              );
          }
        });
      } else {
        typeof parsedValue === "object" &&
          !Array.isArray(parsedValue) &&
          Object.keys(parsedValue).forEach((key) =>
            tempValue.push({ value: searchTextParsing(parsedValue[key]) })
          );
      }

      value = structuredClone(JSON.stringify(tempValue));
    }
    temp[name] = value;
  }
  setFilters(structuredClone(temp));
};

export const resetFilters = (setFilters, initialFilters) => {
  setFilters({ ...initialFilters });
};

export const getDateOnly = (d) => {
  let now = new Date(d);
  return [
    AddZero(now.getDate()),
    AddZero(now.getMonth() + 1),
    now.getFullYear(),
  ].join(".");
};

export const getDateOnlyForInput = (d) => {
  let now = new Date(d);
  return [
    now.getFullYear(),
    AddZero(now.getMonth() + 1),
    AddZero(now.getDate()),
  ].join("-");
};

export const getTimeOnly = (d) => {
  let now = new Date(d);
  return [
    [
      AddZero(now.getHours().toLocaleString()),
      AddZero(now.getMinutes().toLocaleString()),
    ].join(":"),
  ].join(" ");
};

export const convertUTCDateToLocalDate = (d) => {
  let date = new Date(d);
  let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  let offset = date.getTimezoneOffset() / 60;
  let hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};

export const getLocalTimeOnly = (d, format = "HH:mm") => {
  return moment(d).local().format(format);
  // return moment.utc(d).local().format(format);
};

export const getLocalDateTime = (d, format = "YYYY-MM-DD HH:mm") => {
  return moment(d).local().format(format);
  // return moment.utc(d).local().format(format);
};

export const getUTCToLocalTimeOnly = (d, format = "HH:mm") => {
  return moment.utc(d).local().format(format);
};

export const getUTCToLocalDateTime = (d, format = "YYYY-MM-DD HH:mm") => {
  return moment.utc(d).local().format(format);
};

export const compareStartEndDateValidation = (start, end, format) => {
  const dateIsAfter = moment(start, format).isAfter(moment(end, format));
  const dateIsSame = moment(start, format).isSame(moment(end, format));  
  const dateIsBefore = moment(end, format).isBefore(moment(start, format));  
  return (dateIsAfter || dateIsSame || dateIsBefore) ? false : true;
}


function AddZero(num) {
  return num >= 0 && num < 10 ? "0" + num : num + "";
}

export const useQuery = () => new URLSearchParams(useLocation().search);

export const sortArrayOfObjects = (property) => {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const useCustomFieldsUpdator = (customFields) => {
  return (cf_id, value) => {
    const fieldIndex = customFields.findIndex((f) => f.cf_id === cf_id);
    customFields[fieldIndex].value = value;
    customFields[fieldIndex].hasError = false;
    return customFields;
  };
};

export const validateCustomFieldsValue = (customFields) => {
  return customFields.map((m) => {
    let hasError = false;
    if (m.field_type === "date to date") {
      let dtd = m.value === "" ? JSON.stringify([]) : m.value;
      hasError =
        Boolean(
          JSON.parse(dtd.replace(/\\/g, "")).filter((f) => f).length < 2
        ) && m.required;
    } else {
      hasError = (!m.value || m.value === "[]") && m.required;
    }
    return {
      ...m,
      hasError,
    };
  });
};

export const generateRandomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const parseQuery = (queryString) => {
  var query = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
};

export const customPagination = ({ data, page, perPage }) => {
  const firstPageIndex = (page - 1) * perPage;
  const lastPageIndex = firstPageIndex + perPage;
  return data.slice(firstPageIndex, lastPageIndex);
};

export const minimizeStrings = (string) => {
  if (string.length < 10) {
    return string;
  } else {
    return string.substring(0, 10) + "...";
  }
};

export const isValidId = (id) => {
  return !/[a-zA-Z]/g.test(id);
};

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  let byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //New Code
  return new Blob([ab], { type: mimeString });
};

export const capitalize = (str) => {
  let i;
  let frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

export const getCurrentDate = moment().format("YYYY-MM-DD");

export const htmlToText = (htmlString) => {
  // Remove HTML tags using regex
  let plainText = htmlString.replace(/<[^>]+>/g, "");

  return plainText;
};

export const sortAndMergeArrays = (arr1, arr2) => {
  // Concatenate the arrays
  const mergedArr = arr1.concat(arr2);

  // Sort the merged array by order_number
  mergedArr.sort((a, b) => a.order_number - b.order_number);

  // Use a Map to keep track of unique elements by name
  const uniqueMap = new Map();
  mergedArr.forEach((item) => {
    if (!uniqueMap.has(item.name)) {
      uniqueMap.set(item.name, item);
    }
  });

  // Create an array from the unique values in the Map
  const uniqueArr = Array.from(uniqueMap.values());

  // Find the first occurrence of each item in the original arrays
  const resultArr = uniqueArr.map((item) => {
    const itemInArr1 = arr1.find((elem) => elem.name === item.name);
    const itemInArr2 = arr2.find((elem) => elem.name === item.name);
    if (itemInArr1 && itemInArr2) {
      if (itemInArr1.product_stock !== null) {
        return itemInArr1;
      } else {
        return itemInArr2;
      }
    } else if (itemInArr1) {
      return itemInArr1;
    } else {
      return itemInArr2;
    }
  });

  return resultArr;
};

export const validateData = (arr, type) => {
  for (let i = 0; i < arr.length; i++) {
    const obj1 = arr[i];
    for (let j = i + 1; j < arr.length; j++) {
      const obj2 = arr[j];
      if (type === "concrete") {
        if (
          obj1.vehicle_id === obj2.vehicle_id &&
          obj1.driver_id === obj2.driver_id
        ) {
          if (
            obj1.startDate === obj2.startDate &&
            obj1.endDate === obj2.endDate &&
            ((obj1.startTime <= obj2.startTime &&
              obj1.endTime > obj2.startTime) ||
              (obj2.startTime <= obj1.startTime &&
                obj2.endTime > obj1.startTime))
          ) {
            return false;
          }
        } else {
          if (
            obj1.startDate === obj2.startDate &&
            obj1.endDate === obj2.endDate &&
            ((obj1.startTime <= obj2.startTime &&
              obj1.endTime > obj2.startTime) ||
              (obj2.startTime <= obj1.startTime &&
                obj2.endTime > obj1.startTime))
          ) {
            return false;
          }
        }
      } else if (type === "floor") {
        if (obj1.crew_id === obj2.crew_id) {
          if (
            obj1.startDate === obj2.startDate &&
            obj1.endDate === obj2.endDate &&
            ((obj1.startTime <= obj2.startTime &&
              obj1.endTime > obj2.startTime) ||
              (obj2.startTime <= obj1.startTime &&
                obj2.endTime > obj1.startTime))
          ) {
            return false;
          }
        } else {
          if (
            obj1.startDate === obj2.startDate &&
            obj1.endDate === obj2.endDate &&
            ((obj1.startTime <= obj2.startTime &&
              obj1.endTime > obj2.startTime) ||
              (obj2.startTime <= obj1.startTime &&
                obj2.endTime > obj1.startTime))
          ) {
            return false;
          }
        }
      }
    }
  }
  return true;
};

const getType = (vehicle) =>
  vehicle?.capacity !== null
    ? "capacity"
    : vehicle?.l !== null
    ? "l"
    : vehicle?.t !== null
    ? "t"
    : "";



// THIS IS A COMMON FUNCTION FOR ALL VEHICLE ROUND RELATED DATA
export const distributeAmount = (amount, oldVehicles, mainCategoryCode = []) => {
  let remainingAmount = Math.floor(Number(amount));
  let vehicleIndex = 0;
  let isFloorman = oldVehicles.some((ov) => ov.crew_id && ov.crew_id.length > 0 );
  let oldVehicleRound = null;
  let vehicles = oldVehicles.map((ov) => {
    const oldDelivery = ov.round_details.filter((rd) => rd.id !== '');
    if(oldDelivery.length > 0) {
      oldVehicleRound = oldDelivery[0];
    }
    return {
      ...ov,
      amount: 0,
      round_details: [], //ov.round_details.map((rd) => ({ ...rd, amount: 0 })),
    };
  });
  while (remainingAmount > 0 && !isFloorman) {
    vehicles.map((vehicle) => {
      //const vehicle = vehicles[vehicleIndex];
      const rounds = vehicle.round_details;
      console.log(vehicle);
      let maxCapacity = 1;
      if (vehicle?.capacity) {
        maxCapacity = vehicle?.capacity || 1;
      } else {
        maxCapacity =
          vehicle?.vehicle_details[getType(vehicle?.vehicle_details)] || 1;
      }

      // for (let i = 0; i < rounds.length && remainingAmount > 0; i++) {
      //   const round = rounds[i];
      //   const amountToDistribute = vehicle.flag_hybrid_pump_truck === 1 ? remainingAmount : Math.min(
      //     Math.floor(remainingAmount),
      //     Math.floor(maxCapacity - Number(round.amount))
      //   );
      //   round.amount += amountToDistribute;
      //   vehicle.amount += amountToDistribute;
      //   remainingAmount -= amountToDistribute;
      // }

      if (remainingAmount > 0 ) {

        const newRoundAmount = vehicle.flag_hybrid_pump_truck === 1 ? remainingAmount : Math.min(
          Math.floor(remainingAmount),
          Math.floor(maxCapacity)
        );
        if (newRoundAmount > 0) {
          const newRound = {
            parent_id: vehicle.id,
            vehicle: vehicle.vehicle,
            driver: vehicle.driver,
            vehicle_details: vehicle.vehicle_details,
            crew: vehicle?.crew,
            crew_id: vehicle.crew_id ? vehicle.crew_id : "",
            amount: Math.min(parseInt(newRoundAmount), parseInt(maxCapacity)),
            delivery_batch: 1,
            order_delivery_id: vehicle.order_delivery_id,
            order_id: vehicle.order_id,
            factory_id: vehicle.factory_id,
            line_allowance: oldVehicleRound?.line_allowance || null,
            line_allowance2: oldVehicleRound?.line_allowance2 || null,
            roller_coasters: oldVehicleRound?.roller_coasters || null,
            roller_coasters2: oldVehicleRound?.roller_coasters2 || null,
            washing_methods_id: oldVehicleRound?.washing_methods_id || null,
            unloading_methods_id: oldVehicleRound?.unloading_methods_id || null,
            periods: oldVehicleRound?.periods || null,
            return_freight: oldVehicleRound?.return_freight || null,
            other_than_at_site: oldVehicleRound?.other_than_at_site || null,
            flag_hybrid_pump_truck: oldVehicleRound?.flag_hybrid_pump_truck || null,
            gutter: oldVehicleRound?.gutter || null,
            more_water: oldVehicleRound?.more_water || null,
            dump_truck: oldVehicleRound?.dump_truck || null,
            equipment_type: oldVehicleRound?.equipment_type || null,
            softener: oldVehicleRound?.softener || null,
            weighted_qty: oldVehicleRound?.weighted_qty || null,
          };
          vehicle.round_details.push(newRound);
          vehicle.amount += newRound.amount;
          remainingAmount -= newRound.amount;
        }
      }
      return vehicle;

      // if (vehicleIndex !== vehicles.length - 1) {
      //   vehicleIndex++;
      // } 
    });
  }

  vehicles.forEach((vehicle) => {
    vehicle.amount = Math.floor(isFloorman || vehicle.flag_hybrid_pump_truck === 1 ? remainingAmount : vehicle.amount);
    vehicle.round_details.forEach((round) => {
      round.amount = parseInt(isFloorman  || vehicle.flag_hybrid_pump_truck === 1 ? remainingAmount : round.amount);
    });
    vehicle.round_details = vehicle.round_details.filter((round) => round.amount > 0);
    vehicle.delivery_batch = vehicle.round_details.length;
  });
  return vehicles;
};
