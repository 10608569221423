import React, { useEffect, useContext } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { useDispatch, connect, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { isEqual } from "lodash";

import { getLocalizedStrings } from "../multilingual/GetMessagesMultilingual";
import "react-toastify/dist/ReactToastify.css";
import { toggleLoader, hideLoader } from "../redux/actions/appActions";
import { useQuery } from "../utils/common";
import { routerPositionHelper } from "../utils/router_helper";
import CustomAlert from "../components/alert/CustomAlert";
import { SocketContext } from '../context/sockets';

const Login = React.lazy(() => import("../modules/auth/login/Login"));
const Reset = React.lazy(() => import("../modules/auth/reset/Reset"));
const ErrorPage = React.lazy(() => import("../modules/404/NotFound"));
const Dashboard = React.lazy(() => import("../modules/dashboard/Dashboard"));
const Content = React.lazy(() => import("../components/Wrapper"));

// personnel
// Crew List (CRE)
const CrewList = React.lazy(() =>
  import("../modules/personnel/cre/userManager")
);
const NewCrewList = React.lazy(() =>
  import("../modules/personnel/cre/new/newUser")
);
const EditCrewList = React.lazy(() =>
  import("../modules/personnel/cre/edit/editUser")
);
const ViewCrewList = React.lazy(() =>
  import("../modules/personnel/cre/view/singleUser")
);

// submodule files
const CrewListFileDetailView = React.lazy(() =>
  import("../modules/personnel/cre/files/SingleFileDetail")
);

// stock
// products (PCS)
const Products = React.lazy(() =>
  import("../modules/stocks/products/ProductsManager")
);
const NewProduct = React.lazy(() =>
  import("../modules/stocks/products/new/newProduct")
);

// vehicles (VCL)
const Vehicles = React.lazy(() =>
  import("../modules/stocks/vehicle/ListVehicles")
);
const NewVehicle = React.lazy(() =>
  import("../modules/stocks/vehicle/new/NewVehicle")
);

// suppliers (SUP)
const Suppliers = React.lazy(() =>
  import("../modules/stocks/suppliers/SupplierManager")
);
const NewSupplier = React.lazy(() =>
  import("../modules/stocks/suppliers/new/NewSupplier")
);

// customers (CUS)
const Customers = React.lazy(() =>
  import("../modules/customer/customers/CustomersManager")
);
const NewCustomer = React.lazy(() =>
  import("../modules/customer/customers/new/AddNewCustomer")
);

// offers (OFS)
const Offers = React.lazy(() => import("../modules/offer/offers/ManageOffers"));
const NewOffer = React.lazy(() =>
  import("../modules/offer/offers/new/NewOffer")
);
const OfferEmailContentPdf = React.lazy(() =>
  import("../modules/offer/offers/mailContent/OfferEmailContentPdf")
);

// orders (ORD)
const Orders = React.lazy(() => import("../modules/order/orders/OrderManager"));
const NewOrder = React.lazy(() =>
  import("../modules/order/orders/new/NewOrder")
);

// invoices (INVOICE)
const Invoices = React.lazy(() => import("../modules/invoice/invoices/InvoiceManager"));
const NewInvoice = React.lazy(() =>
  import("../modules/invoice/invoices/new/NewInvoice")
);

// delivery (DLV)
const DayDeliveryView = React.lazy(() =>
  import("../modules/delivery/day-delivery/DayDelivery")
);
const EmployeeDeliveryView = React.lazy(() =>
  import("../modules/delivery/employee-view/EmployeeView")
);

// other modules
const CustomFieldsList = React.lazy(() =>
  import("../modules/settings/customFields/customFieldsList")
);
const CronTab = React.lazy(() =>
  import("../modules/settings/cronjobs/CronTab")
);

let MainRouter = (props) => {
  const auth = useSelector((state) => state.auth, isEqual);

  const query = useQuery();

  const location = useLocation();

  const pathnames = window.location.pathname.split("/");

  const history = createBrowserHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    routerPositionHelper(pathnames, query);
  }, [location, query]);

  const socket = useContext(SocketContext);

  socket.on('connect', () => {
    console.log('Handle Socket connected...', socket.connected)
  })

  useEffect(() => {
    if (auth.isUserLoggedIn === true) {
      socket.emit('ACK', auth.userId)
    }
  }, [socket, auth])


  const AuthRoute = ({ component: Component }) => {
    return !auth.isUserLoggedIn ? (
      <>
        <React.Suspense>
          <Component
            history={history}
            dispatch={dispatch}
            navigate={navigate}
            getLocalizedStrings={getLocalizedStrings}
            toast={toast}
            toggleLoader={props.toggleLoader}
          />
        </React.Suspense>
      </>
    ) : (
      <Navigate
        to={{ pathname: "/dashboard" }}
        replace
        state={{ from: location }}
      />
    );
  };

  const OpenRoute = ({ component: Component }) => {
    return (
      <>
        <React.Suspense>
          <Component
            history={history}
            dispatch={dispatch}
            navigate={navigate}
            getLocalizedStrings={getLocalizedStrings}
            toast={toast}
            toggleLoader={props.toggleLoader}
          />
        </React.Suspense>
      </>
    );
  };

  const DashboardRoute = ({ component: Component }) => {
    return auth.isUserLoggedIn ? (
      <Content
        history={history}
        dispatch={dispatch}
        navigate={navigate}
        getLocalizedStrings={getLocalizedStrings}
        toast={toast}
        toggleLoader={props.toggleLoader}
        hideLoader={props.hideLoader}
      >
        <Component />
      </Content>
    ) : (
      <Navigate replace to={{ pathname: "/" }} />
    );
  };

  const getAllMenus = () => {
    let res = [];
    auth.isUserLoggedIn &&
      Object.keys(JSON.parse(auth.access)).map((parent, idx) => {
        JSON.parse(auth.access)[parent].map((option, childId) => {
          res.push(option);
        });
      });
    return res;
  };

  const hasChildObject = (obj) => {
    let child = new Array();
    Object.keys(obj).map((m) => {
      if (typeof obj[m] === "object" && !obj[m].hasOwnProperty("$$typeof")) {
        Object.keys(obj[m]).map((p) => {
          child.push({ component: obj[m][p], parent: m, name: p });
        });
      }
    });
    return child;
  };



  return (
    <>
      <ToastContainer newestOnTop />
      <CustomAlert />
      <Routes>
        <Route path={"/"} index element={<AuthRoute component={Login} />} />
        <Route path="/reset" element={<AuthRoute component={Reset} />} />
        {/* dashboard */}
        <Route
          path="/dashboard"
          element={<DashboardRoute component={Dashboard} />}
        />

        {auth.isUserLoggedIn &&
          getAllMenus().map((menu, idx) => {
            let childModules = hasChildObject(DashboardModules[menu.shortcode]);
            return (
              <React.Fragment key={`route_${idx}_${menu.parent}`}>
                <Route
                  path={`/dashboard/${menu.shortcode.toLowerCase()}`}
                  element={
                    <DashboardRoute
                      component={DashboardModules[menu.shortcode]["list"]}
                    />
                  }
                />
                <Route
                  path={`/dashboard/${menu.shortcode.toLowerCase()}/view/:_id`}
                  element={
                    <DashboardRoute
                      component={DashboardModules[menu.shortcode]["viewOne"]}
                    />
                  }
                />
                {childModules.map(({ parent, component, name }, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Route
                        path={`/dashboard/${menu.shortcode.toLowerCase()}/${parent}/${name}/:_id`}
                        element={<DashboardRoute component={component} />}
                      />
                    </React.Fragment>
                  );
                })}
                {menu?.operation === "W" && (
                  <>
                    <Route
                      path={`/dashboard/${menu.shortcode.toLowerCase()}/new`}
                      element={
                        <DashboardRoute
                          component={DashboardModules[menu.shortcode]["create"]}
                        />
                      }
                    />
                    <Route
                      path={`/dashboard/${menu.shortcode.toLowerCase()}/edit/:_id`}
                      element={
                        <DashboardRoute
                          component={DashboardModules[menu.shortcode]["edit"]}
                        />
                      }
                    />
                  </>
                )}
              </React.Fragment>
            );
          })}

        {/* other modules */}
        <Route
          path="/custom-fields"
          element={<DashboardRoute component={CustomFieldsList} />}
        />
        <Route
          path="/cronjobs"
          element={<DashboardRoute component={CronTab} />}
        />

        {/* offer details for email */}
        <Route
          path="/offer/:token"
          element={<OpenRoute component={OfferEmailContentPdf} />}
        />

        {/* 404 page */}
        <Route
          path="*"
          element={
            auth.isUserLoggedIn ? (
              <ErrorPage navigate={navigate} />
            ) : (
              <AuthRoute component={Login} />
            )
          }
        />
      </Routes>
    </>
  );
};

const DashboardModules = {
  CRE: {
    list: CrewList,
    create: NewCrewList,
    edit: EditCrewList,
    viewOne: ViewCrewList,
    files: {
      edit: CrewListFileDetailView,
    },
  },
  PRD: {
    list: Products,
    create: NewProduct,
    edit: NewProduct,
  },
  VCL: {
    list: Vehicles,
    create: NewVehicle,
    edit: NewVehicle,
  },
  SUP: {
    list: Suppliers,
    create: NewSupplier,
    edit: NewSupplier,
  },
  CUS: {
    list: Customers,
    create: NewCustomer,
    edit: NewCustomer,
  },
  OFS: {
    list: Offers,
    create: NewOffer,
    edit: NewOffer,
  },
  ORD: {
    list: Orders,
    create: NewOrder,
    edit: NewOrder,
  },
  INV: {
    list: Invoices,
    create: NewInvoice,
    edit: NewInvoice,
  },
  DLV: {
    list: DayDeliveryView,
    view: {
      employee: EmployeeDeliveryView,
    },
  },
};

const MapDispatchToProps = (dispatch) => {
  return {
    toggleLoader: () => dispatch(toggleLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, MapDispatchToProps)(MainRouter);
