export const message = {
    COMMON: {
        SUCCESS: 'Menestys',
        ERROR: 'Virhe',
        RECORD_DELETED: 'Tietueen poistaminen onnistui',
        SOMETHING_WENT_WRONG: 'Opps! Jokin meni vikaan. ole hyvä ja yritä uudelleen.',
        CUSTOM_FIELDS: {
            EMPTY_FIELD_ERROR: 'Anna kentän arvo',
            DUPLICATE_CHOICE: 'Päällekkäisiä valintoja löytyi'
        },
        CONFIRM_DELETE: 'Oletko varma',
        DATA_LOADING_ISSUE: 'Tietoja ei voi ladata.',
        INVALID_INPUT: "Virheellinen syöte",
        INVALID_AMOUNT: "Invalid amount",
        UNABLE_TO_GET_CONTRACT_PRICES: "Unable to get contract prices",
        PLEASE_SELECT_A_PROPERTY: "Please select a property",
        FAILED_TO_GET_GLOBAL_FIELD_FOR_FACTORY: "Failed to get global fields for the factory, please retry!",
        ERROR_WHILE_GETTING_FACTORY_DETAILS: "Error while getting factory details"
    },
    AUTH: {
        PASSWORD_NOT_MATCH: 'Salasana ei täsmää.',
        INVALID_EMAIL: 'Virheellinen sähköpostiosoite.'
    },
    LISTING: {
        SURE_TO_DELETE: 'Haluatko varmasti poistaa tämän tietueen?'
    },
    FORM_ERROR: {
        REQUIRED: 'Tämä kenttä pitää täyttää',
        MAX_LENGTH: 'Maksimipituus ylitetty',
        MIN_LENGTH: 'Syöte on liian lyhyt',
        PHONE_LENGTH: "Please enter a valid numbers",
    },
    ADD_FILE: {
        FILE_TYPE_ERROR: 'Tiedostotyyppiä ei tueta',
        MIN_FILE_ERROR: 'Valitse vähintään yksi ladattava tiedosto'
    },
    CUSTOM_FIELDS: {
        DELETE_CONF: 'Oletko varma?',
        SOMETHING_WRONG: 'Opps! Jotain meni vikaan. Ole hyvä ja yritä myöhemmin uudelleen.',
        ONE_FILE_UPLOAD: '1 tiedosto jonossa',
        SUCCESS: 'Custom field deleted successfully'
    },
    CATEGORY: {
        EMPTY_FIELD: 'Anna luokan nimi'
    },
    EMPTY_FIELD: {
        NAME: "Anna nimi",
        STATUS: "Anna tilan nimi",
        TYPE: "Anna tyypin nimi"
    },
    AGE: {
        EMPTY_FIELD: "Anna iän nimi",
    },
    COMPANYINFO_TABLE:{
        COMPANY_NAME_IS_REQUIRED: "Company name is required",
        COMPANY_TITLE_IS_REQUIRED: "Company title is required",
        COMPANY_ADDRESS_IS_REQUIRED: "Company address is required",
        COMPANY_ZIP_IS_REQUIRED: "Company zip is required",
        COMPANY_POSTAL_CODE_IS_REQUIRED: "Company postal code is required",
        COMPANY_WWW_IS_REQUIRED: "Company WWW is required",
        COMPANY_LOGO_IS_REQUIRED: "Company logo is required",
        COMPANY_EMAIL_IS_REQUIRED: "Company email is required",
        COMPANY_PHONE_NUMBER_IS_REQUIRED: "Company phone number is required",
        COMPANY_ID_IS_REQUIRED: "Company ID is required",
        COMPANY_TAX_ID_IS_REQUIRED: "Company Tax ID is required",
        COMPANY_OVT_ID_IS_REQUIRED: "Company OVT ID is required",
        COMPANY_BANK_IS_REQUIRED: "Company bank is required",
        COMPANY_BANK_ACCOUNT_IS_REQUIRED: "Company bank account is required",
        COMPANY_BIC_IS_REQUIRED: "Company BIC is required",
        COMPANY_THEME_IS_REQUIRED: "Company theme is required",
    },
    USER_TABLE: {
        INVALID_EMAIL: 'Virheellinen sähköpostiosoite',
        VALIDATE_PASSWORD: 'Salasanan tulee olla yli 6 merkkiä',
        PHONE_NUM_VALIDATE: 'Puhelinnumeron tulee olla yli 10 merkkiä pitkä',
        PHONE_NUM_PATTERN: 'Ole hyvä ja näppäile voimassaoleva puhelinnumero',
        PASSWORD_VALIDATE_ERROR: 'Salasanan tulee olla yli 6 merkkiä',
        USER_NOTFOUND: 'Käyttäjää ei löydy',
        JOB_DESCRIPTION_REQUIRED: "Valitse työnkuvaus",
        FIRSTNAME_IS_REQUIRED: "Firstname is required",
        LASTNAME_IS_REQUIRED: "Lastname is required",
        PERSONAL_ID_IS_REQUIRED: "Personal ID is required",
        COMPANY_NAME_IS_REQUIRED: "Company Name is required",
        COMPANY_ID_IS_REQUIRED: "Company ID is required",
        PHONE_NUMBER_IS_REQUIRED: "Phone number is required",
        EMAIL_IS_REQUIRED: "Email is required",
        REGISTRATION_NUMBER_IS_REQUIRED :"D&B Registration Number is required",
        SEGMENTS_ARE_REQUIRED: "Segments are required",
        WORK_PHONE_IS_REQUIRED: "Work phone is required",
        ROLE_IS_REQUIRED: "Role is required",
        STATUS_IS_REQUIRED: "Status is required",    
    },
    SUPPLIER_INFO: {
        PHONE_NUM_PATTERN_ERROR: 'Ole hyvä ja näppäile voimassaoleva puhelinnumero',
        EMAIL_PATTERN_ERROR: 'Virheellinen sähköpostiosoite',
        ZIP_CODE_VALIDATE: 'Syötä postinumerossa vain numeroita',
        ENTER_STATUS: 'Anna tila',
        SUPPLIER_REQUIRED: "Supplier is required",
        STATUS_REQUIRED: "Status is required",
    },
    CHARACTERSTIC_INFO:{
        ENTER_CHARACTERSTIC : "Anna tila",
    },
    FACTORY: {
        NO_FACTORY_MSG: 'Tehtaita ei lisätty',
        PHONE_PATTERN_ERROR: 'Ole hyvä ja näppäile voimassaoleva puhelinnumero',
        INVALID_EMAIL_ERROR: 'Virheellinen sähköpostiosoite',
        DUPLICATE_FACTORY_NAME: 'Kopioi tehtaan nimi',
        noFactorShow: 'No factories to show',
        NOFACTORIESAVAILABLE: "No factories available",
        NAME_REQUIRED: "Name is required",
        EMAIL_REQUIRED:"Email is required",
        ADDRESS_REQUIRED: "Address is required",
        ZIPCODE_REQUIRED: "Zipcode is required",
        POSTAL_DISTRICT_REQUIRED: "Postal district is required",
    },
    CONTACT_PERSON: {
        PHONE_PATTERN_ERROR: 'Ole hyvä ja näppäile voimassaoleva puhelinnumero',
        INVALID_EMAIL_ERROR: 'Virheellinen sähköpostiosoite',
        DUPLICATE_PERSON: 'Päällekkäinen puhelinnumero',
        EMAIL_REQUIRED:"Email is required",
        FIRST_NAME_REQUIRED:"First name is required",
        LAST_NAME_REQUIRED:"Last name is required",    
    },
    DELIVERY: {
        INVALID_BATCHES_PROVIDED: "Invalid batches provided!",
        INVALID_AMOUNT_PROVIDED: "Invalid amount provided!",
        CHOOSE_VEHICLE: "Please choose vehicle",
        CHOOSE_DRIVER: "Please choose driver",
        SELECT_SCHEDULE_DATE_AND_TIME: "Please select schedule date and time",
        INVALID_SCHEDULE_DATE_AND_TIME: "Invalid schedule date and time",
        PRODUCT_TYPE_IS_REQUIRED: "Product type is required",
        PRODUCT_IS_REQUIRED: "Product is required",
        SELECT_LEAST_CREW_FOR_FLOORING: "Please select at least 1 crew for flooring",
        CUSTOMER_NAME_IS_REQUIRED: "Customer name is required",
        ORDER_STATUS_IS_REQUIRED: "Order status is required",
        SELLER_IS_REQUIRED: "Seller is required",
        CONTACT_PERSON_IS_REQUIRED: "Contact person is required",
        PROPERTY_IS_REQUIRED: "Property is required",
        TYPE_IS_REQUIRED: "Type is required",
        VEHICLE_IS_REQUIRED: "Vehicle is required",
        DRIVER_IS_REQUIRED: "Driver is required",
        START_DATE_IS_REQUIRED: "Start Date is required",
        END_DATE_IS_REQUIRED: "End Date is required",
        START_TIME_IS_REQUIRED: "Start Time is required",
        END_TIME_IS_REQUIRED: "End Time is required",
        REACH_FACTORY_DATE_IS_REQUIRED: "Reach Factory Date is required",
        REACH_FACTORY_TIME_IS_REQUIRED: "Reach Factory Time is required",
        REACH_FACTORY_DISTANCE_IS_REQUIRED: "Reach Factory Distance is required",
        START_LOADING_VEHICLE_DATE_IS_REQUIRED: "Start Loading Vehicle Date is required",
        START_LOADING_VEHICLE_TIME_IS_REQUIRED: "Start Loading Vehicle Time is required",
        STOP_LOADING_VEHICLE_DATE_IS_REQUIRED: "Stop Loading Vehicle Date is required",
        STOP_LOADING_VEHICLE_TIME_IS_REQUIRED: "Stop Loading Vehicle Time is required",
        DRIVE_DESTINATION_DATE_IS_REQUIRED: "Drive Destination Date is required",
        DRIVE_DESTINATION_TIME_IS_REQUIRED: "Drive Destination Time is required",
        REACH_DESTINATION_DATE_IS_REQUIRED: "Reach Destination Date is required",
        REACH_DESTINATION_TIME_IS_REQUIRED: "Reach Destination Time is required",
        REACH_DESTINATION_DISTANCE_IS_REQUIRED: "Reach Destination Distance is required",
        START_UNLOADING_VEHICLE_DATE_IS_REQUIRED: "Start Unloading Vehicle Date is required",
        START_UNLOADING_VEHICLE_TIME_IS_REQUIRED: "Start Unloading Vehicle Time is required",
        STOP_UNLOADING_VEHICLE_DATE_IS_REQUIRED: "Stop Unloading Vehicle Date is required",
        STOP_UNLOADING_VEHICLE_TIME_IS_REQUIRED: "Stop Unloading Vehicle Time is required",
        STOP_UNLOADING_VEHICLE_JACKSHOTS_IS_REQUIRED: "Stop Unloading Vehicle Jackshots is required",
        STOP_UNLOADING_VEHICLE_DISTANCE_IS_REQUIRED: "Stop Unloading Vehicle Distance is required",
        FINISH_DELIVERY_DATE_IS_REQUIRED: "Finish Delivery Date is required",
        FINISH_DELIVERY_TIME_IS_REQUIRED: "Finish Delivery Time is required",
        STOP_DELIVERY_DISTANCE_IS_REQUIRED: "Stop Delivery Distance is required",
        CONCRETE_PUMPTRUCK_START_PUMPING_DATE_IS_REQUIRED: "Start Pumpping Date is required",
        CONCRETE_PUMPTRUCK_START_PUMPING_TIME_IS_REQUIRED: "Start Pumpping Time is required",
        CONCRETE_PUMPTRUCK_STOP_PUMPING_DATE_IS_REQUIRED: "Stop Pumpping Date is required",
        CONCRETE_PUMPTRUCK_STOP_PUMPING_TIME_IS_REQUIRED: "Stop Pumpping Time is required",
        CONCRETE_PUMPTRUCK_PHOTO_ADDED_DATE_IS_REQUIRED: "Pumptruck Photo Added Date is required",
        CONCRETE_PUMPTRUCK_PHOTO_ADDED_TIME_IS_REQUIRED: "Pumptruck Photo Added Time is required",
        FLOORMAN_START_WORKING_DATE_IS_REQUIRED: "Start Working Date is required",
        FLOORMAN_START_WORKING_TIME_IS_REQUIRED: "Start Working Time is required",
        FLOORMAN_STOP_WORKING_DATE_IS_REQUIRED: "Stop Working Date is required",
        FLOORMAN_STOP_WORKING_TIME_IS_REQUIRED: "Stop Working Time is required",
        FLOORMAN_START_SEPERATE_HOURLY_WORK_DATE_IS_REQUIRED: "Start Seperate Hourly Work Date is required",
        FLOORMAN_START_SEPERATE_HOURLY_WORK_TIME_IS_REQUIRED: "Start Seperate Hourly Work Time is required",
        FLOORMAN_STOP_SEPERATE_HOURLY_WORK_DATE_IS_REQUIRED: "Stop Seperate Hourly Work Date is required",
        FLOORMAN_STOP_SEPERATE_HOURLY_WORK_TIME_IS_REQUIRED: "Start Seperate Hourly Work Time is required",
        FLOORMAN_START_WAITING_TIME_DATE_IS_REQUIRED: "Start Waiting Date is required",
        FLOORMAN_START_WAITING_TIME_TIME_IS_REQUIRED: "Start Waiting Time is required",
        FLOORMAN_STOP_WAITING_TIME_DATE_IS_REQUIRED: "Stop Waiting Date is required",
        FLOORMAN_STOP_WAITING_TIME_TIME_IS_REQUIRED: "Stop Waiting Time is required",    
    },
    VEHICLES: {
        BASIC: {
            UPDATING_MSG: 'Ladataan...',
            INVALID_INPUT: 'Virheellinen syöte',
            YEAR_INVALID: "Syötä vuosi vuosimalliin",
            REGISTER_NUMBER_REQUIRED: "Register number is required",
            MODEL_REQUIRED: "Model is required",
            TYPE_REQUIRED: "Type is required",
            VEHICLE_OR_DRIVER_ALREADY_ASSIGNED: "Vehicle or Driver already assigned",
            FLOORMEN_ALREADY_ASSIGNED: "Floormen already assigned"
        },
        DRIVER: {
            NO_DRIVER_MSG: 'Ei lisätty ohjaimia',
            INVALID_EMAIL_ERROR: 'Virheellinen sähköpostiosoite',
            PASSWORD_VALIDATE_ERROR: 'Salasanan tulee olla yli 6 merkkiä',
            PHONE_NUM_PATTERN_ERROR: 'Ole hyvä ja näppäile voimassaoleva puhelinnumero',
            PHONE_VALIDATE_ERROR: 'Puhelinnumeron tulee olla yli 10 merkkiä pitkä'
        },
        CAPACITY: {
            INVALID_INPUT: 'Virheellinen syöte'
        },
        INSTRUCTIONS: {
            UPDATING_MSG: 'Ladataan...',
            NO_FILES_MSG: 'Ei lisätty tiedostoja'
        },
        MANAGE_CATEGORY: {
            ENTER_CATEGORY: 'Anna luokka',
            CATEGORY_REQUIRED: "Category name is required",
        },
        MANAGE_TYPE: {
            ENTER_TYPE: 'Anna tyyppi'
        }
    },
    CRON_TAB: {
        ADD_CRON: {
            MINUTE_VALIDATE_ERROR: 'Anna kelvollinen minuutti',
            HOUR_VALIDATE_ERROR: 'Anna kelvollinen tunti',
            DAY_VALIDATE_ERROR: 'Anna kelvollinen päivä',
            MONTH_VALIDATE_ERROR: 'Anna kelvollinen kuukausi',
            WEEK_VALIDATE_ERROR: 'Anna kelvollinen viikko',
            CRON_JOB: 'Cronjob',
            UPDATED: 'Päivitetty',
            ADDED: 'Lisätty',
            SUCCESS: 'Onnistuneesti',
            EDIT: 'Muokata',
            ADD: 'Lisätä',
            NEW_CRON_JOB: 'Uusi Cron-työ'
        },
        CONFIRM_MSG: 'Oletko varma?'
    },
    FILE: {
        DELETE_CONF: 'Haluatko varmasti poistaa?',
        UNDONE_MSG: 'Tätä toimintoa ei voi kumota.',
        EMPTY_FILE_CATEGORY: 'Anna tiedostoluokka',
        FILE_PREVIEW_ERROR: 'Jotain meni pieleen tämän tiedoston esikatselussa. Yritä uudelleen.',
        FILE_UNABLE_PREVIEW: 'Tämän tiedoston esikatselua ei voi ladata.',
        REMOVE_FILE_CONF: 'Poistetaanko tiedosto?',
        ONE_FILE_UPLOAD: '1 tiedosto ladattu',
        NAME_CHANGE_SUCCESS: 'Tiedoston nimen muuttaminen onnistui',
        NAME_REQUIRED: "Tiedoston nimen muuttaminen onnistui",
        NO_TITLE: 'Otsikkoa ei ole määritetty',
        DELETE_SUPPLIER: "Haluatko varmasti poistaa tämän toimittajan luettelosta? tälle toimittajalle lisätyt hinnat poistetaan."
    },
    NOTES: {
        SCHEDULE_DATE_TIME_ERROR: 'Valitse ajoitettu päivämäärä ja aika',
        ENTER_DESCRYPTION_ERROR: 'Anna kuvaus',
        SCHEDULE_TIME_PAST_ERROR: 'Suunniteltu aika ei voi olla menneisyydessä',
        REPLY_MESSAGE_ERROR: 'Kirjoita viesti vastataksesi',
        SOMEONE_DELETED_NOTE: 'Joku poisti vastauksensa huomautuksessa:',
        NO_NOTES: 'Ei muistiinpanoja näytettäväksi',
        ENTER_CATEGORY: 'Anna muistiinpanoluokka',
        NO_FILE_CHOOSEN: 'Ei tiedostoa valittuna',
        NOTE: 'Huomautus',
        HAS_DELETED: 'on poistettu',
        NO_REPLY_RECEIVED: 'Uusi vastaus vastaanotettu muistiinpanossa - ',
        CLOSE_ADD_NOTE_DIALOG: 'Sulje muistiinpanon lisääminen -valintaikkuna ensin.',
        SOMETHING_WRONG: 'Opps! Jokin meni vikaan. ole hyvä ja yritä uudelleen.',
        OPENED_NOTE: 'Avasi muistiinpanosi',
        VIEW_ALL_MESSAGES: 'Katso kaikki viestit',
        ADDED_SUCCESS: "Note added successfully",
        error: {
            scheduledTimeRequired: 'Valitse ajoitettu aika.'
        }
    },
    ASIDE: {
        LANGUAGE_UPDATE_SUCCESS: 'Kieli päivitetty',
        LANGUAGE_UPDATE_FAILED: 'Opps! Jokin meni vikaan. ole hyvä ja yritä uudelleen.'
    },
    DATA_TABLE: {
        RETRY_MSG: 'Jotain meni pieleen, yritä uudelleen.',
        INVALID_FILE: 'Virheellinen tiedosto määritetty',
        SELECT_FILE: 'Valitse tuotava tiedosto'
    },
    PRODUCT: {
        manageStocks: {
            stockLessThanAlarmLimit: 'Varasto on päivitettävä.',
            wareHouseNameRequired: 'Varaston nimi vaaditaan.',
            invalidStockQuantity: 'Sen tulee olla enemmän kuin 0 ja numero.',
            invalidAlarmLimitQuantity: 'Sen tulee olla enemmän kuin 0 ja numero.',
            duplicateWearHouseName: 'Varaston nimi on jo olemassa.'
        },
        createNew: {
            categoryRequired: "Valitse luokka.",
            unitRequired: "Valitse vähintään 1 yksikkö..",
            supplierRequired: "Valitse vähintään yksi toimittaja.",
            characteristicsRequired: (name) =>
                `Ole hyvä ja valitse ${name} ominaisuudet Tuoteominaisuudet-kohdasta.`,
        },
        pricesCharacteristicsCleared: 'Kaikki tälle ominaisuudelle lisätyt hinnat tyhjennetään.',
        warning: {
            selectMinOneDay: 'Valitse vähintään 1 päivä arkipäivästä.',
            purchasePriceCompareSellPrice: 'Ostohinta ei voi olla suurempi kuin myyntihinta.'
        },
        error: {
            propertyRequired: 'Anna omaisuuden tyyppi.',
            unitRequired: 'Anna yksikkö.',
            taxRequired: 'Anna vero.',
            taxValidation: 'Anna kelvollinen arvo.',
            characteristicRequired: 'Anna tyypillinen nimi.'
        },
        productAvailableSupplierFactory: 'Product Available at suppliers factories',
        noPricesAdded: "Ei lisättyjä hintoja",
        noProductsAdded: "Ei lisättyjä tuotteita",
        PLEASE_SELECT_TAX_PERCENTAGE: "Please select tax percentage",
        FAILED_TO_REFRESH_FACTORIES_LIST: "Failed to refresh factories list"    
    },
    OFFER: {
        warning: {
            groupNameExist: 'Samanniminen ryhmä on jo lisätty.',
            addPreviousProduct: 'Lisää aiemmat tuotetiedot lisätäksesi uuden tietueen.'
        },
        error: {
            numberNotFound: 'Tarjouksen numeroa ei löydy',
            groupNameRequired: 'Anna ryhmän nimi.',
            notFound: 'Annetuilla tiedoilla ei löytynyt tarjousta.',
            fillInformation: 'Täytä tarjoustiedot',
            fillPropertyInformation: 'Please fill property information',
            customerPropertyRequired: 'Valitse asiakkaan omaisuus.',
            PROPERTY_FULL_ADDRESS_REQUIRED: 'Please enter full address',
            PROPERTY_ZIPCODE_REQUIRED: 'Please enter zipcode',
            PROPERTY_POSTAL_ADDRESS_REQUIRED: 'Please enter postal address',      
        },
        info: {
            copyProcess: 'Kopioidaan tarjousta, odota...',
            acceptSuccess: "Tarjous hyväksytty",
            rejectSuccess: "Tarjous hylätty onnistuneesti",
            updatedSuccess: "Offer updated successfully",
            createdSuccess: "Offer created successfully",
        },
        SELECT_OPTION_ROW: "Please select option from list",
        ADD_ONE_GROUP_IN_OFFER_CONTENT: "Please add at least 1 group in offer content",
        NO_PRODUCT_LIST_OR_EDITOR_FOUND: "No product list or editor found inside",
        EMPTY_PRODUCT_LIST_FOUND_IN_GROUP: "Empty product list found in group",
        EMPTY_PRODUCT_RECORD_FOUND_IN_GROUP: "Empty product record found in group",
        OFFER_STATUS_IS_REQUIRED: "Offer status is required",
        TAX_STATUS_IS_REQUIRED: "Tax status is required"
    },
    BRAND: {
        error: {
            brandRequired: 'Anna tuotemerkki.'
        }
    },
    EMAIL: {
        success: {
            sent: 'Sähköposti(t) lähetetty onnistuneesti.'
        },
        error: {
            offerSendingIssue: 'Virhe lähetettäessä tarjousta asiakkaalle, yritä uudelleen.'
        }
    },
    CUSTOMER: {
        error: {
            basicInformationRequired: 'Lisää asiakkaan perustiedot.',
            billingInformationRequired: 'Lisää asiakkaan laskutustiedot.'
        },
        warning: {
            previousDataBasicInformationCleared: 'Oletko varma? Aiemmat tietosi perustiedot tyhjennetään.'
        },
        success: {
            creditInformation: 'Luottotiedot haettu.'
        }
    },
    SUPPLIER: {
        noSuppliersAdded: 'Toimittajia ei lisätty'
    },
    ORDER: {
        ORDER_UPDATED_SUCCESSFULLY: "Order updated successfully",
        ORDER_CREATED_SUCCESSFULLY: "Order created successfully",
        ORDER_DELETED_SUCCESS: "Order deleted successfully",
        PLEASE_FILL_ORDER_INFORMATION: "Please fill Order information",
        PLEASE_SELECT_CUSTOMER_PROPERTY: "Please select customer property",
        PLEASE_SELECT_DELIVERY_AREA_FOR_PRODUCT: "Please select delivery area for product",
        AMOUNT_OF_PRODUCT_SHOULD_BE_GREATER_THAN_0: "Amount of product should be greater than 0",
        PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_IN_YOUR_DELIVERY: "Valitse toimituksestasi vähintään yksi tuote",
        PLEASE_SELECT_DRIVER_FOR_VEHICLE: "Valitse ajoneuvon kuljettaja",
        NO_ORDER_FOUND_WITH_THE_DETAILS_PROVIDED: "Annetuilla tiedoilla tilausta ei löytynyt",
        CONSIGNMENT_DETAILS_NOT_FOUND: "Lähetystietoja ei löytynyt",
        ONE_VEHICLE_IS_REQUIRED: "Tarvitaan yksi ajoneuvo",
        ONE_CREW_IS_REQUIRED: "Tarvitaan yksi miehistö",
        PLEASE_SELECT_CREW: "Ole hyvä ja valitse miehistö",
        PLEASE_CHOOSE_FACTORY: "Ole hyvä ja valitse tehdas",
        DELIVERY_CREATED: "Delivery created",
        INVALID_SCHEDULED_DATE_AND_TIME: "Virheellinen ajoitettu päivämäärä ja aika",
        PLEASE_SELECT_HOW_TO_PAY: "Valitse maksutapa",
        PLEASE_SELECT_REPORTAGE: "Please select at least one reportage member",
        ORDER_COPY_SUCCESSFULLY: "Order copied successfully",
    },
    INVOICE: {
        NO_INVOICE_FOUND: "No invoice found with the details provided.",
        FILL_FIRST_PAYMENT_INFO: "Please fill first payment information.",
        FILL_FIRST_PRODUCT_INFO: "Please fill first product information.",
        INVOICE_UPDATED_SUCCESS: "Invoice updated successfully",
        INVOICE_CREATED_SUCCESS: "Invoice created successfully",
        INVOICE_DELETED_SUCCESS: "Invoice deleted successfully",
        UNDONE_MSG: "This action cannot be undone.",
        FETCHING_DETAILS : "Fetching details...",
        LOADING_YOUR_ORDER: "Loading your order",
        INVOICE_STATUS_REQUIRED: "Invoice status is required",
        ORDER_NUMBER_REQUIRED: "Order number is required",
        CUSTOMER_PROPERTY_REQUIRED: "Customer property is required",
        SELLER_REQUIRED: "Seller is required",
        PLEASE_ENTER_VALID_NUMBER: "Please enter valid number.",
        VALUE_GREATER_THAN_ZERO: "Value should be greater than 0.",
        VALUE_LESS_THAN_HUNDRED: "Discount value should less than 100.",
        NETVISOR_CONF: "Lähetetäänkö tiedot Netvisoriin?",
    }
}