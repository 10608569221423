import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import appReducer from './reducers/appReducer'
import authReducer from './reducers/authReducer'
import productReducer from "./reducers/productReducer"
import productDialogReducer from "./reducers/productDialogReducer";
import customerReducer from "./reducers/customerReducer";
import offerReducer from "./reducers/offerReducer";
import orderReducer from './reducers/orderReducer';
import invoiceReducer from "./reducers/invoiceReducer"

export const history = createBrowserHistory();
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = (history) => ({
  app: appReducer,
  auth: authReducer,
  product: productReducer,
  productDialog: productDialogReducer,
  customer: customerReducer,
  offer: offerReducer,
  order: orderReducer,
  invoice: invoiceReducer,
  router: connectRouter(history)
});

const preloadedState = {};
const store = configureStore({
  middleware: [thunk, routerMiddleware(history), logger],
  reducer: rootReducer(history),
  preloadedState,
});

export default store