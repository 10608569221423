export const constants = {
  CHANGE_LANUGAGE: "CHANGE_LANUGAGE",
  TOGGLE_LOADER: "TOGGLE_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  USER_CHECK_LOGIN: "USER_CHECK_LOGIN",
  USER_LOGOUT: "USER_LOGOUT",
  SAVE_FILES_TO_CREW_LIST: "SAVE_FILES_TO_CREW_LIST",
  APP: {
    UPDATE_ROUTES: "UPDATE_ROUTES",
    FIRST_RENDER_UPDATE: "FIRST_RENDER_UPDATE",
    STORE_NOTIFICATIONS: "STORE_NOTIFICATIONS",
    STORE_COMPANYINFO: "STORE_COMPANYINFO",
    CHANGE_ACTIVE_TAB: "CHANGE_ACTIVE_TAB",
    SIDEBAR_ACTIVE: "SIDEBAR_ACTIVE",
    OPEN_ALERT: "OPEN_ALERT",
    CLOSE_ALERT: "CLOSE_ALERT",
    SHOW_EDIT_DATA: "SHOW_EDIT_DATA",
  },
  PRODUCT: {
    PRODUCT_STACK_DATA: "PRODUCT_STACK_DATA",
    SET_SELECTED_SUPPLIERS: "SET_SELECTED_SUPPLIERS",
    RESET_PRODUCTS_STATE: "RESET_PRODUCTS_STATE",
    REMOVE_SUPPLIER_FROM_LIST: "REMOVE_SUPPLIER_FROM_LIST",
    REPLACE_SUPPLIER: "REPLACE_SUPPLIER",
    PRODUCT_CUSTOM_FIELDS_BASIC: "PRODUCT_CUSTOM_FIELDS_BASIC",
    MANAGE_WAREHOUSES: "MANAGE_WAREHOUSES",
    MANAGE_LINKED_PRODUCTS: "MANAGE_LINKED_PRODUCTS",
    SUPPLIERS_WITH_FACTORIES_FILTERED: "SUPPLIERS_WITH_FACTORIES_FILTERED",
    ADD_BASIC_PRICES: "ADD_BASIC_PRICES",
    REMOVE_BASIC_PRICE: "REMOVE_BASIC_PRICE",
    UPDATE_BASIC_PRICE: "UPDATE_BASIC_PRICE",
    ADD_DAILY_PRICE: "ADD_DAILY_PRICE",
    ADD_NEW_DAILY_PRICE: "ADD_NEW_DAILY_PRICE",
    DELETE_DAILY_PRICE: "DELETE_DAILY_PRICE",
    SELECTED_UNITS: "SELECTED_UNITS",
    ENABLE_EDIT_MODE: "ENABLE_EDIT_MODE",
    STORE_EDITOR_OBJECTS: "STORE_EDITOR_OBJECTS",
    ADD_ALL_BASIC_PRICES: "ADD_ALL_BASIC_PRICES",
    UPDATE_VARIATION_PRICES: "UPDATE_VARIATION_PRICES",
    MANAGE_EXISTING_CHARACTERISTICS: "MANAGE_EXISTING_CHARACTERISTICS",
    ADD_CHARACTISTICS_VALUES_WITH_TYPE: "ADD_CHARACTISTICS_VALUES_WITH_TYPE",
    REMOVE_VARIATION_PRICE: "REMOVE_VARIATION_PRICE",
    UPDATE_VARIATION_PRICE: "UPDATE_VARIATION_PRICE",
    ADD_ALL_VARIANT_PRICES: "ADD_ALL_VARIANT_PRICES",
    ADD_ALL_CHARACTERSTICS: "ADD_ALL_CHARACTERSTICS",
    REFRESH_CHARACTERSTICS: "REFRESH_CHARACTERSTICS",
    REFRESH_BASIC_PRICES: "REFRESH_BASIC_PRICES",
    REFRESH_DAILY_PRICES: "REFRESH_DAILY_PRICES",
  },
  PRODUCT_DIALOG: {
    PRODUCT_STACK_DATA: "PRODUCT_STACK_DATA_DIALOG",
    SET_SELECTED_SUPPLIERS: "SET_SELECTED_SUPPLIERS_DIALOG",
    RESET_PRODUCTS_STATE: "RESET_PRODUCTS_STATE_DIALOG",
    REMOVE_SUPPLIER_FROM_LIST: "REMOVE_SUPPLIER_FROM_LIST_DIALOG",
    REPLACE_SUPPLIER: "REPLACE_SUPPLIER_DIALOG",
    PRODUCT_CUSTOM_FIELDS_BASIC: "PRODUCT_CUSTOM_FIELDS_BASIC_DIALOG",
    MANAGE_WAREHOUSES: "MANAGE_WAREHOUSES_DIALOG",
    MANAGE_LINKED_PRODUCTS: "MANAGE_LINKED_PRODUCTS_DIALOG",
    SUPPLIERS_WITH_FACTORIES_FILTERED:
      "SUPPLIERS_WITH_FACTORIES_FILTERED_DIALOG",
    ADD_BASIC_PRICES: "ADD_BASIC_PRICES_DIALOG",
    REMOVE_BASIC_PRICE: "REMOVE_BASIC_PRICE_DIALOG",
    UPDATE_BASIC_PRICE: "UPDATE_BASIC_PRICE_DIALOG",
    ADD_DAILY_PRICE: "ADD_DAILY_PRICE_DIALOG",
    ADD_NEW_DAILY_PRICE: "ADD_NEW_DAILY_PRICE_DIALOG",
    DELETE_DAILY_PRICE: "DELETE_DAILY_PRICE_DIALOG",
    SELECTED_UNITS: "SELECTED_UNITS_DIALOG",
    ENABLE_EDIT_MODE: "ENABLE_EDIT_MODE_DIALOG",
    STORE_EDITOR_OBJECTS: "STORE_EDITOR_OBJECTS_DIALOG",
    ADD_ALL_BASIC_PRICES: "ADD_ALL_BASIC_PRICES_DIALOG",
    UPDATE_VARIATION_PRICES: "UPDATE_VARIATION_PRICES_DIALOG",
    MANAGE_EXISTING_CHARACTERISTICS: "MANAGE_EXISTING_CHARACTERISTICS_DIALOG",
    ADD_CHARACTISTICS_VALUES_WITH_TYPE:
      "ADD_CHARACTISTICS_VALUES_WITH_TYPE_DIALOG",
    REMOVE_VARIATION_PRICE: "REMOVE_VARIATION_PRICE_DIALOG",
    UPDATE_VARIATION_PRICE: "UPDATE_VARIATION_PRICE_DIALOG",
    ADD_ALL_VARIANT_PRICES: "ADD_ALL_VARIANT_PRICES_DIALOG",
    ADD_ALL_CHARACTERSTICS: "ADD_ALL_CHARACTERSTICS_DIALOG",
    REFRESH_CHARACTERSTICS: "REFRESH_CHARACTERSTICS_DIALOG",
    REFRESH_BASIC_PRICES: "REFRESH_BASIC_PRICES_DIALOG",
    REFRESH_DAILY_PRICES: "REFRESH_DAILY_PRICES_DIALOG",
  },
  CUSTOMER: {
    CUSTOMER_STACK_DATA: "CUSTOMER_STACK_DATA",
    UPDATE_CONTACT_PERSONS: "UPDATE_CONTACT_PERSONS",
    UPDATE_CONTRACT_PRICES: "UPDATE_CONTRACT_PRICES",
    CUSTOMER_BASIC_DATA: "CUSTOMER_BASIC_DATA",
    CUSTOMER_BILLING_DATA: "CUSTOMER_BILLING_DATA",
    CUSTOMER_COMPANY: "CUSTOMER_COMPANY",
    RESET_CUSTOMER_STATE: "RESET_CUSTOMER_STATE",
    ENABLE_EDIT_MODE: "ENABLE_EDIT_MODE_CUSTOMER",
    UPDATE_CREDIT_INFORMATION: "UPDATE_CREDIT_INFORMATION",
    CUSTOMER_PROPERTIES: "CUSTOMER_PROPERTIES",
    SELECTED_CUSTOMER_PROPERTY_CUSTOMER: "SELECTED_CUSTOMER_PROPERTY_CUSTOMER",
    UPDATE_EDITOR_OBJECTS_CUSTOMER: "UPDATE_EDITOR_OBJECTS_CUSTOMER",
  },
  OFFERS: {
    GET_OFFERS_STACK: "GET_OFFERS_STACK",
    SET_OFFER_FOR_CUSTOMER: "SET_OFFER_FOR_CUSTOMER",
    RESET_OFFERS_STATE: "RESET_OFFERS_STATE",
    SAVE_OFFER_INFORMATION: "SAVE_OFFER_INFORMATION",
    SAVE_ATTACHMENTS: "SAVE_ATTACHMENTS",
    CUSTOMER_PROPERTIES: "CUSTOMER_PROPERTIES",
    CUSTOMER_CONTACT_DATA: "CUSTOMER_CONTACT_DATA",
    STORE_ALL_CUSTOMER_PROPERTIES: "STORE_ALL_CUSTOMER_PROPERTIES",
    UPDATE_EDITOR_OBJECTS_OFFERS: "UPDATE_EDITOR_OBJECTS_OFFERS",
    NEW_OFFER_GROUP: "NEW_OFFER_GROUP",
    DELETE_OFFER_GROUP: "DELETE_OFFER_GROUP",
    EDIT_MODE_OF_OFFER_GROUP: "EDIT_MODE_OF_OFFER_GROUP",
    EDIT_OFFER_GROUP_NAME: "EDIT_OFFER_GROUP_NAME",
    SELECTED_CUSTOMER_PROPERTY_OFFER: "SELECTED_CUSTOMER_PROPERTY_OFFER",
    UPDATE_OFFER_CONTENT_REARRANGEMENT: "UPDATE_OFFER_CONTENT_REARRANGEMENT",
    ADD_NEW_ROW_OFFER_CONTENT: "ADD_NEW_ROW_OFFER_CONTENT",
    ADD_NEW_PRODUCT_OFFER_CONTENT_CRUD: "ADD_NEW_PRODUCT_OFFER_CONTENT_CRUD",
    UPDATE_OFFER_CONTENT_PRODUCT_REARRANGEMENT:
      "UPDATE_OFFER_CONTENT_PRODUCT_REARRANGEMENT",
    SELECT_PRODUCT_DATA: "SELECT_PRODUCT_DATA",
    SET_OFFER_DATA_FOR_EDIT_MODE: "SET_OFFER_DATA_FOR_EDIT_MODE",
    UPDATE_EDIT_DATA_OFFERS: "UPDATE_EDIT_DATA_OFFERS",
    CLEAR_OFFERS_EDIT_DATA: "CLEAR_OFFERS_EDIT_DATA",
    UPDATE_OFFER_SENT_DATE: "UPDATE_OFFER_SENT_DATE",
    CUSTOMER_BASIC_DATA_OFFER: "CUSTOMER_BASIC_DATA_OFFER",
    CUSTOMER_PROPERTIES_TYPE_IN_OFFER: "CUSTOMER_PROPERTIES_TYPE_IN_OFFER",
    DELETE_OFFER_CONTENT: "DELETE_OFFER_CONTENT",
  },
  ORDERS: {
    GET_ORDERS_STACK: "GET_ORDERS_STACK",
    SET_ORDER_FOR_CUSTOMER: "SET_ORDER_FOR_CUSTOMER",
    SET_ORDER_DELIVERY_DATE_RANGE: "SET_ORDER_DELIVERY_DATE_RANGE",
    RESET_ORDERS_STATE: "RESET_ORDERS_STATE",
    SAVE_ORDER_INFORMATION: "SAVE_ORDER_INFORMATION",
    SAVE_ORDER_ATTACHMENTS: "SAVE_ORDER_ATTACHMENTS",
    CUSTOMER_PROPERTIES_IN_ORDER: "CUSTOMER_PROPERTIES_IN_ORDER",
    STORE_ALL_CUSTOMER_PROPERTIES_IN_ORDERS:
      "STORE_ALL_CUSTOMER_PROPERTIES_IN_ORDERS",
    UPDATE_EDITOR_OBJECTS_ORDERS: "UPDATE_EDITOR_OBJECTS_ORDERS",
    SELECTED_CUSTOMER_PROPERTY_ORDERS: "SELECTED_CUSTOMER_PROPERTY_ORDERS",
    SAVE_ATTACHMENTS_ORDER: "SAVE_ATTACHMENTS_ORDER",
    ADD_NEW_SHIPPING: "ADD_NEW_SHIPPING",
    SET_DATA_UPDATED: "SET_DATA_UPDATED",
    SET_ORDER_DATA_STATUS: "SET_ORDER_DATA_STATUS",
    SELECTED_DRIVER_LIST: "SELECTED_DRIVER_LIST",
    DELETE_SHIPPING: "DELETE_SHIPPING",
    UPDATE_DELIVERY_BATCH: "UPDATE_DELIVERY_BATCH",
    ADD_NEW_PRODUCT_ORDER_DELIVERY: "ADD_NEW_PRODUCT_ORDER_DELIVERY",
    SELECT_PRODUCT_DATA_IN_ORDERS: "SELECT_PRODUCT_DATA_IN_ORDERS",
    ADD_NEW_VEHICLE: "ADD_NEW_VEHICLE",
    REMOVE_DRIVER_LIST: "REMOVE_DRIVER_LIST",
    SAVE_OFFER_INFORMATION_IN_ORDERS: "SAVE_OFFER_INFORMATION_IN_ORDERS",
    CREATE_NEW_ORDER_DELIVERY: "CREATE_NEW_ORDER_DELIVERY",
    ADD_NEW_PRODUCT_ORDER_CONTENT_CRUD: "ADD_NEW_PRODUCT_ORDER_CONTENT_CRUD",
    CUSTOMER_CONTACT_DATA_ORDERS: "CUSTOMER_CONTACT_DATA_ORDERS",
    SET_ORDER_DATA_FOR_EDIT_MODE: "SET_ORDER_DATA_FOR_EDIT_MODE",
    UPDATE_SINGLE_DELIVERY_DATA: "UPDATE_SINGLE_DELIVERY_DATA",
    CUSTOMER_PROPERTIES_TYPE_IN_ORDER: "CUSTOMER_PROPERTIES_TYPE_IN_ORDER",
  },
   INVOICES: {
    GET_INVOICES_STACK: "GET_INVOICES_STACK",
    SET_INVOICES_FOR_CUSTOMER: "SET_INVOICES_FOR_CUSTOMER",
    SET_INVOICES_DELIVERY_DATE_RANGE: "SET_INVOICES_DELIVERY_DATE_RANGE",
    RESET_INVOICES_STATE: "RESET_INVOICES_STATE",
    SAVE_INVOICES_INFORMATION: "SAVE_INVOICES_INFORMATION",
    SAVE_INVOICES_ATTACHMENTS: "SAVE_INVOICES_ATTACHMENTS",
    CUSTOMER_PROPERTIES_IN_INVOICES: "CUSTOMER_PROPERTIES_IN_INVOICES",
    STORE_ALL_CUSTOMER_PROPERTIES_IN_INVOICES:
      "STORE_ALL_CUSTOMER_PROPERTIES_IN_INVOICES",
    UPDATE_EDITOR_OBJECTS_INVOICES: "UPDATE_EDITOR_OBJECTS_INVOICES",
    SELECTED_CUSTOMER_PROPERTY_INVOICES: "SELECTED_CUSTOMER_PROPERTY_INVOICES",
    SAVE_ATTACHMENTS_INVOICES: "SAVE_ATTACHMENTS_INVOICES",
    ADD_NEW_SHIPPING: "ADD_NEW_SHIPPING",
    SET_DATA_UPDATED: "SET_DATA_UPDATED",
    SELECTED_DRIVER_LIST: "SELECTED_DRIVER_LIST",
    DELETE_SHIPPING: "DELETE_SHIPPING",
    UPDATE_DELIVERY_BATCH: "UPDATE_DELIVERY_BATCH",
    ADD_NEW_PRODUCT_INVOICES_DELIVERY: "ADD_NEW_PRODUCT_INVOICES_DELIVERY",
    SELECT_PRODUCT_DATA_IN_INVOICES: "SELECT_PRODUCT_DATA_IN_INVOICES",
    ADD_NEW_VEHICLE: "ADD_NEW_VEHICLE",
    REMOVE_DRIVER_LIST: "REMOVE_DRIVER_LIST",
    SAVE_OFFER_INFORMATION_IN_INVOICES: "SAVE_OFFER_INFORMATION_IN_INVOICES",
    CREATE_NEW_INVOICES_DELIVERY: "CREATE_NEW_INVOICES_DELIVERY",
    ADD_NEW_PRODUCT_INVOICES_CONTENT_CRUD: "ADD_NEW_PRODUCT_INVOICES_CONTENT_CRUD",
    CUSTOMER_CONTACT_DATA_INVOICES: "CUSTOMER_CONTACT_DATA_INVOICES",
    SET_INVOICES_DATA_FOR_EDIT_MODE: "SET_INVOICES_DATA_FOR_EDIT_MODE",
    UPDATE_SINGLE_DELIVERY_DATA: "UPDATE_SINGLE_DELIVERY_DATA",
    SET_PRODUCT_LIST: 'SET_PRODUCT_LIST',
  },
};

export const ROLES = {
  USER: "User",
  ADMIN: "Admin",
  SUPERADMIN: "Super Admin",
};

export const USER_STATUS = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
  { label: "Deleted", value: 2 }
]

export const SEGMENTS = [
  { label: "Company Information", value: "COMPANY_INFORMATION" },
  { label: "Finance", value: "FINANCE" },
  { label: "Risk", value: "RISK" },
  { label: "Ownership", value: "OWNERSHIP" },
  { label: "Management", value: "MANAGEMENT" },
]

export const JOB_DESCRIPTION_TYPE = [
  { id: 1, label: "Betonikuski", value: 1 },
  { id: 2, label: "Pumppari", value: 2 },
  { id: 3, label: "Lattiamies", value: 3 },
  { id: 4, label: "Operaattori", value: 4 },
  { id: 5, label: "Huolto", value: 5 },
]

export const AVAILABLE_LANGUAGES = [
  { shortCode: "en-US", label: "English" },
  { shortCode: "en-FI", label: "Finnish" },
];

export const AGES = [
    { id: 1, label: "-", value: "" },
    { id: 2, label: "50 yrs", value: "50 yrs" },
    { id: 3, label: "100 yrs", value: "100 yrs" },
  ]

export const TABS = {
  MENU: "MENU",
  SETTINGS: "SETTINGS",
  HELP: "HELP",
};

export const PRODUCT_TYPES = {
  CONCRETE: "CON",
  TRANSPORT: "TRA",
  PUMPING: "PUM",
  CON_PUMP: "CON_PUM",
  FLOORING: "FLO",
  PREPARATION: "PRE",
};

export const VEHICLE_TYPES = {
  CONCRETE: "CON",
  PUMPING: "PUM",
  TRANSPORT_PUMP: "TPM",
  CON_PUMP: "CON_PUM",
};

export const PRODUCT_UNITS = {
  M3: "M3",
  TON: "TON",
  KPL: "KPL",
  HOURS: "HRS",
  M2: "M2",
};

export const VALID_FILE_TYPES = [
  "doc",
  "docx",
  "odt",
  "xls",
  "xlsx",
  "pdf",
  "ppt",
  "pptx",
  "txt",
  "csv",
];
export const VALID_IMAGE_TYPES = [
  "jpeg",
  "png",
  "gif",
  "tiff",
  "psd",
  "epf",
  "jpg",
];

export const FILE_CATEGORY = {
  COVER_SIGNATURE: "Order Delivery Cover Signature",
  LOADING_VEHICLE_BILL: "Loading Vehicle",
  LOADING_FOORMAN_BILL: "Floorman Photo",
  DRIVER_SIGNATURE: "Lisää ohjaimen allekirjoitus"
}

export const socketEventType = {
  ack: "ACK",
  emitter: {
    userReadNote: "USER_READ_NOTE",
    authorNewNote: "AUTHOR_NEW_NOTE",
    userRepliedOnNote: "USER_REPLIED_ON_NOTE",
    noteDelete: "NOTE_DELETE",
  },
  receiver: {
    authorNotification: "AUTHOR_NOTIFICATION",
    newNoteFromAuthor: "NEW_NOTE_FROM_AUTHOR",
    replyOnNote: "REPLY_ON_NOTE",
    noteDeleted: "NOTE_DELETED",
  },
};
