export const label = {
  LOGIN: {
    ENTER_EMAIL: "Anna sähköpostiosoite",
    ENTER_PASSWORD: "Anna salasana",
    LOGIN_HERE: "Kirjaudu sisään tästä",
    RESET_PASSWORD: "Nollaa salasana",
    FORGOT_PASSWORD: "Unohditko salasanan?",
    LOGIN: "Kirjaudu sisään",
    REMEMBER_ME: "Muistatko minut?",
    ENTER_OTP: "Anna OTP",
    ENTER_CONFIRMATION_PASSWORD: "Vahvista salasanasi",
  },
  COMMON: {
    BASIC: 'Perus',
    ACTIONS: "Toimet",
    ADD: "Lisää",
    EDIT: "Muokkaa",
    NEW: "Lisää",
    DELETE: "Poista",
    CLEAR: "Tyhjennä",
    ADD_ITEM: "Lisää kohde",
    SAVE: "Tallenna",
    CANCEL: "Peruuta",
    DOWNLOAD: "Lataa",
    PREVIEW: "Esikatsele",
    PREVIEW_CONTENT: "Esikatsele tilausvahvistus",
    PRINT: "Tulosta",
    COPY: "Kopio",
    SEND: "Lähetä",
    YES: "Joo",
    NO: "Ei",
    VIEW: "Näkymä",
    OVERVIEW: "Yleiskatsaus",
    NOTES: "Viestit",
    FILES: "Tiedostot",
    FILE: "Tiedosto",
    CUSTOM_FIELDS: "Lisää kenttä",
    MANAGE_FIELDS: "Hallitse kenttiä",
    NEW_SUPPLIER: "Uusi toimittaja",
    ADD_A_COLUMN: "Hallitse sarakkeita",
    IMPORT: "Tuo",
    EXPORT: "Vie",
    DOWNLOAD_PRODUCT_SAMPLE: "Lataa mallituotetiedosto",
    NEW_CUSTOMER: "Uusi asiakas",
    NEW_OFFER: "Lisää tarjous",
    NEW_ORDER: "Uusi tilaus",
    NEW_INVOICE: "Uusi lasku",
    REMOVE: "Poista",
    NO_RECORD_FOUND: "Merkintöjä ei löydy",
    CLOSE: "Sulje",
    SUBMIT: "Tallenna",
    LOADING: "Ladataan...",
    PRESS_ENTER_TO_EDIT: "Muokkaa painamalla Enter",
    SEARCH: "Hae",
    TYPE: "Tyyppi",
    AREA: "Alue",
    SEND_TO_NETVISOR: "Lähetä Netvisoriin",
    MANAGE: "Hallitse",
    PREVIOUS_PAGE: "Edellinen sivu",
    NEXT_PAGE: "Seuraava sivu",
    GROUP: "Ryhmä",
    SELECT: "Valitse",
    EDITOR: "Muokkain",
    CREATE: "Luo",
    UPDATE: "Päivitä",
    NEXT: "Seuraava",
    SENDING: "Lähettää...",
    FORM: "Täytä lomake",
    NEWORDER: "Uusi Tilaukset",
    GENERATE_INVOICE: "Uusi lasku",
    PREVIEW_ORDER_PROTOCOL: "Tulosta Tilauksen pöytäkirja",
  },
  EMAIL:{
    SEND_EMAIL: "Lähetä sähköposti",
    TITLE: "Otsikko",
    RECIPIENTS: "Vastaanottajat",
    CC: "Kopio",
    BCC: "Piilokopio",
    FROM: "Lähettäjä",
    BODY: "Sisältö"
  },
  LISTING_HEADER: {
    FIRSTNAME: "Henkilöstö",
    WORK_PHONE: "Työpuhelin",
    JOB_DESCRIPTION: "Työnkuvaus",
    ROLE_ID: "Käyttäjän rooli",
    PRODUCT_CODE: "Tuotekoodi",
    PRODUCT_NAME: "Tuotenimi",
    SUPPLIER: "Toimittaja",
    CATEGORY: "Kategoria",
    PRODUCT_TYPE: "Tuotetyyppi",
    PRICE_RANGE: "Hinnat",
    AGE: "Ikä",
    REGISTER_NUMBER: "Rekisterinumero",
    CUSTOMER_REGISTER_NUMBER: "D&B Rekisterinumero",
    CATEGORY_ID: "Kategoria",
    MODEL: "Malli",
    TYPES: "Tyyppi",
    YEAR: "Vuosimalli",
    COLOR: "Väri",
    LENGTH: "Pituus",
    WIDTH: "Leveys",
    HEIGHT: "Korkeus",
    WEIGHT: "Paino",
    CAPACITY: "Kapasitetti",
    SUPPLIER_ID: "Toimittaja",
    DRIVER_ID: "Kuljettaja",
    PHONE: "Puhelinnumero",
    NAME: "Nimi",
    ADDRESS: "Osoite",
    ZIP_CODE: "Postinumero",
    POSTAL_DISTRICT: "Postitoimipaikka",
    COMPANY_NAME: "Yrityksen nimi",
    COMPANY_ID: "Y-Tunnus",
    TYPE_ID: "Tyyppi",
    PHONE_NUMBER: "Puhelinnumero",
    PERSONAL_ID: "Henkilötunnus",
    EMAIL: "Sähköposti",
    COUNTRY: "Maa",
    OFFER_NUMBER: "Tarjousnumero",
    SENT: "Lähetetty",
    OFFER_ACCEPTED: "Tarjous hyväksytty",
    SELLER_DETAILS: "Myyjä",
    ORDER_NUMBER: "Tilausnumero",
    CUSTOMER: "Asiakkaat",
    BOOKED: "Varattu",
    DELIVERED: "Toimitettu",
    STATUS: "Tila",
    ADDITIONAL_INFORMATION: "Lisätiedot",
    CREATED_AT: "Luotu",
    PANEL: "Osio",
    FIELD_NAME: "Lisäkenttä",
    FIELD_TYPE: "Tyyppi",
    GLOBAL_FIELD: "Globaali",
    ORDER_ID: "Järjestys",
    ACCESS: "Näkyvissä",
    IN_SEARCH: "Haettavissa",
    ID: "Lasku ID",
    FILENAME: "Otsikko",
    DESCRIPTION: "Kuvaus",
    CATEGORIES_ID: "Tiedosto kategoria",
    TYPE: "Tyyppi",
    SEGMENTS: "Osiot",
    ZIP_CODE_FULL: "Postinumero",
  },
  APP: {
    WELCOME: "Tervetuloa",
  },
  ORDER_STATUS: {
    DRAFT: "Toimittamatta",
    ON_DELIVERY: "Toimituksessa",
    DELIVERED: "Toimitetu"
  },
  USER_TABLE: {
    PERSONNEL: "Henkilöstö",
    ROLE: "Käyttäjän rooli",
    ROLE_OPTIONS_PLACEHOLDER: "--valitse rooli--",
    ROLE_TEXT: "Rooli",
    JOB_DESCRIPTION: "Työnkuva",
    PERSON: "Henkilö",
    REPORTII: "Raportointi",
    FILES: "Tiedostot",
    GENERAL: "Yleistä",
    NOTES: "Viestit",
    INFORMATION: "Tiedot",
    FIRST_NAME: "Etunimi",
    LAST_NAME: "Sukunimi",
    EMAIL: "Sähköposti",
    WORK_PHONE: "Työpuhelin",
    WORK_PHONE_PLACEHOLDER: "Työpuhelinnumero",
    CREATED_ON: "Luotu",
    OVERVIEW: "Yleiskatsaus",
    SEARCH: "Etsi",
    ADD_NEW_PERSON: "Lisää uusi henkilö",
    STATUS: "Tila",
    PASSWORD: "Salasana",
    NEW_PASSWORD_PLACEHOLDER: "Uusi salasana",
    TITLE: "Otsikko",
    HIDDEN_FIELD: "Piilotettu kenttä",
    APPEARS_IN_SEARCH: "Näkyy haussa",
    SHOW_NAME_INSIDE_FIELD: "Näytä nimi kentän sisällä",
    GLOBAL_FIELD: "Globaali kenttä",
    REQUIRED: "Pakollinen",
    EDIT_MODE_TITLE: "Muokkaa mukautettua kenttää",
    NOT_EDIT_MODE: "Add new field",
    USER: "Käyttäjä",
    ACTIVE: "Aktiivinen",
    INACTIVE: "Ei aktiivinen",
    DELETED: "Poistettu",
    PVM_ALKU: "Pvm. alku",
    PVM_LOPPU: "Pvm. loppu",
    USER_ROLE: "kayttajarooli",
    TYPE: "Tyyppi",
    MANAGE_JOB_DESCRIPTION: "Hallitse työnkuvauksia",
    USER_INFORMATION: "Käyttäjän tiedot",
    Private: "Yksityinen",
    Company: "Yritys",
  },
  FILE: {
    ADD_FILE: "Lisää tiedosto",
    IMPORT_FILE: "Tuo tiedosto",
    NAME: "Nimi",
    PERSONNEL: "Henkilöstö",
    DOCUMENT_TYPE: "Asiakirjan tyyppi",
    START_AT: "Aloita osoitteesta",
    READY_AT: "Valmis klo",
    DESCRIPTION: "Kuvaus",
    FILE_CATEGORY: "Tiedoston luokka",
    FILE_INFORMATION: "Tiedoston tiedot",
    FILE_FORMAT: "Tiedoston muoto",
    FILE_SIZE: "Tiedoston koko",
    TITLE: "Otsikko",
    CANCEL: "Peruuta",
    DELETE: "Poista",
    MANAGE_FILE_CATEGORIES: "Tiedostoluokkien hallinta",
    ADD: "Lisää",
    ENTER_TO_EDIT: "Paina enteriä muokataksesi",
    CLOSE: "Sulje",
    SAVE: "Tallenna",
    DROP_FILE: "Pudota ladattavat tiedostot",
    CHOOSE_FILE: "Valitse tiedostot",
    UPLOADING: "Lataaminen...",
    EDIT_FILE_NAME: "Muokkaa tiedostonimeä",
    FILE_NAME: "Tiedoston nimi",
    SUBMIT: "Tallenna",
    PREVIEW: "Esikatselu",
    EDIT: "Muokkaa",
    REMOVE: "Poista",
    PVM_ALKU: "Pvm. alku",
    PVM_LOPPU: "Pvm. loppu",
  },
  NOTES: {
    READ_BY: "Lukenut",
    ADD_NOTES: "Lisää muistiinpanoja",
    AUTHOR: "Tekijä",
    AUTHOR_2: "Kirjoittaja",
    MARKINGS:"Merkinnät",
    RECIPIENTS: "Vastaanottajat",
    REMINDER_DATE: "Muistutuksen päivämäärä",
    MEMORENDUM: "Muistio",
    NOTE_CATEGORY: "Kategoria",
    TYPE: "Tyypit",
    USERS: "Käyttäjät",
    EDIT: "Muokata",
    DELETE: "Poista",
    RECIPIENT: "Vastaanottaja",
    CATEGORY: "Kategoria",
    CREATED: "Luotu",
    ATTACHMENT: "Liite",
    TIMING: "Ajoitus",
    READ_RECIPIENTS: "Lue vastaanottajat",
    REMOVED_REPLY: "Tämän vastauksen on poistanut",
    REPLY: "Vastaa",
    MANAGE_NOTE_CATEGORY: "Hallitse muistioluokkia",
    NOTE_CATEGORY_PLACEHOLDER: "Huomautus Kategoria",
    ADD: "Lisää",
    CLOSE: "Sulje",
    REMOVE: "Poista",
    SCHEDULE: "Ajoittaa",
    CANCEL: "Peruuta",
    NOTE_RECEIVED_BY: "Uusi huomautus vastaanotti",
    EDITED_REPLY_NOTE: "muokkasivat vastaustaan ​​huomautukseen",
    REPLIED_IN: `on vastannut muistiinpanoon, jossa olet.`,
    ATTACH_FILE: "Liitä tiedosto",
    NO_FILE_CHOOSEN: "Ei tiedostoa valittuna",
    EDIT_REPLY: "Muokkaa vastausta",
    ADD_CATEGORY: "Lisää luokka",
    SUBJECT: "Aihe",
    DISABLE: "Poista käytöstä",
    ENABLE: "Ota käyttöön",
    READ_NOTICES: "lukukuittaus",
    DISALLOW: "Estä",
    ALLOW: "Salli",
    REPLIES: "vastaukset",
    PVM_ALKU: "Pvm. alku",
    PVM_LOPPU: "Pvm. loppu",
    SEARCH: "Hae",
    MORE: "Lisää",
    ATTACH_NAME: "Liitteen nimi",
    MODIFIED: "Muokattu",
  },
  OFFER: {
    ADD_OFFER: "Lisää tarjouksia",
    ADD_NEW_GROUP: "Lisää uusi ryhmä",
    ACCEPT_OFFER: "Hyväksy tarjous",
    REJECT_OFFER: "Hylkää tarjous",
    PRINT_OFFER: "Tulosta tarjous",
    PHONE_SHORT: "Puh.",
    DESCRIPTION: "Kuvaus",
    AMOUNT: "Määrä",
    UNIT: "Yksikkö",
    PRICE: "Hinta",
    TAX_PERCENTAGE: "Vero %",
    TAX_PERCENTAGE_ZERO: "Välisumma",
    DISCOUNT_PERCENTAGE: "Alennus %",
    TAX: "Vero",
    IN_TOTAL: "Yhteensä",
    PROPERTY: "Kohde",
    SENDER: "Lähettäjä",
    RECIPIENT: "Vastaanottaja",
    SENT: "Lähetä",
    OFFER_NUMBER: "Tarjousnumero",
    TITLE: "Tarjous",
    CONFIRMATION_TITLE: "Tilausvahvistus",
    MANAGE_PROPERTY_TYPES: "Hallitse kohde tyyppejä",
    CREATE_NEW_ROW: "Luo uusi rivi",
    PRODUCT_LIST: "Tuotelista",
    GROUP_NAME: "Ryhmän nimi",
    NEW_OFFER_GROUP: "Uusi tarjous ryhmä",
    RETAIL_PRICE: "Myyntihinta",
    ADD_A_ROW: "Lisää tuote",
    NO_PRODUCTS_ADDED: "Tuotteita ei ole lisätty",
    NO_ITEMS_ADDED: "Tuotteita ei ole lisätty",
    DUPLICATE_PRODUCT_IN_SAME_OFFER: "Tuote on jo tarjouksella",
    TYPE_TO_SEARCH: "Kirjiota etsijäksesi...",
    MANAGE_OFFER_STATUS: "Hallinnoi tarjouksen tiloja",
    NO_OFFER_GROUPS_FOUND: "Tarjousryhmiä ei löytynyt!",
    ATTACHMENTS: "Liitteet",
    TAX_SEPARATED: "Vero eriteltynä",
    TAX_INCLUDED: "Sisältää veron",
    TAX_EXCLUDED: "Veroton",
    OFFER_STATUS: "Tarjouksen tila",
    OFFER_CREATED: "Tarjous luotu",
    OFFER_SENT: "Tarjous lähetetty",
    OFFER_INFORMATION: "Tarjouksen tiedot",
    CONTACT_PERSON: "Yhteyshenkilö",
    ADD_NEW: "Lisää uusi",
    PREVIEW_WITHOUT_TAX: "Veroton hinta",
    PREVIEW_TAX: "Vero",
    PREVIEW_TOTAL: "Verollinen hinta",
  },
  CONTACT_PRICE: {
    PRICE: "Lisää hintoja",
  },
  STOCK: {
    CODE: "Koodi",
  },
  SIDEMENU: {
    FRONT_PAGE: {
      FRONT_PAGE: "Etusivu",
    },
    Personnel: {
      Personnel: "Henkilöstö",
      CRE: "Työntekijät",
    },
    Stock: {
      Stock: "Varasto",
      PRD: "Tuotteet",
      VCL: "Ajoneuvot",
      SUP: "Toimittajat",
    },
    Customer: {
      Customer: "Asiakkaat",
      CUS: "Asiakkaat",
    },
    Offer: {
      Offer: "Tarjoukset",
      OFS: "Tarjoukset",
    },
    Order: {
      Order: "Tilaukset",
      ORD: "Tilaukset",
    },
    Delivery: {
      Delivery: "Toimitukset",
      DLV: "Päivätoimituslista",
    },
    Invoicing: {
      Invoicing: "Laskutus",
      INV: "Laskutus"
    },
  },
  NOTFOUND: {
    NOTFOUND_404: "Oho! Sivua ei löydetty",
    NOTFOUND: "Etsimäsi sivu ei ole täällä",
    HOME: "Koti",
  },
  PRODUCT: {
    NEW_PRODUCT: "Uusi tuote",
    BASIC_INFO: "Perustiedot",
    DELETE_PRODUCT: "Poista tuote",
     newOrEditForm: {
      productName: 'Tuotennimi',
      productCode: 'Tuotekoodi',
      taxStatus: 'Verotuksen tila',
      taxRate: 'Veroprosentti',
      STOCK_PRODUCT: 'Varastotuote',
      brand: 'Brändi',
      category: 'Kategoria',
      units: 'Yksiköt',
      age: 'Ikä',
      quickDescription: 'Lyhyt kuvaus',
      longDescription: 'Pitkä kuvaus',
      productImage: 'Tuotekuva',
      taxable: 'Verollinen',
      nonTaxable: 'Veroton',
      reversedTax: 'Käännetty vero',
      selectTaxRate: 'Valitse Veroprosentti',
      manageBrands: 'Hallitse brändejä',
      manageUnits: 'Hallinnoi yksiköitä',
      manageTaxRate: 'Hallitse veroprosentteja',
      manageAge: 'Hallitse ikää',
      productAge: 'Tuotteen ikää',
      productCategory: 'Tuotteen kategoria',
      selectUnit: 'Valitse Yksikkö',
      startTime: 'Aloitusaika',
      endTime: 'Lopetusaika',
    },
    suppliers: {
      addSupplier: 'Lisää toimittaja',
      addNew: 'Lisää uusi',
      selectSupplier: 'Valitse Toimittaja',
      ADD_CONTACT_PERSON: "Lisää yhteyshenkilö"
    },
    manageStocks: {
      manageStockTitle: "Hallitse varastoja",
      totalInStock: "Yhteensä Varastossa",
      wareHouse: "Varasto",
      amount: "Määrä",
      stocks: "Varastot",
      alarmLimit: "Hälytysraja",
      stockLessThanAlarmLimit: "Varasto on päivitettävä",
    },
    manage: {
      supplier: "Toimittaja",
      category: "Kategoria",
    },
    NEW_STOCK_TITLE: "Lisää uusi",
    EDIT_STOCK_TITLE: "Muokkaa Varastossa",
    NEW_DAILY_PRICE: "Lisää hinnoittelumalli",
    EDIT_DAILY_PRICE: "Muokkaa hinnoittelumalli",
    BASIC_PRICE: {
      TITLE: "Perushinnat",
      AMOUNT: "Määrä",
      SUPPLIER: "Toimittaja",
      UNIT: "Yksikkö",
      SELL_PRICE: "Myyntihinta",
      PURCHASE_PRICE: "Ostohinta",
      EDIT_PRICE_FOR: "Muokkaa hintoja"
    },
    VARIENT_PRICE: {
      TITLE: "Vaihtoehtoiset hinnat",
      TIME_TITLE: "Aikaan sidonnaiset hinnat",
      DAYS: "Päivät",
      FROM_TIME: "Alkaen",
      TO_TIME: "Päättyen",
      AMOUNT: "Määrä",
      SUPPLIER: "Toimittaja",
      UNIT: "Yksikkö",
      SELL_PRICE: "Myyntihinta",
      PURCHASE_PRICE: "Ostohinta",
    },
    CONCRETE_CHARACTERISTICS: {
      TITLE: "Tuotteen ominaisuudet",
      DIALOG_TITLE: "Hallinnoi tuoteominaisuuksia",
      NAME: "Nimi",
      SET_OF_VALUES: "Aseta arvot",
    },
    LINK_PRODUCT: {
      TITLE: "Linkitetyt tuotteet",
      ADDITIONAL_PRODUCT: "Lisätuotteet",
      SEARCH_PRODUCT: "Hae tuote",
      ADD_NEW: "Lisää uusi",
      SUBMIT: "Tallenna",
      PRODUCT_CODE: "Tuotekoodi",
      PRODUCT_NAME: "Tuote"
    },
  },
  SUPPLIER_INFO: {
    SUPPLIER_INFORMATION_HEADING: "Tomittajan tiedot",
    FACTORY_HEADING: "Tehdas",
    CONTACT_PERSONS_HEADING: "Yhteyshenkilöt",
    BASIC_INFO: "Perustiedot",
    PRODUCT: "Tuotteet",
    ADD_FIELD: "Lisää kenttä",
    MANAGE_COLUMN: "Hallitse sarakkeita",
    NAME: "Toimittajan nimi",
    COMPANY_ID: "Yrityksen tunnus",
    PHONE_NUM: "Puhelinnumero",
    EMAIL: "Sähköposti",
    EMAIL_PLACEHOLDER: "Sähköposti",
    ADDRESS: "Osoite",
    ZIP_CODE: "Postinumero",
    POST_DISTRICT: "Postitoimipaikka",
    COUNTRY: "Maa",
    STATUS: "Tila",
    STATUS_ACTIVE: "Aktiivinen",
    STATUS_INACTIVE: "Ei aktiivinen",
    MANAGE_STATUS: {
      MANAGE_STATUS_HEADLINE: "Hallinnoi tiloja",
      ENTER_TO_EDIT: "Muokkaa painamalla Enter",
      SUPPLIER_PLACEHOLDER: "Toimittajan tila",
      ADD: "Lisää",
      DELETE: "Poista",
      CLOSE: "Sulje",
    },
    YET_TO_COMPLETE: "Vielä valmiiksi",
    SEARCH: "Hae",
    PVM_ALKU: "Pvm. alku",
    PVM_LOPPU: "Pvm. loppu",
  },
  FACTORY: {
    FACTORY_HEADLINE: "Tehdastiedot",
    NAME: "Nimi",
    ADDRESS: "Osoite",
    COUNTRY: "Maa",
    ZIP_CODE: "Postinumero",
    POSTAL_DISTRICT: "Postitoimipaikka",
    CONTACT_PERSON: "Yhteyshenkilö",
    TELEPHONE_NUM: "Puhelinnumero",
    EMAIL: "Sähköposti",
    DELETE: "Poista",
    SAVE: "Tallentaa",
    EDIT: "Muokata",
    ADD_FIELD: "Lisää kenttä",
    FACTORY_AVAILABILITY: "Saatavilla tehtaasta",
  },
  CONTACT_PERSON: {
    EDIT: "Muokkaa",
    DELETE: "Poista",
    NO_CONTACTS_MSG: "Yhteyshenkilöitä ei ole lisätty",
    CONTACT_HEADLINE: "Yhteyshenkilö",
    ADD_FIELD: "Lisää kenttä",
    FIRST_NAME: "Etunimi",
    LAST_NAME: "Sukunimi",
    TITLE: "Otsikko",
    PHONE: "Puhelin",
    EMAIL: "Sähköposti",
    ADDITIONAL_INFO: "Lisätiedot",
    SAVE: "Tallenna",
    STATUS: "Tila",
    TYPE: "Tyyppi",
    USER_ROLE: "Käyttäjän rooli",
  },
  CUSTOMER: {
    MANGE_CUSTOMER_ROLE: "Hallinnoi asiakkaan rooleja",
    MANGE_CUSTOMER_STATUS: "Hallinnoi asikkaan tiloja",
    ADD_CONTRACT_PRICE: "Lisää sopimushinta",
    EDIT_CONTRACT_PRICE: "Muokkaa sopimushinta",
    CONTRACT_PRICE_PRODUCT_LABEL: "Tuote",
    CONTRACT_PRICE_PRODUCT_PLACEHOLDER: "Valitse tuote",
    CONTRACT_PRICE_DISCOUNT_LABEL: "Alennus (%)",
    CONTRACT_PRICE_DISCOUNT_PLACEHOLDER: "Alennusporsentti",
    CONTRACT_PRICES: "Sopimushinnat",
    CONTACT_PERSONS: "Yhteyshenkilöt",
    OFFERS: "Tarjoukset",
    ORDERS: "Tilaukset",
    INVOICES: "Laskut",
    BASIC_INFO: "Perustiedot",
    FILES: "Tiedostot",
    CONTACT_PERSONS_NEW: "Lisää uusi",
    CONTRACT_PRICES_HEADER: {
      product_code: "Tuotekoodi",
      product: "Tuote",
      discount: "Alennus %",
      actions: "Toimet",
    },
    CREDIT_INFORMATION: {
      TITLE: "Luottotiedot",
      DATA_FETCHED: "Tiedot haettu",
      CREDIT_RATING: "Luottoluokitus",
      CREDIT_RATING_HISTORY: "Luottoluokitus historia",
      RATING: "Luokitus",
      RATING_DATE: "Luokituspäivä",
      PAYMENT_METHOD_HISTORY: "Maksutapa historia",
      PAYMENT_ABILITY_RATING: "Maksukyky luokitus",
      DATE: "Päivä",
    },
    BILLING_INFO: {
      TITLE: "Laskutustiedot",
      SAME_AS_BASIC_ADDRESS: "Sama kuin postiosoite",
      ADDRESS: "Osoite",
      ZIP_CODE: "Postinumero",
      POSTAL_DISTRICT: "Postitoimipaikka",
      COUNTRY: "Maa",
      E_INVOICING_ADDRESS: "Verkkolaskuosoite",
      OPERATOR_ID: "Operaattoritunnus",
      ORDERER: "Operaattori",
      EMAIL: "Sähköposti",
      PHONE_NUMBER: "Puhelinnumero",
      SELLER: "Myyjä",
    }
  },
  VEHICLES: {
    BASIC: {
      CANCEL: "Peruuta",
      SUBMIT: "Tallenna",
      NAME: "Nimi",
      CATEGORY: "Kategoria",
      SUPPLIER: "Toimittaja",
      REGISTRY_NUM: "Rekisterinumero",
      MODEL: "Malli",
      TYPE: "Tyyppi",
      MODEL_YEAR: "Vuosimalli",
      COLOR: "Väri",
      LENGTH: "Pituus",
      WIDTH: "Leveys",
      HEIGHT: "Korkeus",
      WEIGHT: "Paino",
      STATUS: "Tila",
      PICTURES: "Kuvia",
      AREA: "Alue",
    },
    DRIVER: {
      ADD_DRIVER: "Lisää kuljettaja",
      SELECT_DRIVER: "Valitse kuljettaja",
      ADD_NEW: "Lisää uusi",
      NAME: "Nimi",
      LAST_NAME: "Sukunimi",
      PHONE_NUM: "Puhelinnumero",
      PRIMARY_DRIVER: "Ensisijainen kuljettaja",
      ADD_NEW_USER_HEADLINE: "Lisää kuljettaja",
      FIRST_NAME: "Etunimi",
      EMAIL: "Sähköposti",
      PASSWORD: "Salasana",
      WORK_PHONE: "Työpuhelin",
      WORK_PHONE_PLACEHOLDER: "työpuhelinnumero",
      ROLE: "Rooli",
      ROLE_OPTIONS_PLACEHOLDER: "--valitse rooli--",
      SWITCH_SECONDARY: "Toissijainen",
      SWITCH_PRIMARY: "Ensisijainen",
    },
    CAPACITY: {
      CAPACITY: "Kapasiteetti",
      UNIT: "Yksikkö",
      AMOUNT: "Määrä",
      BOOM_LENGTH: "Puomin pituus",
      M: "m",
      SHOW_IN_TABLE: "Näytä taulukossa",
      LITRE: "Litra",
      L: "l",
      TONS: "Tonnia",
      T: "t",
      CUBIC_METER: "Kuutiometri",
      M3: "m3",
    },
    INSTRUCTIONS: {
      CANCEL: "Peruuta",
      SUBMIT: "Tallenna",
      NAME: "Nimi",
      TYPE: "Tyyppi",
      CREATED: "Luotu",
      VIEW_FILE: "View File"
    },
    MANAGE_CATEGORY: {
      HEADLINE: "Hallitse luokkia",
      CATEGORY_PLACEHOLDER: "Ajoneuvo Kategoria",
      ADD: "Lisää",
      ENTER_TO_EDIT: "Muokkaa painamalla Enter",
      DELETE: "Poista",
      CLOSE: "Sulje",
      OFFER_STATUS: "Tarjouksen tila",
    },
    MANAGE_TYPE: {
      HEADLINE: "Hallitse tyyppejä",
      FILE_TYPE_PLACEHOLDER: "Ajoneuvo Tyyppi",
      ADD: "Lisää",
      ENTER_TO_EDIT: "Muokkaa painamalla Enter",
      DELETE: "Poista",
      CLOSE: "Sulje",
    },
     MANAGE_AREA: {
      HEADLINE: "Hallitse Alue",
      AREA_PLACEHOLDER: "Ajoneuvo Alue",
      ADD: "Lisää",
      ENTER_TO_EDIT: "Muokkaa painamalla Enter",
      DELETE: "Poista",
      CLOSE: "Sulje",
    },
    SEARCH: "Hae",
    NEW_STOCK_TITLE: "Lisää uusi",
    ADD_FIELD: "Lisää kenttä",
    PVM_ALKU: "Pvm. alku",
    PVM_LOPPU: "Pvm. loppu",
  },
  SMTP_SETTINGS: {
    HEADLINE: "SMTP-asetukset",
    FORM_NAME: "Lähetttäjän nimi",
    DRIVER: "Kuljettaja",
    MAIL_SERVER_HOST_NAME: "Postipalvelimen isäntä Nimi",
    PORT: "Portti",
    ENCRYPTION_METHOD: "Salausmenetelmä",
    USER_NAME: "Käyttäjätunnus",
    PASSWORD: "Salasana",
  },
  COMPANYINFO_SETTINGS: {
    HEADLINE: "Company Settings",
    NAME:"Company Name",
    TITLE:"Company Title",
    ADDRESS:"Company Address",
    ZIP:"Company ZIP",
    POSTAL_DISTRICT:"Company Postal District",
    WWW:"Company WWW",
    LOGO:"Company Logo",
    EMAIL:"Company Email",
    PHONE_NUMBER:"Company Phone number",
    COMPANY_ID:"Company ID",
    TAX_ID:"Company Tax ID",
    OVT_ID:"Company OVT ID",
    BANK:"Company Bank",
    BANK_ACCOUNT:"Company Bank Account",
    BIC:"Company BIC",
    COMPANY_THEME:"Company Theme",
  },
  VEHICLE_TABLE: {
    REGISTRATION_NUM: "Rekisterinumero",
    NAME: "Nimi",
    CATEGORY: "Kategoria",
    MODEL: "Malli",
    TYPE: "Tyyppi",
    MODEL_YEAR: "Mallin vuosi",
    COLOR: "Väri",
    LENGTH: "Pituus",
    WIDTH: "Leveys",
    HEIGHT: "Korkeus",
    WEIGHT: "Paino",
    CAPACITY: "Kapasiteetti",
    STATUS: "Tila",
    SUPPLIER: "Toimittaja",
    DRIVER: "Kuljettaja",
    FILES_AND_INSTRUCTIONS: "Tiedosto ja ohjeet"
  },
  SUPPLIER_TABLE: {
    NAME: "Nimi",
    COMPANY_ID: "Yrityksen tunnus",
    PHONE: "Puhelin",
    EMAIL: "Sähköposti",
    STATUS: "Tila",
    ADDRESS: "Osoite",
    ZIP: "POSTINUMERO",
    POSTAL_DISTRICT: "Postitoimipaikka",
    CREATED_AT: "Luotu klo",
  },
  CONTACT_PERSONS_IN_SUPPLIER: {
    FIRST_NAME: "Etunimi",
    LAST_NAME: "Sukunimi",
    TITLE: "Titteli",
    PHONE_NUM: "Puhelinnumero",
    EMAIL: "Sähköposti",
    ADDITIONAL_INFO: "lisätiedot",
  },
  PRODUCT_TABLE: {
    PRODUCT_CODE: "Tuotekoodi",
    PRODUCT: "Tuote",
    COLOR: "Väri",
    SUPPLIER: "Toimittaja",
    CATEGORY: "Kategoria",
    PRODUCT_TYPE: "Tuotetyyppi",
    PRICE_RANGE: "Hinnat",
  },
  PRODUCT_VARIANT_TABLE: {
    PRODUCT_CODE: "Tuotekoodi",
    CLASS: "Luokka",
    SIZE: "Koko",
    FLEXIBILITY: "Joustavuus",
    HARDENING_CLASS: "Kovettumisluokka",
    AMOUNT: "Määrä",
    SUPPLIER: "Toimittaja",
    UNIT: "Yksikkö",
    SELL_PRICE: "Myyntihinta",
    PURCHASE_PRICE: "Ostohinta",
  },
  CUSTOM_FIELDS_TABLE: {
    SECTION: "Osio",
    EXTRA_FIELD: "Ylimääräinen kenttä",
    TYPE: "Tyyppi",
    GLOBAL: "Maailmanlaajuinen",
    ORDER_IN_LIST: "Tilaa listalla",
    VISIBLE: "Näkyvissä",
    SEARCHABLE: "Haettavissa",
    EDIT: "Muokkaa",
    DELETE: "Poista",
    SELECT_PLACEHOLDER: "Valitse...",
  },
  CRON_TAB: {
    CRON_JOB_P:
      "Cron-töiden avulla voit automatisoida tiettyjä komentoja tai komentosarjoja sivustollasi. Voit asettaa komennon tai komentosarjan toimimaan tiettyyn aikaan joka päivä, viikko jne. Voit esimerkiksi asettaa cron-työn poistamaan väliaikaiset tiedostot viikoittain levytilan vapauttamiseksi..",
    WARNING: "Varoitus",
    WARNING_MSG:
      "Sinun on tunnettava Linux-komennot ennen kuin voit käyttää cron-töitä tehokkaasti. Tarkista komentosarjasi hosting-järjestelmänvalvojalta ennen cron-työn lisäämistä.",
    CRON_EMAIL: "Cron Sähköposti",
    SEND_EMAIL_MSG:
      "Lähetä sähköpostiviesti tulosteella aina, kun cron-työ suoritetaan.",
    GENERAL_EXAMPLE: "Yleinen esimerkki",
    DOMAIN_EXAMPLE: "Verkkotunnuskohtainen esimerkki",
    EXAMPLE_MSG:
      'Korvaa yllä olevassa esimerkissä "ea-php99" PHP-versiolla, joka on määritetty käytettävälle verkkotunnukselle. Etsi verkkotunnukselle määritetty todellinen PHP-versio <a role="button" className="text-primary">MultiPHP Managerista</a>..',
    ADD_NEW_CRON_JOB: "Lisää uusi Cron-työ",
    PHP_COMMAND_EXAMPLES: "Esimerkkejä PHP-komennoista:",
    EDIT: "Muokkaa",
    DISABLE: "Poista käytöstä",
    ENABLE: "Ota käyttöön",
    DELETE: "Poista",
    ADD_CRON: {
      TITLE: "Otsikko:",
      COMMON_SETTINGS: "Yleiset asetukset",
      COMMON_SETTING_OPTIONS: "--Yleiset asetukset--",
      MINUTE: "Minuutti:",
      HOURS: "Tunnin:",
      DAY: "Päivä:",
      MONTH: "Kuukausi:",
      WEEKDAY: "Viikonpäivä:",
      COMMAND: "Komento:",
    },
    CURRENT_CRON_JOBS: {
      HEADLINE: "Nykyiset Cron-työpaikat",
      TITLE: "Otsikko",
      MINUTE: "Minuutti",
      HOUR: "Tunnin",
      DAY: "Päivä",
      MONTH: "Kuukausi",
      WEEKDAY: "Viikonpäivä",
      COMMAND: "Komento",
      ACTIONS: "Toiminnot",
      EDIT_CRON: "Muokkaa Cronia",
    },
  },
  FORM: {
    BACK: "Takaisin",
    CANCEL: "Peruuta",
    PLEASE_WAIT: "Odota...",
    SUBMIT: "Tallenna",
  },
  ASIDE: {
    OWN_ACCOUNT: "Oma tili",
    SIGNOUT: "Kirjaudu ulos",
    COMPANY_SETTINGS: "Yhtiön tiedot",
    SMTP_SETTINGS: "SMTP-asetukset",
    CUSTOM_FIELDS: "Mukautetut kentät",
    CRON_JOBS: "Cronjobs",
    HELP_TAB: "Ohje-välilehti",
    TOGGLE: "Vaihda",
    HELP: "auta",
    SETTINGS: "asetukset",
    MENU: "Valikko",
  },
  DATA_TABLE: {
    MANAGE_COLUMNS: "Hallitse sarakkeita",
    PREVIOUS: "Edellinen",
    NEXT: "Seuraava",
    LAST_PAGE: "Viimeinen sivu",
    FIRST_PAGE: "Ensimmäinen sivu",
    GO_TO_PAGE: "Mene sivulle",
    ALL: "Kaikki",
    UPLOADING: "Lähetetään...",
    ACTIONS: "Toiminnot",
    NOTHING_TO_SHOW: "Ei mitään näytettävää",
    RESULTS: "Tulokset",
  },
  BREAD_CRUMBS: {
    CUSTOM_FIELDS: "Mukautetut kentät",
    CRON_JOBS: "Cron Jobs",
    HOME: "Koti",
    DASHBOARD: "Kojelauta",
  },
  HEADER: {
    LOGOUT: "Kirjaudu ulos",
  },
  MENU_BAR: {
    BOLD: "Lihavoitu",
    ITALIC: "Kursiivi",
    STRIKE: "Lakko",
    PARAGRAPH: "Kohta",
    H1: "H1",
    H2: "H2",
    H3: "H3",
    H4: "H4",
    H5: "H5",
    H6: "H6",
    BULLETED_LIST: "Ranskalaiset viivat",
    NUMBERED_LIST: "Numeroitu luettelo",
    BLOCK_QUOTE: "Lohkolainaus",
    HORIZONTAL_RULE: "Vaakasuuntainen sääntö",
    HARD_BREAK: "Kova tauko",
    UNDO: "Kumoa",
    REDO: "Toista",
    LEFT: "Vasemmalle",
    RIGHT: "Oikea",
    CENTER: "keskittää",
    JUSTIFY: "Vanhurskauttaa",
    INDENT: "Sisentää",
  },
  NOTIFICATIONS: {
    TITLE: "Ilmoitukset",
    DATA_TITLE: "Ilmoitus",
    NO_NOTIFICATION: "Ei näytettäviä ilmoituksia",
    NEW_NOTE: "Uusi huomautus vastaanotettu",
    DELETED_NOTE: "Uusi huomautus vastaanotettu",
    UPDATE_IN_NOTES: "Päivitys muistiinpanoissa",
  },
  INVOICE: {
     INVOICE_INFO: "Laskun tiedot",
     INVOICE_TITLE: "Laskun tunniste- ja maksutiedot",
     HEADERS: {
      invoiceNumber: "Laskun ID",
      submittedToNetvisor: "Lähetetty netvisoriin",
      invoice_status: "Laskun tila",
      invoiceAdditionalDetail: "Lisätiedot",
      created_at: "Lasku luotu",
      order_number: "Tilausnumero",
      firstname: "Kohde"
     },
     LABEL: {
      INVOICE_STATUS: "Laskun tila" ,
      INVOICE_TYPE: "Laskun tyyppi",
      INVOICE_CREATED: "Lasku luotu",
      INVOICE_ID: "Laskun ID",
      SUBMITTED_TO_NETVISOR: "Lähetetty netvisoriin",
      ORDER_NUMBER: "Tilausnumero",
      CUSTOMER: "Kohde",
       SELLER: "Myyjä",
      ADDITIONAL_INO: "Lisätiedot",
      PRODUCTS: "Tuotteet",
      PRODUCT: "Tuote",
      AMOUNT: "Määrä",
      UNIT: "Yksikkö",
      PRICE_TAX_FREE: "A-hinta (veroton)",
      VAT: "ALV",
      DISCOUNT: "Alennus",
      TAX_FREE_PRICE: "Veroton hinta",
      TAX: "Vero",
      PRICE_WITH_TAX: "Verollinen hinta",
      FETCH_PRODUCT: "Tuo tuotteet",
      ADD_INVOICE_STATUS: "Lisää laskun tila",
      MANAGE_INVOICE_STATUS: "Hallitse laskun tilaa",
      MANAGE_INVOICE_TYPE: "Hallitse laskutyyppiä",
      ADD_INVOICE_TYPE: "Lisää laskun tyyppi",
      ADD_CATEGORY: "Lisää kategoria",
      ADD_BRAND: "Lisää tuotemerkki",
      WAREHOUSE: "Varasto",
      IN_TOTAL: "Yhteensä",
      ADD_BILLING_LINE: "Lisää laskutusrivi"
     },
     DELIVERY: {
       DELIVERIES: "Toimitus",
       ORDER: "Tilaus",
       DELIVERY_BATCH_EQUIPMENT_DRIVER: "Toimituserä, kalusto ja kuski",
     }
  },
  ORDER: {
    ORDER_INFO: "Tilauksen tieodot",
    MANAGE_STATUS: "Hallitse tilauksen tilaa",
    ADD_STATUS: "Lisää tilauksen tila",
    DELIVERY_PROTOCOL: "Toimituspöytäkirja",
    DELIVERY_PROTOCOL_HYBRID: "Betonipumppuauton pystytyspöytäkirja",
    FETCHING_DETAILS: "Haetaan tietoja",
    LOADING_YOUR_ORDER: "Ladataan tilausta",
    ADD_SIGNATURE: "Lisää allekirjoitus",
    ADD_PRODUCT: "Lisää tuote",
    CUSTOMER_BILLING_ADDRESS: "Asiakas/laskutusosoite",
    RECIPIENT_SHIPPING_ADDRESS: "Vastaanottaja/toimitusosoite",
    QUANTITY: "Määrä",
    NUMBER_OF_DELIVERED_LOADS_TOTAL_M3: "Toimituksien määrä/Yhteensä m3",
    ARRIVE: "Saapunut",
    GONE: "Lähtenyt",
    KM: "Km",
    REG_NO: "Rek.num",
    NOTES_ON_DELIVERY: "Toimituksen merkinnät",
    RECIPIENTS_ACKNOWLEDGMENT_AND_PHONE_NUMBER :"Vastaanottajan hyväksyntä ja puhelinnumero",
    ORDER_CREATED: "Tilaus luotu",
    LABEL: {
      ORDER_NUMBER: "Tilausnumero",
      CUSTOMER_NAME: "Asiakkaan nimi",
      OFFER: "Tarjous",
      ORDER_STATUS: "Tilauksen tila",
      SELLER: "Myyjä",
      ADDITIONAL_INFO: "Lisätiedot",
      SCHEDULE: "Aikatauluta",
      START_DATE: "Pvm. alku",
      END_DATE: "Pvm. loppu",
      START_TIME: "Aika alku",
      END_TIME: "Aika loppu",
      WORKER: "Työntekijä",
      ROUND: 'Erä',
      SELECT_CUSTOMER_PROPERTY: "Valitse asiakkaan kohde",
      START_DELIVERY_DATE: "Start Delivery",
      REACH_FACTORY_DATE: "Reach Factory",
      REACH_FACTORY_DISTANCE: "Reach Factory Distance",
      START_LOADING_VEHICLE_DATE: "Start Loading Vehicle",
      STOP_LOADING_VEHICLE_DATE: "Stop Loading Vehicle",
      DRIVE_DESTINATION_DATE: "Drive Destination",
      REACH_DESTINATION_DATE: "Reach Destination",
      REACH_DESTINATION_DISTANCE: "Reach Destination Distance",
      CONCRETE_PUMPTRUCK_PHOTO_ADDED: "Pumptruck Photo Added",
      CONCRETE_PUMPTRUCK_START_PUMPING_DATE: "Aloita pumppaus",
      CONCRETE_PUMPTRUCK_STOP_PUMPING_DATE: "Lopeta pumppaus",
      START_UNLOADING_VEHICLE_DATE: "Start Unloading Vehicle",
      STOP_UNLOADING_VEHICLE_DATE: "Stop Unloading Vehicle",
      STOP_UNLOADING_VEHICLE_JACKSHOTS: "Stop Unloading Vehicle Jackshots",
      STOP_UNLOADING_VEHICLE_DISTANCE: "Stop Unloading Vehicle Distance",
      FINISH_DELIVERY_DATE: "Finish Delivery",
      STOP_DELIVERY_DISTANCE: "Stop Delivery Distance",
      PUMPTRUCK_START_MOUNTING_DATE: "Aloita pystytys",
      PUMPTRUCK_PHOTO_ADDED_DATE: "Pumppauspöytäkirja kuvattu",
      PUMPTRUCK_FORM_ADDED_DATE: "Pumppauspöytäkirja täytetty",
      PUMPTRUCK_START_PUMPING_DATE: "Aloita pumppaus",
      PUMPTRUCK_STOP_PUMPING_DATE: "Lopeta pumppaus",
      FLOORMAN_START_WORKING_DATE: "Start Working",
      FLOORMAN_STOP_WORKING_DATE: "Stop Working",
      FLOORMAN_START_SEPERATE_HOURLY_WORK_DATE: "Start Seperate Hourly Work",
      FLOORMAN_STOP_SEPERATE_HOURLY_WORK_DATE: "Stop Seperate Hourly Work",
      FLOORMAN_START_WAITING_TIME_DATE: "Start Waiting Time",
      FLOORMAN_STOP_WAITING_TIME_DATE: "Stop Waiting Time",
      COPY: "Kopio Tilauksen",
    },
    CUSTOMER: {
      CUSTOMER_INFO: "Asiakastiedot",
      BASIC_INFO: "Perustiedot",
      BILLING_INFO: "Laskutus tiedot",
    },
    PROPERTY: {
      NEW_TYPE: "Uusi kiinteistötyyppi",
      MANAGE_TYPE: "Hallitse kiinteistötyyppejä",
      ADD_TYPE: "Lisää kohteen tyyppi",
      PRESS_ENTER: "Muokkaa painamalla Enter",
      PROPERTY_INFO: "Kohde",
      ADD_NEW_PROPERTY: "Lisää uusi kohde",
      PROPERTY_TYPE: "Kohteen tyyppi",
      FULL_ADDRESS: "Koko osoite",
      ADDRESS: "Osoite",
      ZIP_CODE: "Postinumero",
      POSTAL_DISTRICT: "Postitoimipaikka",
      POSTAL_OFFICE: "Postitoimisto",
      PROPERTIES: "Kohteet",
      UPDATE_PROPERTY_TYPE: "Päivitä kohteen tyyppi",
      NEW_PROPERTY_TYPE: "Uusi kohteen tyyppi",
    },
    DELIVERY: {
      DELIVERY_INFO: "Toimitukset",
      ADD_NEW_DELIVERY: "Lisää uusi toimitus",
      ADD_NEW_VEHICLE: "Lisää uusi ajoneuvo",
      ADD_NEW_ONE: "Lisää uusi",
      ADD_NEW_CREW: "Lisää uusi miehistö",
      SELECT_CREW: "Työntekijä",
      ADD_SHIPPING: "Lisää toimitus",
      ADD_DELIVERY_SCHEDULE: "Lisää Toimituksen aikataulut",
      DELIVERIES: "Toimitukset",
      PRODUCT: "Tuote",
      AMOUNT: "Määrä",
      UNIT: "Yksikkö",
      PUMP_PRODUCT: "Pumppaustuote",
      CONCRETE_PRODUCT: "Betonituote",
      DELIVERY_AREA: "Toimitusalue",
      FACTORY: "Tehdas",
      DELIVERED_AT: "Toimitettu",
      STATUS: "Tila",
      DELIVERY_TIME: "Toimitusaika",
      STARTED: "Aloitettu",
      FINISHED: "Valmis",
      DELIVERY_BATCHES: "Toimituserät",
      PERIODS: "Jaksot",
      PERIODS_TIME: "Jaskoaika",
      EQUIPMENT_DRIVERS: "Varusteet ja kuskit",
      AT: "klo",
      VEHICLE: "Ajoneuvo",
      EQUIPMENT_TYPE: "Tyyppi",
      DRIVER: "Kuljettaja",
      ROUND: "Toimituserät",
      RETURN_FREIGHT: "Paluurahti",
      WEIGHT: "Paino",
      NO_DELIVERY_FOUND: 'Toimituksia ei löytynyt',
      CONCRETE_QUALITY: "Betonilaatu",
      PUMPING_QUALITY: "Pumppus",
      ADD: "Lisät",
      ADDITIVES :"Lisäaineet",
      DISMANTLING_METHOD: "Purkutapa",
      WASHING_METHOD: "Pesu",
      LINE_ALLOWANCE: "Linjalisä",
      FLEXIBLE: "Notkistetta",
      ADD_GUTTER: "Lisää kourua",
      ROLLER_COASTER: "Valuputki",
      WATER: "Vettä",
      DELIVERY_SCHEDULES: "Toimituksen aikataulut",
      AMOUNT_OF_RETURN_FREIGHT: "Punnittu määrä",
      DUMP_TRUCK: "Arvioitu määrä",
      START: "Aloitus",
      ARRIVED_AT_FACTORY: "Saavuttu tehtaalle",
      TRAVEL: "Matka",
      LOADING: "Kuormaus",
      MINUTES_LOADED: "Pöytäkirja ladattu",
      TRANSPORT_TO_DESTINATION: "Kuljetus kohteeseen",
      UNLOADING_TIME: "Purkuaika",
      EPISODES_OVER: "Jaksot yli",
      START_MOUNTING: "Aloita pystytys",
      START_PUMPING: "Aloita pumppaus",
      STOP_PUMPING: "Lopeta pumppaus",
      STOP_DELIVERY: "Lopeta toimitus",
      PUMP_TRUCK_PHOTO_ADDED: "Pumppauspöytäkirja kuvattu",
      PUMP_TRUCK_FORM_ADDED: "Pumppauspöytäkirja täytetty",
      CONCRETE_PUMP_TRUCK_PHOTO_ADDED: "Betonilähete kuvattu",
      CONCRETE_PUMP_TRUCK_FORM_ADDED: "Betonilähete täytetty",
      CONCRETE_PUMP_TRUCK_START_PUMPING:"Pumppaus aloitettu",
      CONCRETE_PUMP_TRUCK_STOP_PUMPING: "Pumppaus lopetettu",
      CAST_INTERESET_MARK: "Cast Intereset Mark",
      INTEREST_GIVEN_FROM_WHERE: "Interest given from where",
      WHO_GAVE_THE_INTEREST: "who gave the interest",
      SURFACE_QUALITY_REQUIREMENT: "Surface quality requirement",
      AFTERCARE: "Aftercare",
      AFTERCARE_FROM_CUSTOMER: "Aftercare from customer",
      WEATHER: "Sää",
      RISKS_THAT_WEAKEN_THE_FINAL_RESULT: "Lopputulosta heikentävät riskit",
      RISK_STATED: "Riskit ilmoitettu",
      RISKS_STATED_TO_WHOM: "Kenelle",
      REPAIRS_DONE: "Korjaukset tehty",
      REPAIRS_DONE_WHO_FIXED_IT: "Kuka korjannut",
      PROCEEDINGS_AND_COVERINGS: "Pöytäkirjata ja lähetteet",
      SQUARE_DISTRIBUTE_TO_PERSON: "Neliöt jaettu työntekijöille",
      HOURS_DISTRIBUTE_TO_PERSON: "Tunnit jaettu työntekijöille",
      INDIVIDUAL_HOURS: "Yksilölliset tunnit",
      PERIOD_HOURS: "Jaksoaika",
      CHOOSE: "Valitse",
      YES: "Kyllä",
      NO: "Ei",
      DELIVERED: "Toimitettu",
      CHECKPOINTS: "Tarkastuskohteet",
      UNDERGONE_STRUCTURAL: "Pumppuautolle on suoritettu rakenteelliset tarkastukset (sis. puomin sylinterit ja hyd. letkut)",
      TWELVE_MONTH_INSPECTIONS: "12 kuukauden tarkastukset",
      RE_INSPECTION: "Uusintatarkastus",
      SHORTCOMINGS_AND_ERRORS_HAVE_BEEN_CORRECTED: "Puutteet ja viat on korjattu",
      THE_PUMP_TRUCK_COMES_WITH_THE_NECESSARY_DOCUMENTS: "Pumppuauton mukana on tarvittavat käyttö- ja huolto-ohjeet sekä muut tarvittavat asiapaperit",
      THE_CONDITION_OF_THE_SUPPLY_PIPING: "Syöttöputkiston kunto",
      VISUAL_CONTACT_WITH_THE_CASTING_TARGET: "Näköyhteys valukohteeseen",
      SAFE_PERFORMANCE_OF_PUMPING_WORK: "Seuraavat asiat on oltava pumppaustyön turvallisen suorittamisen edellyttämässä kunnossa:",
      ASSISTIVE_DEVICES: "Tukemislaitteet",
      PLACE_OF_USE: "Käyttöpaikka",
      GROUND_BASE: "Työalustan maapohja",
      PLATFORM_STABILITY: "Alustan vakaus",
      POWER_LINES_AND_WIRES: "Sähkölinjat- ja johdot. Huom! Suojaetäisyydet.",
      TRENCHES: "Kaivannot",
      TRAFFICKED_AREAS: "Liikennöidyt alueet / Liikennöitävät alueet",
      WEATHER_CONDITIONS: "Sääolosuhteet",
      CHECKS_LINE_PUMPING: "Linjapumppaukseen liittyvät tarkistukset",
      SAFETY_AND_PROTECTION_DISTANCES_FOR_LINE_PUMPING: "Linjapumppauksen turva- ja suojaetäisyydet",
      CONSIDERATION_OF_OTHER_MACHINES: "Muiden työkoneiden huomioiminen",
      WORKING_HOURS: "Työaika",
      ADD_FLOORMAN_PROTOCOL: "Täytä lattiapöytäkirja",
      ADD_FLOORMAN_COVER: "Lisää lattiapöytäkirja",
      FLOORMAN_PROTOCOL: "Lattiapöytäkirja",
      ADD_PUMPTRUCK_PROTOCOL: "Add Pumptruck Protocol",
      ADD_PUMPTRUCK_COVER: "Add Pumptruck Cover",
      ADD_PUMPTRUCK_MOUNTING_COVER: "Add Pumptruck Mounting Cover",
      ADD_CONCRETE_COVER: "Add Concrete Cover",
      ADD_CON_PUMP_FACTORY_COVER: "Add Factory Cover",
      ADD_CON_PUMP_COVER: "Add Concrete Pumptruck Cover",
      ADD_CON_PUMP_MOUNTING_COVER: "Add Concrete Pumptruck Mounting Cover",
      ADD_CON_PUMP_CONCRETE_COVER: "Add Concrete Cover",
      ADD_CON_PUMP_RETURN_FREIGHT_COVER: "Add Return Freight Cover",
      NOTES_ON_DELIVERY: "Huomautuksia toimituksesta",
    },
    ATTACHMENT: {
      ATTACHMENT_INFO: "Liitteet",
    },
    OFFER_DETAILS: {
      OFFER_BASIC_INFO: "Tarjouksen perustiedot",
      OFFER_CONTENT: "Tarjoussisältö",
      OFFER_CONTENT_PREVIEW: "Tilausvahvistuken sisältö",
      TAX_STATUS: "Veron tila",
    },
    VEHICLE: {
      EDIT_VEHICLE: "Muokkaa kalustoa",
      ADD_VEHICLE: "Lisää kalustoa"
    },
    CREW: {
      ADD_CREW: "Lisää miehistö",
      EDIT_CREW: "Muokkaa miehistöä"
    }
  },
};
