export const message = {
  COMMON: {
    SUCCESS: "Success",
    ERROR: "Error",
    RECORD_DELETED: "Record deleted successfully",
    SOMETHING_WENT_WRONG: "Oops! Something went wrong, please try again.",
    CUSTOM_FIELDS: {
      EMPTY_FIELD_ERROR: "Please enter field value",
      DUPLICATE_CHOICE: "Duplicate choices found",
    },
    CONFIRM_DELETE: "Are you sure",
    DATA_LOADING_ISSUE: 'Unable to load data.',
    INVALID_INPUT: "Invalid input",
    INVALID_AMOUNT: "Invalid amount",
    UNABLE_TO_GET_CONTRACT_PRICES: "Unable to get contract prices",
    PLEASE_SELECT_A_PROPERTY: "Please select a property",
    FAILED_TO_GET_GLOBAL_FIELD_FOR_FACTORY: "Failed to get global fields for the factory, please retry!",
    ERROR_WHILE_GETTING_FACTORY_DETAILS: "Error while getting factory details"
  },
  AUTH: {
    PASSWORD_NOT_MATCH: "Password doesn't match",
    INVALID_EMAIL: "Invalid email address",
  },
  LISTING: {
    SURE_TO_DELETE: "Are you sure want to delete this record?",
  },
  FORM_ERROR: {
    REQUIRED: "This field is required",
    MAX_LENGTH: "Max length exceeded",
    MIN_LENGTH: "Input is too short",
    PHONE_LENGTH: "Please enter a valid numbers",
  },
  ADD_FILE: {
    FILE_TYPE_ERROR: "File type not supported",
    MIN_FILE_ERROR: "Please select at least one file to upload",
  },
  CUSTOM_FIELDS: {
    DELETE_CONF: "Are you sure?",
    SOMETHING_WRONG: "Oops! Something went wrong, please try again later.",
    ONE_FILE_UPLOAD: "1 file in queue",
    SUCCESS: "Custom field deleted successfully",
  },
  CATEGORY: {
    EMPTY_FIELD: "Please enter a category name",
  },
  EMPTY_FIELD: {
    NAME: "Please enter a name",
    STATUS: "Please enter a status name",
    TYPE: "Please enter a type name"
  },
  AGE: {
    EMPTY_FIELD: "Please enter age name",
  },
  COMPANYINFO_TABLE:{
    COMPANY_NAME_IS_REQUIRED: "Company name is required",
    COMPANY_TITLE_IS_REQUIRED: "Company title is required",
    COMPANY_ADDRESS_IS_REQUIRED: "Company address is required",
    COMPANY_ZIP_IS_REQUIRED: "Company zip is required",
    COMPANY_POSTAL_CODE_IS_REQUIRED: "Company postal code is required",
    COMPANY_WWW_IS_REQUIRED: "Company WWW is required",
    COMPANY_LOGO_IS_REQUIRED: "Company logo is required",
    COMPANY_EMAIL_IS_REQUIRED: "Company email is required",
    COMPANY_PHONE_NUMBER_IS_REQUIRED: "Company phone number is required",
    COMPANY_ID_IS_REQUIRED: "Company ID is required",
    COMPANY_TAX_ID_IS_REQUIRED: "Company Tax ID is required",
    COMPANY_OVT_ID_IS_REQUIRED: "Company OVT ID is required",
    COMPANY_BANK_IS_REQUIRED: "Company bank is required",
    COMPANY_BANK_ACCOUNT_IS_REQUIRED: "Company bank account is required",
    COMPANY_BIC_IS_REQUIRED: "Company BIC is required",
    COMPANY_THEME_IS_REQUIRED: "Company theme is required",
  },
  USER_TABLE: {
    INVALID_EMAIL: "Invalid email address",
    VALIDATE_PASSWORD: "Password should more than 6 characters",
    PHONE_NUM_VALIDATE: "Phone number should more than 10 characters",
    PHONE_NUM_PATTERN: "Please enter a valid phone number",
    PASSWORD_VALIDATE_ERROR: "Password should more than 6 characters",
    USER_NOTFOUND: "User not found",
    JOB_DESCRIPTION_REQUIRED: "Please select job description",
    FIRSTNAME_IS_REQUIRED: "Firstname is required",
    LASTNAME_IS_REQUIRED: "Lastname is required",
    PERSONAL_ID_IS_REQUIRED: "Personal ID is required",
    COMPANY_NAME_IS_REQUIRED: "Company Name is required",
    COMPANY_ID_IS_REQUIRED: "Company ID is required",
    PHONE_NUMBER_IS_REQUIRED: "Phone number is required",
    EMAIL_IS_REQUIRED: "Email is required",
    REGISTRATION_NUMBER_IS_REQUIRED :"D&B Registration Number is required",
    SEGMENTS_ARE_REQUIRED: "Segments are required",
    WORK_PHONE_IS_REQUIRED: "Work phone is required",
    ROLE_IS_REQUIRED: "Role is required",
    STATUS_IS_REQUIRED: "Status is required",
  },
  SUPPLIER_INFO: {
    PHONE_NUM_PATTERN_ERROR: "Please enter a valid phone number",
    EMAIL_PATTERN_ERROR: "Invalid email address",
    ZIP_CODE_VALIDATE: "Please enter only numbers in zip code",
    ENTER_STATUS: "Please enter a status",
    SUPPLIER_REQUIRED: "Supplier is required",
    STATUS_REQUIRED: "Status is required",
  },
  CHARACTERSTIC_INFO: {
    ENTER_CHARACTERSTIC: "Please Enter characteristic",
  },
  FACTORY: {
    NO_FACTORY_MSG: "No factories added",
    PHONE_PATTERN_ERROR: "Please enter a valid phone number",
    INVALID_EMAIL_ERROR: "Invalid email address",
    DUPLICATE_FACTORY_NAME: "Duplicate Factory Name",
    noFactorShow: 'No factories to show',
    NOFACTORIESAVAILABLE: "No factories available",
    NAME_REQUIRED:"Name is required",
    ADDRESS_REQUIRED:"Address is required",
    EMAIL_REQUIRED:"Email is required",
    ZIPCODE_REQUIRED:"Zipcode is required",
    POSTAL_DISTRICT_REQUIRED:"Postal district is required",
  },
  CONTACT_PERSON: {
    PHONE_PATTERN_ERROR: "Please enter a valid phone number",
    INVALID_EMAIL_ERROR: "Invalid email address",
    DUPLICATE_PERSON: "Phone number already in use",
    EMAIL_REQUIRED:"Email is required",
    FIRST_NAME_REQUIRED:"First name is required",
    LAST_NAME_REQUIRED:"Last name is required",
  },
  DELIVERY: {
    INVALID_BATCHES_PROVIDED: "Invalid batches provided!",
    INVALID_AMOUNT_PROVIDED: "Invalid amount provided!",
    CHOOSE_VEHICLE: "Please choose vehicle",
    CHOOSE_DRIVER: "Please choose driver",
    SELECT_SCHEDULE_DATE_AND_TIME: "Please select schedule date and time",
    INVALID_SCHEDULE_DATE_AND_TIME: "Invalid schedule date and time",
    PRODUCT_TYPE_IS_REQUIRED: "Product type is required",
    PRODUCT_IS_REQUIRED: "Product is required",
    SELECT_LEAST_CREW_FOR_FLOORING: "Please select at least 1 crew for flooring",
    CUSTOMER_NAME_IS_REQUIRED: "Customer name is required",
    ORDER_STATUS_IS_REQUIRED: "Order status is required",
    SELLER_IS_REQUIRED: "Seller is required",
    CONTACT_PERSON_IS_REQUIRED: "Contact person is required",
    PROPERTY_IS_REQUIRED: "Property is required",
    TYPE_IS_REQUIRED: "Type is required",
    VEHICLE_IS_REQUIRED: "Vehicle is required",
    DRIVER_IS_REQUIRED: "Driver is required",
    START_DATE_IS_REQUIRED: "Start Date is required",
    END_DATE_IS_REQUIRED: "End Date is required",
    START_TIME_IS_REQUIRED: "Start Time is required",
    END_TIME_IS_REQUIRED: "End Time is required",
    REACH_FACTORY_DATE_IS_REQUIRED: "Reach Factory Date is required",
    REACH_FACTORY_TIME_IS_REQUIRED: "Reach Factory Time is required",
    REACH_FACTORY_DISTANCE_IS_REQUIRED: "Reach Factory Distance is required",
    START_LOADING_VEHICLE_DATE_IS_REQUIRED: "Start Loading Vehicle Date is required",
    START_LOADING_VEHICLE_TIME_IS_REQUIRED: "Start Loading Vehicle Time is required",
    STOP_LOADING_VEHICLE_DATE_IS_REQUIRED: "Stop Loading Vehicle Date is required",
    STOP_LOADING_VEHICLE_TIME_IS_REQUIRED: "Stop Loading Vehicle Time is required",
    DRIVE_DESTINATION_DATE_IS_REQUIRED: "Drive Destination Date is required",
    DRIVE_DESTINATION_TIME_IS_REQUIRED: "Drive Destination Time is required",
    REACH_DESTINATION_DATE_IS_REQUIRED: "Reach Destination Date is required",
    REACH_DESTINATION_TIME_IS_REQUIRED: "Reach Destination Time is required",
    REACH_DESTINATION_DISTANCE_IS_REQUIRED: "Reach Destination Distance is required",
    START_UNLOADING_VEHICLE_DATE_IS_REQUIRED: "Start Unloading Vehicle Date is required",
    START_UNLOADING_VEHICLE_TIME_IS_REQUIRED: "Start Unloading Vehicle Time is required",
    STOP_UNLOADING_VEHICLE_DATE_IS_REQUIRED: "Stop Unloading Vehicle Date is required",
    STOP_UNLOADING_VEHICLE_TIME_IS_REQUIRED: "Stop Unloading Vehicle Time is required",
    STOP_UNLOADING_VEHICLE_JACKSHOTS_IS_REQUIRED: "Stop Unloading Vehicle Jackshots is required",
    STOP_UNLOADING_VEHICLE_DISTANCE_IS_REQUIRED: "Stop Unloading Vehicle Distance is required",
    FINISH_DELIVERY_DATE_IS_REQUIRED: "Finish Delivery Date is required",
    FINISH_DELIVERY_TIME_IS_REQUIRED: "Finish Delivery Time is required",
    STOP_DELIVERY_DISTANCE_IS_REQUIRED: "Stop Delivery Distance is required",
    CONCRETE_PUMPTRUCK_START_PUMPING_DATE_IS_REQUIRED: "Start Pumpping Date is required",
    CONCRETE_PUMPTRUCK_START_PUMPING_TIME_IS_REQUIRED: "Start Pumpping Time is required",
    CONCRETE_PUMPTRUCK_STOP_PUMPING_DATE_IS_REQUIRED: "Stop Pumpping Date is required",
    CONCRETE_PUMPTRUCK_STOP_PUMPING_TIME_IS_REQUIRED: "Stop Pumpping Time is required",
    CONCRETE_PUMPTRUCK_PHOTO_ADDED_DATE_IS_REQUIRED: "Pumptruck Photo Added Date is required",
    CONCRETE_PUMPTRUCK_PHOTO_ADDED_TIME_IS_REQUIRED: "Pumptruck Photo Added Time is required",
    PUMPTRUCK_START_MOUNTING_DATE_IS_REQUIRED: "Start Mounting Date is required",
    PUMPTRUCK_START_MOUNTING_TIME_IS_REQUIRED: "Start Mounting Time is required",
    PUMPTRUCK_PHOTO_ADDED_DATE_IS_REQUIRED: "Photo Added Date is required",
    PUMPTRUCK_PHOTO_ADDED_TIME_IS_REQUIRED: "Photo Added Time is required",
    PUMPTRUCK_FORM_ADDED_DATE_IS_REQUIRED: "Form Added Date is required",
    PUMPTRUCK_FORM_ADDED_TIME_IS_REQUIRED: "Form Added Time is required",
    PUMPTRUCK_START_PUMPING_DATE_IS_REQUIRED: "Start Pumpping Date is required",
    PUMPTRUCK_START_PUMPING_TIME_IS_REQUIRED: "Start Pumpping Time is required",
    PUMPTRUCK_STOP_PUMPING_DATE_IS_REQUIRED: "Stop Pumpping Date is required",
    PUMPTRUCK_STOP_PUMPING_TIME_IS_REQUIRED: "Stop Pumpping Time is required",
    FLOORMAN_START_WORKING_DATE_IS_REQUIRED: "Start Working Date is required",
    FLOORMAN_START_WORKING_TIME_IS_REQUIRED: "Start Working Time is required",
    FLOORMAN_STOP_WORKING_DATE_IS_REQUIRED: "Stop Working Date is required",
    FLOORMAN_STOP_WORKING_TIME_IS_REQUIRED: "Stop Working Time is required",
    FLOORMAN_START_SEPERATE_HOURLY_WORK_DATE_IS_REQUIRED: "Start Seperate Hourly Work Date is required",
    FLOORMAN_START_SEPERATE_HOURLY_WORK_TIME_IS_REQUIRED: "Start Seperate Hourly Work Time is required",
    FLOORMAN_STOP_SEPERATE_HOURLY_WORK_DATE_IS_REQUIRED: "Stop Seperate Hourly Work Date is required",
    FLOORMAN_STOP_SEPERATE_HOURLY_WORK_TIME_IS_REQUIRED: "Start Seperate Hourly Work Time is required",
    FLOORMAN_START_WAITING_TIME_DATE_IS_REQUIRED: "Start Waiting Date is required",
    FLOORMAN_START_WAITING_TIME_TIME_IS_REQUIRED: "Start Waiting Time is required",
    FLOORMAN_STOP_WAITING_TIME_DATE_IS_REQUIRED: "Stop Waiting Date is required",
    FLOORMAN_STOP_WAITING_TIME_TIME_IS_REQUIRED: "Stop Waiting Time is required",
  },
  VEHICLES: {
    BASIC: {
      UPDATING_MSG: "Uploading...",
      INVALID_INPUT: "Invalid input",
      YEAR_INVALID: "Please enter a year in model year",
      REGISTER_NUMBER_REQUIRED: "Register number is required",
      MODEL_REQUIRED: "Model is required",
      TYPE_REQUIRED: "Type is required",
      VEHICLE_OR_DRIVER_ALREADY_ASSIGNED: "Vehicle or Driver already assigned",
      FLOORMEN_ALREADY_ASSIGNED: "Floormen already assigned"
    },
    DRIVER: {
      NO_DRIVER_MSG: "No drivers added",
      NO_DRIVER_MSG: "No drivers added",
      INVALID_EMAIL_ERROR: "Invalid email address",
      PASSWORD_VALIDATE_ERROR: "Password should more than 6 characters",
      PHONE_NUM_PATTERN_ERROR: "Please enter a valid phone number",
      PHONE_VALIDATE_ERROR: "Phone number should more than 10 characters",
    },
    CAPACITY: {
      INVALID_INPUT: "Invalid input",
    },
    INSTRUCTIONS: {
      UPDATING_MSG: "Uploading...",
      NO_FILES_MSG: "No files added",
    },
    MANAGE_CATEGORY: {
      ENTER_CATEGORY: "Please enter a category",
      CATEGORY_REQUIRED: "Category name is required",
    },
    MANAGE_TYPE: {
      ENTER_TYPE: "Please enter a type",
    },
  },
  CRON_TAB: {
    ADD_CRON: {
      MINUTE_VALIDATE_ERROR: "Please enter a valid minute",
      HOUR_VALIDATE_ERROR: "Please enter a valid hour",
      DAY_VALIDATE_ERROR: "Please enter a valid day",
      MONTH_VALIDATE_ERROR: "Please enter a valid month",
      WEEK_VALIDATE_ERROR: "Please enter a valid week",
      CRON_JOB: "Cronjob",
      UPDATED: "updated",
      ADDED: "added",
      SUCCESS: "successfully",
      EDIT: "Edit",
      ADD: "Add",
      NEW_CRON_JOB: " New Cron Job",
    },
    CONFIRM_MSG: "Are you sure?",
  },
  FILE: {
    DELETE_CONF: "Are you sure want to delete?",
    UNDONE_MSG: "This action cannot be undone.",
    EMPTY_FILE_CATEGORY: "Please enter a file category",
    FILE_PREVIEW_ERROR: "Something went wrong previewing this file, please retry.",
    FILE_UNABLE_PREVIEW: "Unable to load the preview for this file.",
    REMOVE_FILE_CONF: "Remove file?",
    ONE_FILE_UPLOAD: "1 file uploaded",
    NAME_CHANGE_SUCCESS: "File name changed successfully",
    NAME_REQUIRED: "File name is required",
    NO_TITLE: "No title specified",
    DELETE_SUPPLIER: "Are you sure want to delete this supplier from the list? prices added for this supplier will be deleted.",
  },
  NOTES: {
    SCHEDULE_DATE_TIME_ERROR: "Please choose scheduled date and time",
    ENTER_DESCRYPTION_ERROR: "Please enter description",
    SCHEDULE_TIME_PAST_ERROR: "Scheduled time cannot be in past",
    REPLY_MESSAGE_ERROR: "Please enter a message to reply",
    SOMEONE_DELETED_NOTE: "Someone deleted their reply in note:",
    NO_NOTES: "No notes to show",
    ENTER_CATEGORY: "Please enter a note category",
    NO_FILE_CHOOSEN: "No file chosen",
    NOTE: "Note",
    HAS_DELETED: "has been deleted",
    NO_REPLY_RECEIVED: "New reply received in note - ",
    CLOSE_ADD_NOTE_DIALOG: "Please close add note dialog first.",
    SOMETHING_WRONG: "Oops! Something went wrong, please try again.",
    OPENED_NOTE: "opened your note",
    VIEW_ALL_MESSAGES: "View all messages",
    ADDED_SUCCESS: "Note added successfully",
    error: {
      scheduledTimeRequired: 'Please select scheduled time.'
    }
  },
  ASIDE: {
    LANGUAGE_UPDATE_SUCCESS: "Language updated",
    LANGUAGE_UPDATE_FAILED: "Oops! Something went wrong, please try again.",
  },
  DATA_TABLE: {
    RETRY_MSG: "Something went wrong, please retry.",
    INVALID_FILE: "Invalid file specified",
    SELECT_FILE: "Please select a file to import",
  },
  PRODUCT: {
    manageStocks: {
      stockLessThanAlarmLimit: "Stock need to update.",
      wareHouseNameRequired: "Warehouse name is required.",
      invalidStockQuantity: "It should be more than 0 and a number.",
      invalidAlarmLimitQuantity: "It should be more than 0 and a number.",
      duplicateWearHouseName: "Warehouse name already exist with us.",
    },
    createNew: {
      categoryRequired: "Please select a category.",
      unitRequired: "Please select at least 1 unit.",
      supplierRequired: "Please select at least 1 supplier.",
      characteristicsRequired: (name) =>
        `Please select characteristics for ${name} in Product Characteristics`,
    },
    pricesCharacteristicsCleared: 'All the prices added for this characteristic will be cleared.',
    warning: {
      selectMinOneDay: 'Please select at least 1 day from weekday.',
      purchasePriceCompareSellPrice: 'Purchase price cannot be greater than selling price.',
      dailyPriceTimeNotValid: 'Please enter valid End Time.'
    },
    error: {
      propertyRequired: 'Please enter a property type.',
      unitRequired: 'Please enter a unit.',
      taxRequired: 'Please enter a tax.',
      taxValidation: 'Please enter valid value.',
      characteristicRequired: 'Please enter a characteristic name.'
    },
    productAvailableSupplierFactory: 'Product Available at suppliers factories',
    noPricesAdded: "No prices added",
    noProductsAdded: "No products added",
    PLEASE_SELECT_TAX_PERCENTAGE: "Please select tax percentage",
    FAILED_TO_REFRESH_FACTORIES_LIST: "Failed to refresh factories list"
  },
  OFFER: {
    warning: {
      groupNameExist: 'Group with same name is already added.',
      addPreviousProduct: 'Please add previous product data to add a new record.'
    },
    error: {
      numberNotFound: 'Offer number not found',
      groupNameRequired: 'Please enter a group name.',
      notFound: 'No offer found with the details provided.',
      fillInformation: 'Please fill offer information',
      fillPropertyInformation: 'Please fill property information',
      customerPropertyRequired: 'Please select customer property.',
      PROPERTY_FULL_ADDRESS_REQUIRED: 'Please enter full address',
      PROPERTY_ZIPCODE_REQUIRED: 'Please enter zipcode',
      PROPERTY_POSTAL_ADDRESS_REQUIRED: 'Please enter postal address',
    },
    info: {
      copyProcess: 'Copying offer, please wait...',
      acceptSuccess: "Offer accepted successfully",
      rejectSuccess: "Offer rejected successfully",
      updatedSuccess: "Offer updated successfully",
      createdSuccess: "Offer created successfully",
    },
    SELECT_OPTION_ROW: "Please select option from list",
    ADD_ONE_GROUP_IN_OFFER_CONTENT: "Please add at least 1 group in offer content",
    NO_PRODUCT_LIST_OR_EDITOR_FOUND: "No product list or editor found inside",
    EMPTY_PRODUCT_LIST_FOUND_IN_GROUP: "Empty product list found in group",
    EMPTY_PRODUCT_RECORD_FOUND_IN_GROUP: "Empty product record found in group",
    OFFER_STATUS_IS_REQUIRED: "Offer status is required",
    TAX_STATUS_IS_REQUIRED: "Tax status is required"
  },
  BRAND: {
    error: {
      brandRequired: 'Please enter a brand.'
    }
  },
  EMAIL: {
    success: {
      sent: 'Email(s) sent successfully.'
    },
    error: {
      offerSendingIssue: 'An error occurred while sending the offer to the customer, please try again.'
    }
  },
  CUSTOMER: {
    error: {
      basicInformationRequired: 'Please add customer basic information.',
      billingInformationRequired: 'Please add customer billing information.'
    },
    warning: {
      previousDataBasicInformationCleared: 'Are you sure? Your previous data basic information data will be cleared.'
    },
    success: {
      creditInformation: 'Credit information retrieved.'
    }
  },
  SUPPLIER: {
    noSuppliersAdded: 'No suppliers added'
  },
  ORDER: {
    ORDER_UPDATED_SUCCESSFULLY: "Order updated successfully",
    ORDER_CREATED_SUCCESSFULLY: "Order created successfully",
    ORDER_DELETED_SUCCESS: "Order deleted successfully",
    PLEASE_FILL_ORDER_INFORMATION: "Please fill Order information",
    PLEASE_SELECT_CUSTOMER_PROPERTY: "Please select customer property",
    PLEASE_SELECT_DELIVERY_AREA_FOR_PRODUCT: "Please select delivery area for product",
    AMOUNT_OF_PRODUCT_SHOULD_BE_GREATER_THAN_0: "Amount of product should be greater than 0",
    PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_IN_YOUR_DELIVERY: "Please select at least one product in your delivery",
    PLEASE_SELECT_DRIVER_FOR_VEHICLE: "Please select driver for vehicle",
    NO_ORDER_FOUND_WITH_THE_DETAILS_PROVIDED: "No order found with the details provided",
    CONSIGNMENT_DETAILS_NOT_FOUND: "Consignment details not found",
    ONE_VEHICLE_IS_REQUIRED: "One vehicle is required",
    ONE_CREW_IS_REQUIRED: "One crew is required",
    PLEASE_SELECT_CREW: "Please select crew",
    PLEASE_CHOOSE_FACTORY: "Please choose factory",
    DELIVERY_CREATED: "Delivery created",
    INVALID_SCHEDULED_DATE_AND_TIME: "Invalid scheduled date and time",
    PLEASE_SELECT_HOW_TO_PAY: "Select payment option",
    PLEASE_SELECT_REPORTAGE: "Please select at least one reportage member",
    ORDER_COPY_SUCCESSFULLY: "Order copied successfully",
  },
  INVOICE: {
    NO_INVOICE_FOUND: "No invoice found with the details provided.",
    FILL_FIRST_PAYMENT_INFO: "Please fill first payment information.",
    FILL_FIRST_PRODUCT_INFO: "Please fill first product information.",
    INVOICE_UPDATED_SUCCESS: "Invoice updated successfully",
    INVOICE_CREATED_SUCCESS: "Invoice created successfully",
    INVOICE_DELETED_SUCCESS: "Invoice deleted successfully",
    UNDONE_MSG: "This action cannot be undone.",
    FETCHING_DETAILS : "Fetching details...",
    LOADING_YOUR_ORDER: "Loading your order",
    INVOICE_STATUS_REQUIRED: "Invoice status is required",
    ORDER_NUMBER_REQUIRED: "Order number is required",
    CUSTOMER_PROPERTY_REQUIRED: "Customer property is required",
    SELLER_REQUIRED: "Seller is required",
    PLEASE_ENTER_VALID_NUMBER: "Please enter valid number.",
    VALUE_GREATER_THAN_ZERO: "Value should be greater than 0.",
    VALUE_LESS_THAN_HUNDRED: "Discount value should less than 100.",
    NETVISOR_CONF: "Invoice data send to NetVisor?",
  }
};
