import { constants, TABS } from '../constants'

const initialState = {
    firstRender: true,
    language: 'en-US',
    loader: false,
    fileIdsInCrewList: [],
    routeDetails: {
        module_id: Number(),
        module_name: '',
        parent: '',
        panel: '',
        shortcode: ''
    },
    notifications: [],
    companyinfo: {},
    activeTab: { TABS: TABS.MENU },
    activeSidebar: false,
    alertProps: {
        open: false
    },
    showEditFirstName: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case constants.APP.SIDEBAR_ACTIVE:
            return {
                ...state,
                activeSidebar: action.payload
            }
        case constants.APP.CHANGE_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload
            }
        case constants.CHANGE_LANUGAGE:
            return {
                ...state,
                language: action.payload.language
            }
        case constants.TOGGLE_LOADER:
            return {
                ...state,
                loader: true
            }
        case constants.HIDE_LOADER:
            return {
                ...state,
                loader: false
            }
        case constants.SAVE_FILES_TO_CREW_LIST:
            return {
                ...state,
                fileIdsInCrewList: action.payload
            }
        case constants.APP.UPDATE_ROUTES:
            return {
                ...state,
                routeDetails: action.payload
            }
        case constants.APP.FIRST_RENDER_UPDATE:
            return {
                ...state,
                firstRender: false
            }
        case constants.APP.STORE_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload.notifications
            }
        case constants.APP.STORE_COMPANYINFO:
            return {
                ...state,
                companyinfo: action.payload
            }
        case constants.APP.OPEN_ALERT:
        return {
                ...state,
                alertProps: {
                    open: true,
                    ...action.payload
                }
            }
        case constants.APP.CLOSE_ALERT:
            return {
                ...state,
                alertProps: {
                    open: false
                }
            }
        case constants.APP.SHOW_EDIT_DATA:
            return {
                ...state,
                showEditFirstName: {
                    ...state.showEditFirstName,
                    ...action.payload
                }

            }
        default:
            return {
                ...state
            }
    }
}