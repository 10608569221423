import React, { useEffect } from 'react'
import MainRouter from './scripts/router/Router';
import './styles/global.scss'
import './styles/style.css'
import { connect } from 'react-redux'
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter } from 'react-router-dom';
import { SocketContext, socket } from './scripts/context/sockets';

function App(props) {
  return (
    <SocketContext.Provider value={socket}>
      <BrowserRouter>
        <MainRouter />
      </BrowserRouter>
    </SocketContext.Provider>
  );
}

export default connect()(App)