import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { hide_alert } from "../../redux/actions/appActions";
import { isEqual } from "lodash";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import { getLocalizedStrings } from "../../multilingual/GetMessagesMultilingual";

const CustomAlert = (props) => {
  const app = useSelector((state) => state.app, isEqual);

  const [loading, setLoading] = useState(false);
  const [fullWidth] = useState(true);
  const [maxWidthXs] = useState("xs");
  const [logoClass, setLogoClass] = useState("icon-failure-red");

  useEffect(() => {
    if (app.alertProps.type === "success") {
      setLogoClass("icon-success");
    } else if (app.alertProps.type === "warning") {
      setLogoClass("icon-warning");
    } else if (app.alertProps.type === "info") {
      setLogoClass("icon-info");
    } else if (app.alertProps.type === "danger") {
      setLogoClass("icon-failure-red");
    } else if (app.alertProps.type === "dangerplus") {
      setLogoClass("icon-failure-red");
    }
    if (!app.alertProps.open) {
      setLoading(false);
    }
  }, [app.alertProps.open]);

  const onSubmitData = async () => {
    setLoading(true);
    await app.alertProps.submitCallback(app.alertProps.submitParam);
    setLoading(false);
    props.hide_alert();
  };

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidthXs}
        open={app.alertProps.open}
        onClose={(_e, reason) => {
          if (reason !== "backdropClick") {
            props.hide_alert();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="MuiDialogBody MuiDialogAlert text-center">
              <div className="mb-3">
                <span className={`icon-size ${logoClass} icon-64`}></span>
              </div>
              <h2 className="body-l">{app.alertProps.message}</h2>
              {(app.alertProps.hasOwnProperty("subMessage") && (
                <p>{app.alertProps?.subMessage}</p>
              )) || <></>}
              <div className="pt-2 mb-3">
                <button
                  type="button"
                  onClick={props.hide_alert}
                  className={`btn ${app.alertProps.type === 'dangerplus' ? 'btn-warning': 'btn-outline-success'} btn-sm`}
                  >
                  {getLocalizedStrings().label.FILE.CANCEL}
                </button>
                {(app.alertProps.hasOwnProperty("submitCallback") &&
                  "function" === typeof app.alertProps?.submitCallback && (
                    <button
                      type="button"
                      onClick={() => onSubmitData()}
                      className={`btn ${app.alertProps.type === 'dangerplus' ? 'btn-success': 'btn-danger'} btn-sm ms-2`}
                      disabled={loading}
                    >
                      {!loading ? (
                        app.alertProps.submitLabel
                      ) : (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          {getLocalizedStrings().label.FORM.PLEASE_WAIT}
                        </>
                      )}
                    </button>
                  )) || <></>}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

const MapDispatchToProps = (dispatch) => ({
  hide_alert: (payload) => dispatch(hide_alert(payload)),
});

export default connect(null, MapDispatchToProps)(CustomAlert);
