import { PlaylistAddOutlined } from "@mui/icons-material";
import { updateRoutes, show_edit_firstname } from "../redux/actions/appActions";
import Store from "../redux/store";

let store = {}
Store.subscribe(() => {
    store = Store.getState()
})

const customPaths = ['custom-fields']

export const routerPositionHelper = (pathnames, query, customPanel = undefined) => {

    const accessData = store.auth.isUserLoggedIn ? JSON.parse(store.auth.access) : [];

    let allModules = [].concat.apply(
        [],
        Object.keys(accessData).map((m) => accessData[m])
    );
    let currentModule = allModules.filter(
        (f) => f.shortcode.toLowerCase() === pathnames[2]
    );

    if (!(pathnames[4])) {
        Store.dispatch(show_edit_firstname({
            name: '',
            subModule: '',
            subModuleValue: ''
        }))
    }

    if (currentModule.length) {
        Store.dispatch(updateRoutes({
            module_id: currentModule[0].manager_id,
            module_name: currentModule[0].manager_name,
            parent: currentModule[0].parent,
            shortcode: currentModule[0].shortcode.toLowerCase(),
            panel: customPanel ? customPanel : getPanelName(currentModule[0].shortcode.toLowerCase(), query),
        }))
    } else {
        const routeDetails = store.app.routeDetails
        if (routeDetails?.module_id && !customPaths.includes(pathnames[1])) {
            customPanel && Store.dispatch(updateRoutes({
                module_id: routeDetails.module_id,
                module_name: routeDetails.module_name,
                parent: routeDetails.shortcode,
                shortcode: null,
                panel: customPanel,
            }))
        } else {
            Store.dispatch(updateRoutes({
                module_id: 0,
                module_name: '',
                parent: '',
                shortcode: null,
                panel: getPanelByPath(),
            }))
        }
    }
};

const getPanelByPath = () => {
    return window.location.pathname.replace('/', '').replace('-', '_')
}

const getPanelName = (shortcode, query) => {
    if (shortcode === "cre") {
        if (shortcode === "cre" && !query.get("panel")) {
            return "overview";
        } else {
            return query.get("panel") || shortcode;
        }
    } else if (shortcode === "vcl") return "vehicle_basic";
    else if (shortcode === "prd") return "product";
    else if (shortcode === "sup") return "supplier";
    else return query.get("panel") || shortcode;
};
