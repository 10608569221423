import { generateRandomString } from '../../utils/common';
import { constants } from '../constants'

const initialState = {
    loaded: false,
    randomKey: '',
    customerStackData: {},
    isEditMode: false,

    customer_id: '',

    hasCustomerBasicData: false,
    customerBasicData: {},
    basicDataId: '',

    hasCustomerBillingData: false,
    customerBillingData: {},
    selectedCustomerProperty: {},

    contactPersons: [],
    contractPrices: [],
    customerProperties: [],
    customerPropertyTypeList: [],
    column_order_contacts: [],

    hasCreditInformation: true,
    creditInformation: {},
    editorObjects: {}
}

Array.prototype.insert = function (index, item) {
    this.splice(index, 1);
    this.splice(index, 0, item);
};

export default (state = initialState, action) => {
    switch (action.type) {
        case constants.CUSTOMER.CUSTOMER_STACK_DATA:
            const tempToken = generateRandomString(8)
            localStorage.setItem('customer-temp-token', tempToken)
            return {
                ...state,
                loaded: action.payload.loaded,
                customerStackData: action.payload.data,
                randomKey: tempToken,
                column_order_contacts: action.payload.data.column_order_contact.length ? action.payload.data.column_order_contact : []
            }
        case constants.CUSTOMER.ENABLE_EDIT_MODE:
            let creditInformation = {}
            let hasCreditInformation = false
            if (action.payload.hasOwnProperty('credit_information')) {
                creditInformation = action.payload?.credit_information
                if (Object.keys(creditInformation).length) {
                    hasCreditInformation = true
                }
            }
            return {
                ...state,
                loaded: true,
                customer_id: action.payload.id,
                isEditMode: true,
                hasCustomerBasicData: true,
                customerBasicData: action.payload.customer_basic[0],
                basicDataId: action.payload.customer_basic[0].id,
                hasCustomerBillingData: true,
                customerBillingData: action.payload.customer_billing[0],
                contactPersons: action.payload.customer_contact,
                customerProperties: action.payload.customer_property,
                hasCreditInformation,
                creditInformation
            }
        case constants.CUSTOMER.RESET_CUSTOMER_STATE:
            return {
                loaded: false,
                randomKey: '',
                customerStackData: {},
                isEditMode: false,

                customer_id: '',

                hasCustomerBasicData: false,
                customerBasicData: {},
                basicDataId: '',

                hasCustomerBillingData: false,
                customerBillingData: {},
                selectedCustomerProperty: {},

                contactPersons: [],
                contractPrices: [],
                customerProperties: [],
                column_order_contacts: [],
                customerPropertyTypeList: [],

                hasCreditInformation: false,
                creditInformation: {},
                editorObjects: {}
            }
        case constants.CUSTOMER.UPDATE_CONTACT_PERSONS:
            let contactPersons = structuredClone(state.contactPersons)
            if (action.payload.operation === 'add') {
                contactPersons.push(action.payload.person)
            } else if (action.payload.operation === 'update') {
                contactPersons.insert(contactPersons.findIndex(f => f.id === action.payload.person.id), action.payload.person)
            } else if (action.payload.operation === 'delete') {
                contactPersons = contactPersons.filter(f => f.id !== action.payload.personId)
            }
            contactPersons = contactPersons.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
            )
            return {
                ...state,
                contactPersons: [...contactPersons]
            }
        case constants.CUSTOMER.UPDATE_CONTRACT_PRICES:
            let contractPrices = [...state.contractPrices]
            if (action.payload.operation === 'add') {
                contractPrices.push(action.payload.data)
            } else if (action.payload.operation === 'update') {
                contractPrices.insert(contractPrices.findIndex(f => f.id === action.payload.data.id), action.payload.data)
            } else if (action.payload.operation === 'delete') {
                contractPrices = contractPrices.filter(f => f.id !== action.payload.id)
            }
            contractPrices = contractPrices.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
            )
            return {
                ...state,
                contractPrices: [...contractPrices]
            }
        case constants.CUSTOMER.CUSTOMER_BASIC_DATA:
            return {
                ...state,
                hasCustomerBasicData: true,
                basicDataId: action.payload.id,
                customerBasicData: action.payload
            }
        case constants.CUSTOMER.CUSTOMER_COMPANY:
            return {
                ...state,
                hasCustomerBasicData: false,
                basicDataId: state.customerBasicData.id,
                customerBasicData: {}
            }
        case constants.CUSTOMER.CUSTOMER_BILLING_DATA:
            return {
                ...state,
                hasCustomerBillingData: true,
                customerBillingData: action.payload
            }
        case constants.CUSTOMER.UPDATE_CREDIT_INFORMATION:
            return {
                ...state,
                hasCreditInformation: true,
                creditInformation: action.payload
            }
        case constants.CUSTOMER.CUSTOMER_PROPERTIES:
            let customerProperties = [...state.customerProperties];
            if (action.payload.operation === "add") {
                customerProperties.push(action.payload.data);
            } else if (action.payload.operation === "update") {
                customerProperties.insert(
                    customerProperties.findIndex((f) => f.id === action.payload.data.id),
                    action.payload.data
                );
            } else if (action.payload.operation === "delete") {
                customerProperties = customerProperties.filter(
                (f) => f.id !== action.payload.data
                );
            }
            return {
                ...state,
                customerProperties: [...customerProperties],
            };
        case constants.CUSTOMER.SELECTED_CUSTOMER_PROPERTY_CUSTOMER:
            return {
                ...state,
                selectedCustomerProperty: action.payload,
            };          
        case constants.CUSTOMER.UPDATE_EDITOR_OBJECTS_CUSTOMER:
            console.log(action.payload);
            return {
                ...state,
                editorObjects: action.payload,
            };
        default:
            return {
                ...state
            }
    }
}