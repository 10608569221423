import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

import { generateRandomString, sortArrayOfObjects } from "../../utils/common";
import { constants } from "../constants";
import { getLocalizedStrings } from '../../multilingual/GetMessagesMultilingual';
import moment from "moment";

const initialState = {
  mounted: false,
  randomKey: "",
  allData: new Object(),
  isEditMode: false,
  editData: {},

  hasCustomerDetails: false,
  customerDetails: {},

  hasOfferInformationAdded: false,
  offerInformationDetails: {},
  selectedContactPerson: {},

  attachments: [],

  customerProperties: [],
  selectedCustomerProperty: {},

  editorObjects: {},

  offerContent: [],
  countProductAddedInOfferContent: 0,

  offerContentEditorObjects: [],
  offerContentPreviewEditorObjects: [],
};

Array.prototype.insert = function (index, item) {
  this.splice(index, 1);
  this.splice(index, 0, item);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.OFFERS.GET_OFFERS_STACK:
      const tempToken = generateRandomString(8);
      localStorage.setItem("offer-temp-token", tempToken);
      return {
        ...state,
        mounted: true,
        randomKey: tempToken,
        allData: action.payload,
      };
    case constants.OFFERS.SET_OFFER_FOR_CUSTOMER:
      return {
        ...state,
        hasCustomerDetails: true,
        customerDetails: action.payload,
      };
    case constants.OFFERS.RESET_OFFERS_STATE:
      return {
        mounted: false,
        randomKey: "",
        allData: new Object(),
        isEditMode: false,
        editData: {},

        hasCustomerDetails: false,
        customerDetails: {},

        hasOfferInformationAdded: false,
        offerInformationDetails: {},

        attachments: [],

        customerProperties: [],
        selectedCustomerProperty: {},

        editorObjects: {},

        offerContent: [],
        countProductAddedInOfferContent: 0,

        offerContentEditorObjects: [],
        offerContentPreviewEditorObjects: [],
      };
    case constants.OFFERS.SAVE_OFFER_INFORMATION:
      return {
        ...state,
        hasOfferInformationAdded: true,
        offerInformationDetails: action.payload,
      };
    case constants.OFFERS.CUSTOMER_CONTACT_DATA:
      return {
        ...state,
        selectedContactPerson: action.payload,
      };
    case constants.OFFERS.SAVE_ATTACHMENTS:
      let attachments = [...state.attachments];
      if (action.payload.operation === "add") {
        action.payload.data.forEach((f) => {
          attachments.push(f);
        });
      } else if (action.payload.operation === "update") {
        attachments.insert(
          attachments.findIndex((f) => f.id === action.payload.data.id),
          action.payload.data
        );
      } else if (action.payload.operation === "delete") {
        attachments = attachments.filter((f) => f.id !== action.payload.data);
      }
      return {
        ...state,
        attachments: [...attachments],
      };
    case constants.OFFERS.CUSTOMER_PROPERTIES:
      let customerProperties = [...state.customerProperties];
      if (action.payload.operation === "add") {
        customerProperties.push(action.payload.data);
      } else if (action.payload.operation === "update") {
        customerProperties.insert(
          customerProperties.findIndex((f) => f.id === action.payload.data.id),
          action.payload.data
        );
      } else if (action.payload.operation === "delete") {
        customerProperties = customerProperties.filter(
          (f) => f.id !== action.payload.data
        );
      }
      return {
        ...state,
        customerProperties: [...customerProperties],
      };
    case constants.OFFERS.STORE_ALL_CUSTOMER_PROPERTIES:
      return {
        ...state,
        customerProperties: action.payload,
      };
    case constants.OFFERS.CUSTOMER_PROPERTIES_TYPE_IN_OFFER:
      let allDataProps = state.allData;
      if (action.payload.operation === "add") {
        allDataProps.customerPropertyTypeList.push(action.payload.data);
      } else if (action.payload.operation === "update") {
        allDataProps.customerPropertyTypeList.insert(
          allDataProps.customerPropertyTypeList.findIndex((f) => f.id === action.payload.data.id),
          action.payload.data
        );
      } else if (action.payload.operation === "delete") {
        allDataProps.customerPropertyTypeList = allDataProps.customerPropertyTypeList.filter(
          (f) => f.id !== action.payload.data
        );
      }
      return {
        ...state,
        allData: allDataProps,
      };
    case constants.OFFERS.UPDATE_EDITOR_OBJECTS_OFFERS:
      return {
        ...state,
        editorObjects: action.payload,
      };
    /**
     * OFFER CONTENT GROUP ***STARTS***
     */
    case constants.OFFERS.NEW_OFFER_GROUP:
      let offerContent = [...state.offerContent];
      offerContent.push({
        orderId: offerContent.length + 1,
        name: action.payload.name,
        isEditMode: false,
        staticId: offerContent.length + 1,
        contents: [],
      });
      return {
        ...state,
        offerContent,
      };
    case constants.OFFERS.DELETE_OFFER_GROUP:
      let offerContentData = [...state.offerContent];
      offerContentData = offerContentData
        .filter((f) => f.staticId !== action.payload)
        .map((m, idx) => {
          return {
            ...m,
            orderId: idx + 1,
          };
        });
      return {
        ...state,
        offerContent: offerContentData,
      };
    case constants.OFFERS.DELETE_OFFER_CONTENT:
      let offerContentDeleteData = [...state.offerContent];
      offerContentDeleteData = offerContentDeleteData
        .map((m, idx) => {
          return {
            ...m,
            contents: m.staticId === action.payload.staticId ? m.contents.filter((f) => f.staticContentId !== action.payload.staticContentId) : m.contents,
          };
        });

      let offerContentEditorDeleteObjects = [...state.offerContentEditorObjects];
      offerContentEditorDeleteObjects = offerContentEditorDeleteObjects.filter((f) => {
        if(f.staticContentId === action.payload.staticContentId && f.staticId === action.payload.staticId) {
          return false;
        } else {
          return true;
        }
      });

      let offerContentPreviewEditorDeleteObjects = [...state.offerContentPreviewEditorObjects];

      return {
        ...state,
        offerContent: offerContentDeleteData,
        offerContentEditorObjects: offerContentEditorDeleteObjects,
        offerContentPreviewEditorObjects: offerContentPreviewEditorDeleteObjects,
      };
    case constants.OFFERS.EDIT_MODE_OF_OFFER_GROUP:
      let offerContentReData = [...state.offerContent];
      offerContentReData = offerContentReData.map((f) => {
        return {
          ...f,
          isEditMode:
            f.staticId === action.payload.staticId
              ? action.payload.data
              : false,
        };
      });
      return {
        ...state,
        offerContent: offerContentReData,
      };
    case constants.OFFERS.EDIT_OFFER_GROUP_NAME:
      let editNewNameOfferContent = [...state.offerContent];
      const indexId = editNewNameOfferContent.findIndex(
        (f) => f.staticId === action.payload.staticId
      );
      editNewNameOfferContent[indexId].name = action.payload.name;
      return {
        ...state,
        offerContent: editNewNameOfferContent,
      };
    case constants.OFFERS.SELECTED_CUSTOMER_PROPERTY_OFFER:
      return {
        ...state,
        selectedCustomerProperty: action.payload,
      };
    case constants.OFFERS.UPDATE_OFFER_CONTENT_REARRANGEMENT:
      return {
        ...state,
        offerContent: [...action.payload],
      };
    /**
     * OFFER CONTENT GROUP ***ENDS***
     */
    /**
     * OFFER CONTENT GROUP PRODUCTS ***STARTS***
     */
    case constants.OFFERS.ADD_NEW_ROW_OFFER_CONTENT:
      let newRowAddedOfferContent = [...state.offerContent];
      let offerContentEditorObjects = [...state.offerContentEditorObjects];
      let offerContentPreviewEditorObjects = [...state.offerContentPreviewEditorObjects];      
      const newRowAddedOfferContentID = newRowAddedOfferContent.findIndex(
        (f) => f.staticId === action.payload.offerGroup
      );
      if (action.payload.type === "editor") {
        const Editor = state.editorObjects?.offerContentEditor.EditorInstance;
        let editorObj = new Editor({
          extensions: [state.editorObjects?.offerContentEditor.StarterKit],
        });
        offerContentEditorObjects.push({
          staticId: action.payload.offerGroup,
          staticContentId:
            newRowAddedOfferContent[newRowAddedOfferContentID].contents.length +
            1,
          editorObj,
        });
        newRowAddedOfferContent[newRowAddedOfferContentID].contents.push({
          type: action.payload.type,
          staticContentId:
            newRowAddedOfferContent[newRowAddedOfferContentID].contents.length +
            1,
        });
      } else if (action.payload.type === "productList") {
        newRowAddedOfferContent[newRowAddedOfferContentID].contents.push({
          type: action.payload.type,
          staticContentId:
            newRowAddedOfferContent[newRowAddedOfferContentID].contents.length +
            1,
          products: [],
        });
      }
      return {
        ...state,
        offerContent: newRowAddedOfferContent,
        offerContentEditorObjects: [...offerContentEditorObjects],
        offerContentPreviewEditorObjects: [...offerContentPreviewEditorObjects],
      };
    case constants.OFFERS.ADD_NEW_PRODUCT_OFFER_CONTENT_CRUD:
      let newRowAddedOfferContentProduct = state.offerContent.map((m) => m);
      let singleContentData =
        newRowAddedOfferContentProduct[
          newRowAddedOfferContentProduct.findIndex(
            (f) => f.staticId === action.payload.staticId
          )
        ].contents;
      if (singleContentData.length) {
        let staticContentIdPos = singleContentData.findIndex(
          (k) => k.staticContentId === action.payload.staticContentId
        );
        if (action.payload.operation === "add") {
          console.log(singleContentData[staticContentIdPos]);
          if (
            singleContentData[staticContentIdPos].products.length === 0 ||
            singleContentData[staticContentIdPos].products.every(
              (s) => s.hasProductDataAdded
            )
          ) {
            singleContentData[staticContentIdPos].products.push({
              productOrderId:
                singleContentData[staticContentIdPos].products.length + 1,
              productStaticId:
                singleContentData[staticContentIdPos].products.length + 1,
              hasProductDataAdded: false,
              productData: {},
              amount: 0,
              units: [],
              unit_id: "",
              purchase_price: 0,
              retail_price: 0,
              tax_percentage: 0,
              discount: 0,
              supplier: 0,
              focusElement: true,
            });
          } else {
            toast.warning(
              getLocalizedStrings().message.OFFER.warning.addPreviousProduct
            );
            return {
              ...state,
            };
          }
        } else if (action.payload.operation === "delete") {
          singleContentData[staticContentIdPos].products = singleContentData[
            staticContentIdPos
          ].products.filter(
            (k) => k.productStaticId !== action.payload.productStaticId
          );
        }
      }
      newRowAddedOfferContentProduct[
        newRowAddedOfferContentProduct.findIndex(
          (f) => f.staticId === action.payload.staticId
        )
      ].contents = singleContentData;
      return {
        ...state,
        countProductAddedInOfferContent:
          state.countProductAddedInOfferContent + 1,
        offerContent: cloneDeep(newRowAddedOfferContentProduct),
      };
    case constants.OFFERS.UPDATE_OFFER_CONTENT_PRODUCT_REARRANGEMENT:
      let productRearrangeData = [...state.offerContent];
      let rearrangeProductsContents =
        productRearrangeData[
          productRearrangeData.findIndex(
            (f) => f.staticId === action.payload.staticId
          )
        ].contents;
      rearrangeProductsContents[
        rearrangeProductsContents.findIndex(
          (f) => f.staticContentId === action.payload.staticContentId
        )
      ].products = action.payload.newList;
      productRearrangeData[
        productRearrangeData.findIndex(
          (f) => f.staticId === action.payload.staticId
        )
      ].contents = rearrangeProductsContents;
      return {
        ...state,
        countProductAddedInOfferContent:
          state.countProductAddedInOfferContent + 1,
        offerContent: cloneDeep(productRearrangeData),
      };
    case constants.OFFERS.SELECT_PRODUCT_DATA:
      let editAddedOfferContentProduct = state.offerContent.map((m) => m);

      let editProductDataContent =
        editAddedOfferContentProduct[
          editAddedOfferContentProduct.findIndex(
            (f) => f.staticId === action.payload.staticId
          )
        ]?.contents;
      if (editProductDataContent.length) {
        let staticProductDataAddProduct = {};
        let staticProductDataAddProductAll = [];
        let staticContentIdPosAddProduct = editProductDataContent.findIndex(
          (k) => k.staticContentId === action.payload.staticContentId
        );
        if (action.payload.operation === "update") {
          staticProductDataAddProductAll =
            editProductDataContent[staticContentIdPosAddProduct].products;
          staticProductDataAddProduct = staticProductDataAddProductAll.filter(
            (k) => k.productStaticId === action.payload.productStaticId
          )[0];
          if (action.payload.update === "product") {
            let basicPricingData = {};
            let basicPricingDataFilter = [];
            const timeformat = 'YYYY-MM-DD hh:mm:ss A';
            if (action.payload.productData.daily_price.length > 0) {
              const todayName = moment().format('ddd');
              const dailyPriceList = action.payload.productData.daily_price.filter((dpf) => JSON.parse(dpf.days).includes(todayName));
              dailyPriceList.map((dp) => {
                const time = moment(moment().format(timeformat), timeformat);
                const beforeTime = moment(moment().format('YYYY-MM-DD')+' '+dp.from_time, timeformat);
                const afterTime = moment(moment().format('YYYY-MM-DD')+' '+dp.to_time, timeformat);
                if (time.isBetween(beforeTime, afterTime)) {
                  if(staticProductDataAddProduct.hasProductDataAdded) {
                    if (dp.supplier_id === Number(staticProductDataAddProduct.supplier) && dp.unit_id === Number(action.payload.value)){
                      basicPricingDataFilter = [dp];
                    }
                  } else {
                    basicPricingDataFilter = [dp];
                  }
                }
              });
            }
            if (basicPricingDataFilter.length === 0) {
              basicPricingDataFilter =
              action.payload.productData.basic_price.filter(
                (f) =>
                  f.supplier_id === Number(action.payload.supplierId) &&
                  f.unit_id === action.payload.productData.units[0].name.id
              );
            }

            if (basicPricingDataFilter.length) {
              basicPricingData = basicPricingDataFilter[0];
            }
            staticProductDataAddProduct = {
              ...staticProductDataAddProduct,
              hasProductDataAdded: true,
              productData: action.payload.productData,
              amount: basicPricingData?.units || 1,
              units: action.payload.productData.units,
              unit_id: basicPricingData?.unit_id || "",
              purchase_price: basicPricingData?.purchase_price
                ? Number(basicPricingData?.purchase_price)
                : 0,
              retail_price: basicPricingData?.selling_price
                ? Number(basicPricingData?.selling_price)
                : 0,
              tax_percentage: action.payload.productData.tax_status
                ? action.payload.productData.tax_id
                : 0,
              discount:
                action.payload.productData.hasOwnProperty(
                  "discount_percentage"
                ) && action.payload.productData.discount_percentage.length === 1
                  ? action.payload.productData.discount_percentage[0].discount
                  : 0,
              supplier: action.payload.supplierId,
              focusElement: false,
            };
          } else if (action.payload.update === "unit_id") {
            let basicPricingData = {};
            let basicPricingDataFilter = [];
            const timeformat = 'YYYY-MM-DD hh:mm:ss A';
            if (staticProductDataAddProduct.productData?.daily_price?.length > 0) {
              const todayName = moment().format('ddd');
              const dailyPriceList = staticProductDataAddProduct.productData.daily_price.filter((dpf) => JSON.parse(dpf.days).includes(todayName));
              dailyPriceList.map((dp) => {
                const time = moment(moment().format(timeformat), timeformat);
                const beforeTime = moment(moment().format('YYYY-MM-DD')+' '+dp.from_time, timeformat);
                const afterTime = moment(moment().format('YYYY-MM-DD')+' '+dp.to_time, timeformat);
                if (time.isBetween(beforeTime, afterTime)) {
                  if (dp.supplier_id === Number(staticProductDataAddProduct.supplier) && dp.unit_id === Number(action.payload.value)){
                    basicPricingDataFilter = [dp];
                  }
                }
              });
            }
            if (basicPricingDataFilter.length === 0) {
              basicPricingDataFilter =
              staticProductDataAddProduct.productData?.basic_price?.filter(
                (f) =>
                  f.supplier_id === Number(staticProductDataAddProduct.supplier) &&
                  f.unit_id === Number(action.payload.value)
              );
            }
            if (basicPricingDataFilter && basicPricingDataFilter.length) {
              basicPricingData = basicPricingDataFilter[0];
            }
            // let basicPricingDataFilter =
            //   staticProductDataAddProduct.productData.basic_price.filter(
            //     (f) =>
            //       f.supplier_id ===
            //         Number(staticProductDataAddProduct.supplier) &&
            //       f.unit_id === Number(action.payload.value)
            //   );
            // if (basicPricingDataFilter.length) {
            //   basicPricingData = basicPricingDataFilter[0];
            // }
            const selectedUnits = state.allData.unitList.filter((uf) => uf.id === Number(action.payload.value));

            staticProductDataAddProduct = {
              ...staticProductDataAddProduct,
              amount: basicPricingData?.units || 1,
              unit_id: Number(action.payload.value),
              units: selectedUnits,
              purchase_price: basicPricingData?.purchase_price
                ? Number(basicPricingData?.purchase_price)
                : 0,
              retail_price: basicPricingData?.selling_price
                ? Number(basicPricingData?.selling_price)
                : 0,
            };
          } else {
            staticProductDataAddProduct[action.payload.update] =
              action.payload.value;
            if (action.payload.update === "hasProductDataAdded") {
              staticProductDataAddProduct["focusElement"] = true;
            }
          }
          editAddedOfferContentProduct[
            editAddedOfferContentProduct.findIndex(
              (f) => f.staticId === action.payload.staticId
            )
          ].contents[staticContentIdPosAddProduct].products[
            staticProductDataAddProductAll.findIndex(
              (k) => k.productStaticId === action.payload.productStaticId
            )
          ] = staticProductDataAddProduct;
        } else if (action.payload.operation === "add") {
          let allProducts =
            editAddedOfferContentProduct[
              editAddedOfferContentProduct.findIndex(
                (f) => f.staticId === action.payload.staticId
              )
            ].contents[staticContentIdPosAddProduct].products;
          let basicPricingData = {};
          let basicPricingDataFilter = [];
          const timeformat = 'YYYY-MM-DD hh:mm:ss A';
          if (action.payload.productData.daily_price.length > 0) {
            const todayName = moment().format('ddd');
            const dailyPriceList = action.payload.productData.daily_price.filter((dpf) => JSON.parse(dpf.days).includes(todayName));
            dailyPriceList.map((dp) => {
              const time = moment(moment().format(timeformat), timeformat);
              const beforeTime = moment(moment().format('YYYY-MM-DD')+' '+dp.from_time, timeformat);
              const afterTime = moment(moment().format('YYYY-MM-DD')+' '+dp.to_time, timeformat);
              if (time.isBetween(beforeTime, afterTime)) {
                if (dp.supplier_id === Number(action.payload.supplierId) && dp.unit_id === Number(action.payload.productData.units?.[0].name.id)){
                  basicPricingDataFilter = [dp];
                }
                // basicPricingDataFilter = [dp];
              }
            });
          }
          if (basicPricingDataFilter.length === 0) {
            action.payload.productData.basic_price.filter(
              (f) =>
                f.supplier_id === Number(action.payload.supplierId) &&
                f.unit_id === action.payload.productData.units?.[0].name.id
            );
          }
          if (basicPricingDataFilter.length) {
            basicPricingData = basicPricingDataFilter[0];
          }
          editAddedOfferContentProduct[
            editAddedOfferContentProduct.findIndex(
              (f) => f.staticId === action.payload.staticId
            )
          ].contents[staticContentIdPosAddProduct].products.push({
            productOrderId: allProducts.length + 1,
            productStaticId: allProducts.length + 1,
            hasProductDataAdded: true,
            productData: action.payload.productData,
            amount: basicPricingData?.units || 1,
            units: action.payload.productData.units,
            unit_id: action.payload.productData.units?.[0].name.id,
            purchase_price: basicPricingData?.purchase_price
              ? Number(basicPricingData?.purchase_price)
              : 0,
            retail_price: basicPricingData?.selling_price
              ? Number(basicPricingData?.selling_price)
              : 0,
            tax_percentage: action.payload.productData.tax_status
              ? action.payload.productData.tax_id
              : 0,
            discount: 0,
            supplier: action.payload.supplierId,
          });
        }
      }
      return {
        ...state,
        countProductAddedInOfferContent:
          state.countProductAddedInOfferContent + 1,
        offerContent: cloneDeep(editAddedOfferContentProduct),
      };
    /**
     * OFFER CONTENT GROUP PRODUCTS ***ENDS***
     */
    case constants.OFFERS.SET_OFFER_DATA_FOR_EDIT_MODE:
      let offerContentSetData = [];
      let offerContentSetFinalData = [];

      let offerContentEditorObjectsEdit = [];
      let offerContentPreviewEditorObjectsEdit = [];
      if (
        state.editorObjects.customerDetailsEditor.getHTML() === "" ||
        state.editorObjects.customerDetailsEditor.getHTML() === "<p></p>"
      ) {
        state.editorObjects.customerDetailsEditor.commands.insertContent(
          action.payload.customer_additional_information
        );
      }
      if (
        state.editorObjects.propertiesEditor.getHTML() === "" ||
        state.editorObjects.propertiesEditor.getHTML() === "<p></p>"
      ) {
        state.editorObjects.propertiesEditor.commands.insertContent(
          action.payload.property_additional_information
        );
      }
      const Editor = state.editorObjects?.offerContentEditor.EditorInstance;
      const PreviewEditor = state.editorObjects?.offerContentPreviewEditor.EditorInstance;
      action.payload.offer_content.forEach((content) => {
        let allEditorContents = [];
        allEditorContents = content.editor_group.map((editorGroup) => {
          let editorObj = new Editor({
            content: editorGroup.description,
            extensions: [state.editorObjects?.offerContentEditor.StarterKit],
          });
          offerContentEditorObjectsEdit.push({
            staticId: Number(content.static_id),
            staticContentId: Number(editorGroup.static_id),
            editorObj,
          });
          return {
            type: "editor",
            acceptAfterFlag: editorGroup.acceptAfterFlag,
            staticContentId: Number(editorGroup.static_id),
            editor_group_id: content.id, //editorGroup.id,
          };
        });
        let allProductListContents = [];
        allProductListContents = content.product_group.map((productGroup) => {
          return {
            type: "productList",
            staticContentId: Number(productGroup.static_id),
            product_group_id: productGroup.id,
            products: productGroup.product_list.map((pl) => {
              return {
                offer_contents_products_id: pl.id,
                acceptAfterFlag: pl.acceptAfterFlag,
                productOrderId: Number(pl.order_id),
                productStaticId: Number(pl.static_id),
                hasProductDataAdded: true,
                productData: pl?.product_details?.[0] || {name:pl?.product_name, product_code: ''},
                amount: Number(pl.amount),
                units: pl?.product_details?.[0]?.units || [],
                unit_id: Number(pl.unit),
                purchase_price: Number(pl.purchase_price),
                retail_price: Number(pl.retail_price),
                tax_percentage: Number(pl.tax_per),
                discount: Number(pl.discount_per),
                supplier: Number(pl.supplier),
              };
            }),
          };
        });
        offerContentSetData.push({
          old_content_group_id: content.id,
          orderId: Number(content.order_id),
          name: content.group_name,
          isEditMode: false,
          staticId: Number(content.static_id),
          contents: [...allEditorContents, ...allProductListContents].sort(
            sortArrayOfObjects("staticContentId")
          ),
        });
      });
      if (action.payload.offer_accepted) {
        action.payload.offer_content_final.forEach((content) => {
          let allEditorContents = [];
          if (content?.editor_group && content.editor_group.length > 0) {
            allEditorContents = content.editor_group.map((editorGroup) => {
              let editorObj = new PreviewEditor({
                content: editorGroup.description,
                extensions: [state.editorObjects?.offerContentEditor.StarterKit],
              });
              editorObj.commands.clearContent(true);
              editorObj.commands.setContent(editorGroup.description);
              offerContentPreviewEditorObjectsEdit.push({
                staticId: Number(content.static_id),
                staticContentId: Number(editorGroup.static_id),
                editorObj,
              });
              return {
                type: "editor",
                acceptAfterFlag: editorGroup.acceptAfterFlag,
                staticContentId: Number(editorGroup.static_id),
                editor_group_id: content.id, //editorGroup.id,
              };
            });  
          }
          let allProductListContents = [];
          if (content?.product_group && content.product_group.length > 0) {
            allProductListContents = content.product_group.map((productGroup) => {
              return {
                type: "productList",
                staticContentId: Number(productGroup.static_id),
                product_group_id: productGroup.id,
                products: productGroup.product_list.map((pl) => {
                  return {
                    offer_contents_products_id: pl.id,
                    acceptAfterFlag: pl.acceptAfterFlag,
                    productOrderId: Number(pl.order_id),
                    productStaticId: Number(pl.static_id),
                    hasProductDataAdded: true,
                    productData: pl?.product_details?.[0] || {name:pl?.product_name, product_code: ''},
                    amount: Number(pl.amount),
                    units: pl?.product_details?.[0]?.units || [],
                    unit_id: Number(pl.unit),
                    purchase_price: Number(pl.purchase_price),
                    retail_price: Number(pl.retail_price),
                    tax_percentage: Number(pl.tax_per),
                    discount: Number(pl.discount_per),
                    supplier: Number(pl.supplier),
                  };
                }),
              };
            });
          }
          offerContentSetFinalData.push({
            orderId: Number(content.order_id),
            name: content.group_name,
            isEditMode: false,
            staticId: Number(content.static_id),
            contents: [...allEditorContents, ...allProductListContents].sort(
              sortArrayOfObjects("staticContentId")
            ),
          });
        });  
      }
      return {
        ...state,
        isEditMode: true,
        editData: action.payload,
        hasCustomerDetails: true,
        customerDetails: action.payload.offers_details.customer,
        hasOfferInformationAdded: true,
        offerInformationDetails: action.payload.offers_details,
        selectedContactPerson: Number(action.payload.contact_person_id),
        attachments: action.payload.offers_files,
        customerProperties:
          action.payload.offers_details.customer.customer_property,
        selectedCustomerProperty:
          action.payload.offers_details.customer.customer_property.filter(
            (f) => f.id === Number(action.payload.customer_property_id)
          )[0],
        offerContentEditorObjects: [...offerContentEditorObjectsEdit],
        offerContentPreviewEditorObjects: [...offerContentPreviewEditorObjectsEdit],        
        offerContent: cloneDeep(offerContentSetData),
        offerContentFinal: cloneDeep(offerContentSetFinalData),
      };
    case constants.OFFERS.CUSTOMER_BASIC_DATA_OFFER:
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          customer_basic: [action.payload],
        },
      };
    case constants.OFFERS.UPDATE_EDIT_DATA_OFFERS:
      return {
        ...state,
        editData: action.payload,
      };
    case constants.OFFERS.CLEAR_OFFERS_EDIT_DATA:
      return {
        ...state,
        editData: {},
      };
    case constants.OFFERS.UPDATE_OFFER_SENT_DATE:
      return {
        ...state,
        editData: {
          ...state.editData,
          offer_sent: action.payload,
        },
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
