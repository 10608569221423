import {
  generateRandomString,
  getDateOnlyForInput,
  getTimeOnly,
} from "../../utils/common";
import { constants } from "../constants";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

const initialState = {
  mounted: false,
  randomKey: "",
  allData: {},
  isEditMode: false,
  editData: {},

  hasCustomerDetails: false,
  customerDetails: {},

  hasOrderInformationAdded: false,
  orderInformationDetails: {},
  selectedContactPerson: {},

  editorObjects: {},

  attachments: [],

  customerProperties: [],
  selectedCustomerProperty: {},

  countProductAddedInOrderContent: 0,

  hasOfferInformation: false,
  offerInformationData: {},
  countProductAddedInOfferContent: 0,
  
  hasOrderInformation: false,
  orderInformationData: {},
  countProductAddedInOrderContent: 0,

  shippingItems: [],
  selectedDriverList: [],

  dataUpdated: false,

  datesRange: [],

  productList: []
};

Array.prototype.insert = function (index, item) {
  this.splice(index, 1);
  this.splice(index, 0, item);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.INVOICES.GET_INVOICES_STACK:
      const tempToken = state.randomKey ||  generateRandomString(8);
      localStorage.setItem("order-temp-token", tempToken);
      return {
        ...state,
        mounted: true,
        randomKey: tempToken,
        allData: {
          ...action.payload,
          },
      };
    case constants.INVOICES.SET_DATA_UPDATED:
      return {
        ...state,
        dataUpdated: action.payload,
      };
    case constants.INVOICES.SET_INVOICES_DELIVERY_DATE_RANGE:
      let datesRange = [...state.datesRange];
      if (
        datesRange.findIndex(
          (dr) =>
            dr.order_id === action.payload.order_id &&
            dr.delivery_id === action.payload.delivery_id
        ) <= -1
      ) {
        datesRange.push({
          minDate: action.payload.minDate,
          maxDate: action.payload.maxDate,
          delivery_id: action.payload.delivery_id,
          order_id: action.payload.order_id,
        });
      }
      return {
        ...state,
        datesRange: datesRange,
      };
    case constants.INVOICES.SET_INVOICES_FOR_CUSTOMER:
      return {
        ...state,
        hasCustomerDetails: true,
        customerDetails: action.payload,
        countProductAddedInOrderContent:
          state.countProductAddedInOrderContent + 1,
      };
    case constants.INVOICES.RESET_INVOICES_STATE:
      return {
        mounted: false,
        randomKey: "",
        allData: {},
        isEditMode: false,
        editData: {},
        dataUpdated: false,
        hasCustomerDetails: false,
        customerDetails: {},

        editorObjects: {},

        hasOrderInformationAdded: false,
        orderInformationDetails: {},
        selectedContactPerson: {},

        attachments: [],

        customerProperties: [],
        selectedCustomerProperty: {},

        countProductAddedInOrderContent: 0,

        hasOfferInformation: false,
        offerInformationData: {},
        countProductAddedInOfferContent: 0,

        shippingItems: [],
        selectedDriverList: [],
        productList: []
      };
    case constants.INVOICES.SAVE_INVOICES_INFORMATION:
      let otherOfferData = {
        offerInformationData: {},
        countProductAddedInOfferContent: state.countProductAddedInOfferContent,
      };
      if (
        action.payload.hasOwnProperty("offer_details") &&
        action.payload.offer_details.length
      ) {
        otherOfferData.offerInformationData = action.payload.offer_details[0];
        otherOfferData.countProductAddedInOfferContent =
          state.countProductAddedInOfferContent + 1;
      }
      return {
        ...state,
        hasOrderInformationAdded: true,
        orderInformationDetails: action.payload,
        hasOfferInformation: Boolean(action.payload?.offer_details?.length),
        ...otherOfferData,
      };
    case constants.INVOICES.CUSTOMER_CONTACT_DATA_INVOICES:
      return {
        ...state,
        selectedContactPerson: action.payload,
      };
    case constants.INVOICES.SAVE_OFFER_INFORMATION_IN_INVOICES:
      return {
        ...state,
        hasOfferInformation: true,
        offerInformationData: action.payload,
      };
    case constants.INVOICES.SAVE_ATTACHMENTS_INVOICES:
      let attachments = [...state.attachments];
      if (action.payload.operation === "add") {
        action.payload.data.forEach((f) => {
          attachments.push(f);
        });
      } else if (action.payload.operation === "update") {
        attachments.insert(
          attachments.findIndex((f) => f.id === action.payload.data.id),
          action.payload.data
        );
      } else if (action.payload.operation === "delete") {
        attachments = attachments.filter((f) => f.id !== action.payload.data);
      }
      return {
        ...state,
        attachments: [...attachments],
      };
    case constants.INVOICES.CUSTOMER_PROPERTIES_IN_INVOICES:
      let customerProperties = [...state.customerProperties];
      if (action.payload.operation === "add") {
        customerProperties.push(action.payload.data);
      } else if (action.payload.operation === "update") {
        customerProperties.insert(
          customerProperties.findIndex((f) => f.id === action.payload.data.id),
          action.payload.data
        );
      } else if (action.payload.operation === "delete") {
        customerProperties = customerProperties.filter(
          (f) => f.id !== action.payload.data
        );
      }
      return {
        ...state,
        customerProperties: [...customerProperties],
      };
    case constants.INVOICES.STORE_ALL_CUSTOMER_PROPERTIES_IN_INVOICES:
      return {
        ...state,
        customerProperties: action.payload,
      };
    case constants.INVOICES.UPDATE_EDITOR_OBJECTS_INVOICES:
      return {
        ...state,
        editorObjects: action.payload,
      };
    case constants.INVOICES.SELECTED_CUSTOMER_PROPERTY_INVOICES:
      return {
        ...state,
        selectedCustomerProperty: action.payload,
      };
    case constants.INVOICES.ADD_NEW_SHIPPING:
      return {
        ...state,
        shippingItems: [
          ...state.shippingItems,
          {
            deliveryOrderId: state.shippingItems.length + 1,
            productType: action.payload.ProductType,
            startDate: action.payload.startDate,
            startTime: action.payload.startTime,
            endDate: action.payload.endDate,
            endTime: action.payload.endTime,
            offer_id: "",
            vehicles: [
              {
                vehicle_id: Number(action.payload.vehicle_id),
                drivers: [Number(action.payload.driver_id)],
              },
            ],
            products: [],
            linked_products: [],
            delivery_batches: 1,
          },
        ],
      };
    case constants.INVOICES.SELECTED_DRIVER_LIST:
      return {
        ...state,
        selectedDriverList: action.payload,
      };
    case constants.INVOICES.REMOVE_DRIVER_LIST:
      return {
        ...state,
        selectedDriverList: [],
      };
    case constants.INVOICES.DELETE_SHIPPING:
      return {
        ...state,
        shippingItems: [
          ...state.shippingItems.filter(
            (k) => k.deliveryOrderId !== action.payload
          ),
        ],
      };
    case constants.INVOICES.UPDATE_DELIVERY_BATCH:
      let shippingItems_UPDATE_DELIVERY_BATCH = [...state.shippingItems];
      shippingItems_UPDATE_DELIVERY_BATCH[
        state.shippingItems.findIndex(
          (f) => f.delivery_id === action.payload.deliveryOrderId
        )
      ].delivery_batches = Number(action.payload.batches);
      return {
        ...state,
        shippingItems: [...shippingItems_UPDATE_DELIVERY_BATCH],
        countProductAddedInOrderContent:
          state.countProductAddedInOrderContent + 1,
      };
    case constants.INVOICES.ADD_NEW_PRODUCT_INVOICES_DELIVERY:
      let shippingItems_ADD_NEW_PRODUCT_INVOICES_DELIVERY =
        state.shippingItems.map((m) => m);
      let products_add_delivery =
        shippingItems_ADD_NEW_PRODUCT_INVOICES_DELIVERY[
          shippingItems_ADD_NEW_PRODUCT_INVOICES_DELIVERY.findIndex(
            (f) => f.delivery_id === action.payload.deliveryOrderId
          )
        ].products;
      if (action.payload.operation === "add") {
        if (
          products_add_delivery.length === 0 ||
          products_add_delivery.every((s) => s.hasProductDataAdded)
        ) {
          products_add_delivery.push({
            productOrderId: products_add_delivery.length + 1,
            productStaticId: products_add_delivery.length + 1,
            hasProductDataAdded: false,
            productData: {},
            amount: 0,
            units: [],
            unit_id: "",
            delivery_area: state?.selectedCustomerProperty?.id || null,
            factories: [],
            selected_factory: null,
            delivery_at: "-",
            status: "Yet to dispatch",
            supplier: 0,
            focusElement: true,
          });
        } else {
          toast.warning("Please add previous product data to add a new record");
          return {
            ...state,
          };
        }
      } else if (action.payload.operation === "delete") {
        products_add_delivery = products_add_delivery.filter(
          (k) => k.productStaticId !== action.payload.productStaticId
        );
      }
      shippingItems_ADD_NEW_PRODUCT_INVOICES_DELIVERY[
        shippingItems_ADD_NEW_PRODUCT_INVOICES_DELIVERY.findIndex(
          (f) => f.delivery_id === action.payload.deliveryOrderId
        )
      ].products = products_add_delivery;
      return {
        ...state,
        countProductAddedInOrderContent:
          state.countProductAddedInOrderContent + 1,
        shippingItems: cloneDeep(shippingItems_ADD_NEW_PRODUCT_INVOICES_DELIVERY),
      };
    case constants.INVOICES.ADD_NEW_VEHICLE:
      let vehicleItems_ADD_NEW_VEHICLE = state.shippingItems.map((m) => m);
      let vehicle_add =
        vehicleItems_ADD_NEW_VEHICLE[
          vehicleItems_ADD_NEW_VEHICLE.findIndex(
            (f) => f.deliveryOrderId === action.payload.deliveryOrderId
          )
        ].vehicles;
      if (action.payload.operation === "add") {
        vehicle_add.push({
          id: action.payload.id,
          vehicleStaticId: vehicleItems_ADD_NEW_VEHICLE.length + 1,
          vehicle_id: action.payload.vehicle_id,
          drivers: action.payload.driver_id,
          startDate: action.payload.startDate,
          startTime: action.payload.startTime,
          endDate: action.payload.endDate,
          endTime: action.payload.endTime,
          deliveryBatches: 1,
          amount: 0,
          hasFixedAmount: 0,
          hasFixedBatches: 0,
        });
      } else if (action.payload.operation === "update") {
        let vehicleAddedIdx = vehicle_add.findIndex(
          (k) => k.vehicle_id === action.payload.vehicle_id
        );
        vehicle_add[vehicleAddedIdx][action.payload.recordName] =
          action.payload.recordValue;
      } else if (action.payload.operation === "delete") {
        vehicle_add = vehicle_add.filter(
          (v) => v.vehicle_id !== action.payload.vehicle_id
        );
      }
      vehicleItems_ADD_NEW_VEHICLE[
        vehicleItems_ADD_NEW_VEHICLE.findIndex(
          (f) => f.deliveryOrderId === action.payload.deliveryOrderId
        )
      ].vehicles = vehicle_add;
      return {
        ...state,
        countProductAddedInOrderContent:
          state.countProductAddedInOrderContent + 1,
        shippingItems: cloneDeep(vehicleItems_ADD_NEW_VEHICLE),
      };
    case constants.INVOICES.SELECT_PRODUCT_DATA_IN_INVOICES:
      let editAddedShippingItemsProduct = state.shippingItems.map((m) => m);
      let staticProductDataAddProduct = {};
      let staticProductDataAddProductAll = [];

      let staticContentIdPosAddProduct =
        editAddedShippingItemsProduct.findIndex(
          (k) => k.delivery_id === action.payload.deliveryOrderId
        );
      if (action.payload.operation === "update") {
        staticProductDataAddProductAll =
          editAddedShippingItemsProduct[staticContentIdPosAddProduct]?.products;

        staticProductDataAddProduct = staticProductDataAddProductAll.filter(
          (k) => k.productStaticId === action.payload.productStaticId
        )[0];

        if (action.payload.update === "product") {
          let basicPricingData = {};
          let basicPricingDataFilter =
            action.payload.productData.basic_price.filter(
              (f) =>
                f.supplier_id === Number(action.payload.supplierId) &&
                f.unit_id === action.payload.productData.units[0].name.id
            );
          if (basicPricingDataFilter.length) {
            basicPricingData = basicPricingDataFilter[0];
          }
          staticProductDataAddProduct = {
            ...staticProductDataAddProduct,
            hasProductDataAdded: true,
            productData: action.payload.productData,
            amount: basicPricingData?.amount || 1,
            units: action.payload.productData.units,
            unit_id: action.payload.productData.units[0].name.id,
            delivery_area: state?.selectedCustomerProperty?.id ||  null,
            factories:
              action.payload.productData.supplier[
                action.payload.productData.supplier.findIndex(
                  (k) => k.supplier_id === Number(action.payload.supplierId)
                )
              ].factory,
            selected_factory: null,
            delivery_at: "-",
            delivery_status: action.payload.productData?.delivery_status || 0,
            status: "Yet to dispatch",
            supplier: action.payload.supplierId,
            focusElement: false,
          };
        } else if (action.payload.update === "unit_id") {
          let basicPricingData = {};
          let basicPricingDataFilter =
            staticProductDataAddProduct.productData.basic_price.filter(
              (f) =>
                f.supplier_id ===
                  Number(staticProductDataAddProduct.supplier) &&
                f.unit_id === Number(action.payload.value)
            );
          if (basicPricingDataFilter.length) {
            basicPricingData = basicPricingDataFilter[0];
          }
          staticProductDataAddProduct = {
            ...staticProductDataAddProduct,
            amount: basicPricingData?.units || 1,
            unit_id: Number(action.payload.value),
          };
        } else {
          staticProductDataAddProduct[action.payload.update] =
            action.payload.value;
          if (action.payload.update === "hasProductDataAdded") {
            staticProductDataAddProduct["focusElement"] = true;
          }
        }
        editAddedShippingItemsProduct[staticContentIdPosAddProduct].products[
          staticProductDataAddProductAll.findIndex(
            (k) => k.productStaticId === action.payload.productStaticId
          )
        ] = staticProductDataAddProduct;
      } else if (action.payload.operation === "add") {
        let allProducts =
          editAddedShippingItemsProduct[staticContentIdPosAddProduct].products;

        let basicPricingData = {};
        let basicPricingDataFilter =
          action.payload.productData.basic_price.filter(
            (f) =>
              f.supplier_id === Number(action.payload.supplierId) &&
              f.unit_id === action.payload.productData.units[0].name.id
          );
        if (basicPricingDataFilter.length) {
          basicPricingData = basicPricingDataFilter[0];
        }
        editAddedShippingItemsProduct[
          staticContentIdPosAddProduct
        ].products.push({
          productOrderId: allProducts.length + 1,
          productStaticId: allProducts.length + 1,
          hasProductDataAdded: true,
          productData: action.payload.productData,
          amount: basicPricingData?.units || 1,
          units: action.payload.productData.units,
          unit_id: action.payload.productData.units[0].name.id,
          delivery_area: state?.selectedCustomerProperty?.id || null,
          factories:
            action.payload.productData.supplier[
              action.payload.productData.supplier.findIndex(
                (k) => k.supplier_id === Number(action.payload.supplierId)
              )
            ].factory,
          selected_factory: null,
          delivery_at: "-",
          status: "Yet to dispatch",
          supplier: action.payload.supplierId,
        });
      }
      return {
        ...state,
        countProductAddedInOrderContent:
        state.countProductAddedInOrderContent + 1,
        shippingItems: cloneDeep(editAddedShippingItemsProduct),
      };
    case constants.INVOICES.CREATE_NEW_INVOICES_DELIVERY:
      let newShippingData = action.payload;
      let allDates = [];
      newShippingData.vehicles.forEach((m) => {
        allDates.push(new Date(`${m.startDate} ${m.startTime}`));
        allDates.push(new Date(`${m.endDate} ${m.endTime}`));
      });
      let maxDate = new Date(Math.max.apply(null, allDates));
      let minDate = new Date(Math.min.apply(null, allDates));
      let timings = {};
      if (typeof maxDate === "object") {
        timings["endDate"] = getDateOnlyForInput(maxDate);
        timings["endTime"] = getTimeOnly(maxDate);
      }
      if (typeof minDate === "object") {
        timings["startDate"] = getDateOnlyForInput(minDate);
        timings["startTime"] = getTimeOnly(minDate);
      }
      newShippingData = {
        ...newShippingData,
        ...timings,
      };
      const oldShippingItem = state.shippingItems.filter((s) => s.delivery_id != newShippingData.delivery_id);
      return {
        ...state,
        customerProperties: [newShippingData.offer_customer_property],
        selectedCustomerProperty: newShippingData.offer_customer_property,
        shippingItems: [...oldShippingItem, newShippingData],
        datesRange: [
          {
            minDate,
            maxDate,
            delivery_id: newShippingData.delivery_id,
            order_id: newShippingData.order_id,
          },
        ],
      };
    case constants.INVOICES.SET_INVOICES_DATA_FOR_EDIT_MODE:
      return {
        ...state,
        isEditMode: true,
        dataUpdated: false,
        editData: action.payload,
        hasCustomerDetails: true,
        customerDetails: { 
          customer_basic: action.payload.customer_basic, 
          customer_billing: action.payload.customer_billing,
          customer_contact: action.payload.customer_contact_person 
        },
        hasOrderInformationAdded: true,
        orderInformationDetails: action.payload,
        // selectedContactPerson: Number(action.payload.contact_person_id),
        // customerProperties:
        //   action.payload.offerCustomerProperty,
        // selectedCustomerProperty:
        //   action.payload.offerCustomerProperty.filter(
        //     (f) => f.id === Number(action.payload.propertyId)
        //   )[0],
      };
    case constants.INVOICES.UPDATE_SINGLE_DELIVERY_DATA:
      let shippingItemsForSingleUpdate = [...state.shippingItems];
      shippingItemsForSingleUpdate[
        shippingItemsForSingleUpdate.findIndex(
          (f) => f.deliveryOrderId === action.payload.deliveryOrderId
        )
      ] = action.payload.deliveryData;
      return {
        ...state,
        shippingItems: structuredClone(shippingItemsForSingleUpdate),
        countProductAddedInOrderContent:
          state.countProductAddedInOrderContent + 1,
      };
    case constants.INVOICES.SET_PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload
      };
    default: {
      return {
        ...state,
        dataUpdated: false,
      };
    }
  }
};
